import { IApi } from '../../../api/IApi';
import { IAlterarPontoUtilizacaoDTO } from './AlterarPontoUtilizacaoDTO';

export class AlterarPontoUtilizacaoController {
  constructor(private api: IApi) {}

  async execute(data: IAlterarPontoUtilizacaoDTO, token: string): Promise<void> {
    await this.api.post<void, IAlterarPontoUtilizacaoDTO>('usuarios/pontosUtilizacao', data, token);
  }
}
