import React from 'react';
import { FlatList, View } from 'react-native';

import { Pedido } from '../../../entities/Pedido';
import { resgatarPontosUsuario } from '../../../useCases/Usuario/PontosUsuario';
import { IPedidoSint } from '../../../useCases/Usuario/ListarPedidosUsuario/ListarPedidosUsuarioDTO';

import { useAuth } from '../../../hooks/useAuth';
import { ITimeline } from '../../../hooks/useTimeline';

import { CardPedidos } from '../CardPedidos';
import { Loading } from '../../Common/Loading';
import { SemRegistro } from '../../Common/SemRegistro';
import { DataTimeline } from '../../Common/DataTimeline';
import { AtualizarLista } from '../../Common/AtualizarLista';

interface ITimelinePedidos {
  pedidos: ITimeline<Pedido>[];
  loadingMore: boolean;
  onUpdate: () => void;
  onResgate: (success: boolean) => void;
  onOpenSint: (pedidosSint: IPedidoSint[], pedidoCliente: string, referencia: number) => void;
}

export function TimelinePedidos({
  pedidos,
  loadingMore,
  onUpdate,
  onResgate,
  onOpenSint,
}: ITimelinePedidos) {
  const { token } = useAuth();

  async function handleResgate(id: number) {
    try {
      await resgatarPontosUsuario.execute({ id }, token);

      onResgate(true);
    } catch (err) {
      onResgate(false);
    }
  }

  function handleCardClick(id: number) {
    const _pedido = pedidos.map(e => e.timeline.find(pedido => pedido.id === id));

    const pedido = _pedido.find(e => e);

    onOpenSint(pedido.pontosPedidosSint, pedido.pedidoCliente, pedido.referencia);
  }

  return (
    <FlatList
      data={pedidos}
      keyExtractor={item => `pedido_${item.id.toString()}`}
      renderItem={({ item }) => (
        <View>
          <DataTimeline data={item.data} />
          {item.timeline.map(pedido => (
            <>
              <CardPedidos
                key={`card_pedido_${pedido.id}`}
                pedido={pedido}
                onResgate={handleResgate}
                onClick={handleCardClick}
              />
              {/* <CardPedidos
                key={`card_pedido_${pedido.id}`}
                pedido={{ ...pedido, status: 2, statusDesc: 'Pontos Disponíveis' }}
                onResgate={handleResgate}
                onClick={handleCardClick}
              /> */}
            </>
          ))}
        </View>
      )}
      ListFooterComponent={
        loadingMore ? (
          <Loading />
        ) : pedidos.length > 0 ? (
          <AtualizarLista onPress={onUpdate} />
        ) : null
      }
      ListEmptyComponent={<SemRegistro screen="Meus Pedidos" />}
      contentContainerStyle={{
        flex: 1,
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    />
  );
}
