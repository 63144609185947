import { IApi } from '../../../api/IApi';
import { IAlterarCadastroUsuarioDTO } from './AlterarCadastroUsuarioDTO';

export class AlterarCadastroUsuarioController {
  constructor(private api: IApi) {}

  async execute(data: IAlterarCadastroUsuarioDTO, token: string): Promise<void> {
    await this.api.post<void, IAlterarCadastroUsuarioDTO>('usuarios/alterar', data, token);
  }
}
