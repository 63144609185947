import React, { useEffect, useState } from 'react';
import { View, Image, Text } from 'react-native';
import { Loading } from '../Loading';

import { styles } from './styles';

interface IAvatar {
  tamanho: 'sm' | 'md' | 'lg' | 'xl';
  userInfo: {
    nome: string;
    avatar: string | null;
  };
  isLoading?: boolean;
}

export function Avatar({ tamanho, userInfo, isLoading = false }: IAvatar) {
  const [iniciais, setIniciais] = useState('');

  function handleIniciais() {
    const nomeSplitted = userInfo.nome.split(' ');

    // if (nomeSplitted.at(0).length === 2) return setIniciais(nomeSplitted[0].toUpperCase());
    if (nomeSplitted[nomeSplitted.length - 1].length === 2)
      return setIniciais(nomeSplitted[0].toUpperCase());

    const primeiroNome = nomeSplitted[0].slice(0, 1);
    const ultimoNome = nomeSplitted[nomeSplitted.length - 1].slice(0, 1);

    const _iniciais = primeiroNome + ultimoNome;

    setIniciais(_iniciais);
  }

  useEffect(() => handleIniciais(), []);

  return userInfo.avatar && !isLoading ? (
    <Image
      source={{ uri: userInfo.avatar }}
      style={[
        styles.avatar,
        tamanho === 'sm'
          ? styles.avatarSm
          : tamanho === 'md'
          ? styles.avatarMd
          : tamanho === 'lg'
          ? styles.avatarLg
          : styles.avatarXl,
      ]}
    />
  ) : (
    <View
      style={[
        styles.avatar,
        styles.textAvatar,
        tamanho === 'sm'
          ? styles.avatarSm
          : tamanho === 'md'
          ? styles.avatarMd
          : tamanho === 'lg'
          ? styles.avatarLg
          : styles.avatarXl,
      ]}
    >
      {isLoading ? (
        <View
          style={[
            styles.avatar,
            styles.textAvatar,
            tamanho === 'sm'
              ? styles.avatarSm
              : tamanho === 'md'
              ? styles.avatarMd
              : tamanho === 'lg'
              ? styles.avatarLg
              : styles.avatarXl,
          ]}
        >
          <Loading />
        </View>
      ) : (
        <Text
          style={[
            styles.text,
            tamanho === 'sm'
              ? styles.textSm
              : tamanho === 'md'
              ? styles.textMd
              : tamanho === 'lg'
              ? styles.textLg
              : styles.textXl,
          ]}
        >
          {iniciais}
        </Text>
      )}
    </View>
  );
}
