import { isIOS } from 'react-device-detect';
import { Dimensions, StyleSheet } from 'react-native';

import { theme } from '../../../global/styles/theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    backgroundColor: theme.colors.white_100,
  },
  header: {
    maxHeight: isIOS ? 232 : 216,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    alignItems: 'center',
    backgroundColor: theme.colors.blue400,
    zIndex: 1,
    overflow: 'hidden',
  },
  bar: {
    width: '100%',
    marginTop: isIOS ? 32 : 16,
  },
  innerHeader: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingStart: 8,
    paddingEnd: 16,
  },
  avatar: {
    width: 32,
    height: 32,
    borderColor: theme.colors.blue100,
    borderRadius: 32,
    borderWidth: 2,
  },
  pontos: {
    marginTop: 12,
    color: theme.colors.white,
    fontFamily: theme.fonts.pontos,
    fontSize: 96,
    alignSelf: 'center',
  },
  pontosLabel: {
    marginStart: -16,
    color: theme.colors.white,
    fontFamily: theme.fonts.roboto700,
    fontSize: 22,
  },
  pontosDinheiroLabel: {
    alignSelf: 'center',
    color: theme.colors.white,
    fontFamily: theme.fonts.pontos,
    fontSize: 22,
  },
  clube: {
    marginLeft: -16,
    color: theme.colors.white,
    fontFamily: theme.fonts.pontos,
    fontSize: Dimensions.get('window').width >= 375 ? 24 : 20,
    alignSelf: 'center',
  },
});
