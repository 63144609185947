import React, { useState, useEffect } from 'react';
import { View, Text } from 'react-native';
import { Button } from 'react-native-paper';
import { isBrowser } from 'react-device-detect';
import * as ImagePicker from 'expo-image-picker';
import { FontAwesome5 } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import imageCompression from 'browser-image-compression';

import { alterarImagemUsuario } from '../../../useCases/Usuario/ImagemUsuario';
import { IImagemUsuarioDTO } from '../../../useCases/Usuario/ImagemUsuario/ImagemUsuarioDTO';

import { useAuth } from '../../../hooks/useAuth';
import { useToast } from '../../../hooks/useToast';

import { Avatar } from '../../Common/Avatar';

import { styles } from './styles';
import { theme } from '../../../global/styles/theme';

export function HeaderPerfil() {
  const { errorToast, successToast } = useToast();
  const { usuario, token, setUser, isTokenValid } = useAuth();

  const [isImageLoading, setIsImageLoading] = useState(false);

  async function handleChangeImage() {
    const image: any = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    });

    if (image.cancelled) return;

    try {
      setIsImageLoading(true);

      const blob = await (await fetch(image.uri)).blob();
      const file = new File([blob], '', { type: 'image/jpeg' });
      const options = { maxSizeMB: 1, maxWidthOrHeight: 1920, useWebWorker: true };

      const compressedImage = await imageCompression(file, options);
      const b64 = await imageCompression.getDataUrlFromFile(compressedImage);

      const imagemData: IImagemUsuarioDTO = { imagem: b64.split('base64,')[1] };
      await alterarImagemUsuario.execute(imagemData, token);

      setUser({ ...usuario, imagem: b64 });

      successToast('Imagem atualizada');
    } catch (err) {
      const authExpirou = isTokenValid(err);
      errorToast(authExpirou || err);
    } finally {
      setIsImageLoading(false);
    }
  }

  useEffect(() => {
    (async () => {
      if (!isBrowser) {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();

        if (status !== 'granted')
          errorToast('É necessário permitir autorização à galeria de fotos');
      }
    })();
  }, []);

  return (
    <View>
      <LinearGradient
        style={styles.avatarContainer}
        colors={[theme.colors.blue100, theme.colors.blue50]}
      >
        <Avatar
          isLoading={isImageLoading}
          userInfo={{ nome: usuario?.nome ?? '', avatar: usuario.imagem ?? null }}
          tamanho="xl"
        />

        <Button compact mode={'contained'} style={styles.btnCamera} onPress={handleChangeImage}>
          <FontAwesome5 name="camera" size={16} color={theme.colors.blue200} />
        </Button>
      </LinearGradient>

      <View style={{ marginTop: 8 }}>
        {usuario.razaoSocial && (
          <Text style={[styles.h1, { alignSelf: 'center', textAlign: 'center' }]}>
            {usuario.razaoSocial}
          </Text>
        )}

        <Text style={[styles.h1, { alignSelf: 'center', textAlign: 'center' }]}>
          {usuario.nome}
        </Text>
      </View>
    </View>
  );
}
