import { View } from 'react-native';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import React, { useState, useEffect } from 'react';
import { Checkbox, TextInput } from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { logarUsuarioValidacao } from '../../../../useCases/Validacao/LogarUsuarioValidacao';
import { ILogarUsuarioDTO } from '../../../../useCases/Usuario/LogarUsuario/LogarUsuarioDTO';

import { useToast } from '../../../../hooks/useToast';
import { useModal } from '../../../../hooks/useModal';
import { useKeyPressed } from '../../../../hooks/useKeyPressed';

import { Button } from '../../../Common/Button';
import { TipoTela } from '../../../../screens/Home';
import { ModalRecuperarSenha } from '../ModalRecuperarSenha';
import { TextInputSenha } from '../../../Common/TextInputSenha';

import { styles } from './styles';
import { theme } from '../../../../global/styles/theme';

interface IFormLogin {
  handleLogin: (props: ILogarUsuarioDTO) => void;
  handleScreenChange: (tipo: TipoTela) => void;
}

const { white, blue50, textInput } = theme.colors;

const DEFAULT_THEME = { colors: { primary: blue50, placeholder: textInput, text: white } };

export function FormLogin({ handleLogin, handleScreenChange }: IFormLogin) {
  const { errorToast } = useToast();
  const { onEnterPressed } = useKeyPressed();
  const { isModalOpen, openModal, closeModal } = useModal();

  const [campos, setCampos] = useState<ILogarUsuarioDTO>({} as ILogarUsuarioDTO);
  const [salvarCampos, setSalvarCampos] = useState(false);

  async function handleSubmit() {
    try {
      const form = logarUsuarioValidacao.handle(campos);

      handleLogin(form);
      if (salvarCampos) AsyncStorage.setItem('@AuthSavedData', JSON.stringify(campos));
      else AsyncStorage.removeItem('@AuthSavedData');
    } catch (err) {
      errorToast(err);
    }
  }

  function handleKeyPressed(e: any) {
    if (onEnterPressed(e)) handleSubmit();
  }

  useEffect(() => {
    if (cpf.isValid(cpf.format(campos.cnpjCPF)))
      setCampos({ ...campos, cnpjCPF: cpf.format(campos.cnpjCPF) });

    if (cnpj.isValid(cnpj.format(campos.cnpjCPF)))
      setCampos({ ...campos, cnpjCPF: cnpj.format(campos.cnpjCPF) });
  }, [campos?.cnpjCPF]);

  useEffect(() => {
    AsyncStorage.getItem('@AuthSavedData')
      .then(data => JSON.parse(data))
      .then(parsedData => {
        if (parsedData) {
          setSalvarCampos(true);
          setCampos(parsedData);
        }
      });
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <TextInput
          label="CNPJ/CPF"
          keyboardType="number-pad"
          onChangeText={cnpjCPF => setCampos({ ...campos, cnpjCPF })}
          onKeyPress={handleKeyPressed}
          value={campos?.cnpjCPF}
          onPressIn={() => {}}
          onPressOut={() => {}}
          autoComplete="true"
          style={styles.textInput}
          theme={DEFAULT_THEME}
        />

        <TextInputSenha
          onChangeText={senha => setCampos({ ...campos, senha })}
          onKeyPress={handleKeyPressed}
          value={campos?.senha}
          style={styles.textInput}
          theme={DEFAULT_THEME}
        />

        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Checkbox
            status={salvarCampos ? 'checked' : 'unchecked'}
            onPress={() => setSalvarCampos(!salvarCampos)}
            color={white}
          />

          <Button
            mode="text"
            text="Salvar credencias"
            textStyle={{ color: white }}
            onPress={() => setSalvarCampos(!salvarCampos)}
          />
        </View>

        <View style={styles.btnContainer}>
          <Button
            text="Cadastre-se"
            mode="text"
            color={white}
            onPress={() => handleScreenChange('cadastro')}
          />

          <Button
            text="Login"
            mode="outlined"
            uppercase={false}
            onPress={handleSubmit}
            color={white}
            style={{ borderColor: blue50 }}
          />
        </View>

        <View style={{ marginTop: 16, alignSelf: 'center' }}>
          <Button text="Esqueci minha senha" mode="text" color={white} onPress={openModal} />
        </View>
      </View>

      <ModalRecuperarSenha isOpen={isModalOpen} onClose={closeModal} />
    </View>
  );
}
