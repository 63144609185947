import { View,Image,Text} from 'react-native';
import React from 'react';

import { Button } from '../../Button';
import { Modal } from '../';
import ImagemPopup from '../../../../assets/PopUp/ImagemPopUp.png';
import { styles } from './styles';

interface IModalPopUp {
  header?: string;
  body?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

export function PopUp({ header, body, isOpen, onClose }: IModalPopUp) {
  return (
    <Modal isModalOpen={isOpen} setIsModalOpen={onClose} header={header}>
      {body && <View style={{ marginBottom: 8 }}>{body}</View>}

      <View  style={styles.TT}>
        <Image source={ImagemPopup} style={styles.Imagem} /> 
        
        <a href='https://clarityglass.com.br/wp-content/uploads/2023/07/VIDRACEIROS_REGULAMENTO-CAMPANHA-STRADA-DOS-SONHOS.pdf' target="_blank">Baixar o regulamento</a>
      </View>
      
      
    </Modal>
  );
}
