import { FontAwesome5 } from '@expo/vector-icons';
import { TextInput, View, Text } from 'react-native';
import React, { useState, useEffect, createRef } from 'react';

import { PontoUtilizacao } from '../../entities/PontoUtilizacao';
import { inativarPontoUtilizacao } from '../../useCases/PontoUtilizacao/InativarPontoUtilizacao';
import { cadastrarPontoUtilizacao } from '../../useCases/PontoUtilizacao/CadastrarPontoUtilizacao';
import { alterarPontoUtilizacaoUsuario } from '../../useCases/PontoUtilizacao/AlterarPontoUtilizacao';
import { listarPontosUtilizacaoUsuario } from '../../useCases/PontoUtilizacao/ListarPontosUtilizacao';
import { buscarQrCodePontoUtilizacao } from '../../useCases/PontoUtilizacao/BuscarQrCodePontoUtilizacao';
import { IINativarPontoUtilizacaoDTO } from '../../useCases/PontoUtilizacao/InativarPontoUtilizacao/InativarPontoUtilizacaoDTO';
import { ICadastrarPontoUtilizacaoDTO } from '../../useCases/PontoUtilizacao/CadastrarPontoUtilizacao/CadastrarPontoUtilizacaoDTO';

import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';
import { useModal } from '../../hooks/useModal';
import { useScreen } from '../../hooks/useScreen';

import { Card } from '../../components/Common/Card';
import { Button } from '../../components/Common/Button';
import { Header } from '../../components/Common/Header';
import { Loading } from '../../components/Common/Loading';
import { ModalQrCode } from '../../components/PontosUtilizacao/ModalQrCode';
import { ModalConfirmacao } from '../../components/Common/Modal/ModalConfirmacao';
import { ModalNovoPontoUtilizacao } from '../../components/PontosUtilizacao/ModalNovoPontoUtilizacao';

import { styles } from './styles';
import { theme } from '../../global/styles/theme';

export interface INovoPontoUtilizacao {
  descricao: string;
  email: string;
}

export function PontosDeUtilizacao() {
  const { screen } = useScreen();
  const { errorToast, successToast } = useToast();
  const { usuario, token, isTokenValid } = useAuth();

  const [loading, setLoading] = useState(true);

  const {
    openModal: openAddPontoModal,
    closeModal: closeAddPontoModal,
    isModalOpen: addPontoModal,
  } = useModal();
  const {
    openModal: openRemovePontoModal,
    closeModal: closeRemovePontoModal,
    isModalOpen: removePontoModal,
  } = useModal();
  const {
    openModal: openQrCodeModal,
    closeModal: closeQrCodeModal,
    isModalOpen: qrCodeModal,
  } = useModal();

  const [pontos, setPontos] = useState<PontoUtilizacao[]>([]);
  const [pontoEdit, setPontoEdit] = useState<number | null>();
  const [pontoRemove, setPontoRemove] = useState<number | null>();
  const [qrCode, setQrCode] = useState({ descricao: '', uri: '' });
  const [pontoDescHistory, setPontoDescHistory] = useState<string | null>();

  const textInputRef = createRef<TextInput>();

  function handleEditPontoClick(id: number) {
    if (pontoEdit === id) return;

    const _pontos = [...pontos];

    if (pontoEdit) {
      const pontoIndex = _pontos.findIndex(e => e.id === pontoEdit);

      if (_pontos[pontoIndex].descricao !== pontoDescHistory)
        _pontos[pontoIndex].descricao = pontoDescHistory as string;

      setPontos(_pontos);
    }

    const pontoIndex = _pontos.findIndex(e => e.id === id);

    textInputRef.current.focus();

    setPontoDescHistory(_pontos[pontoIndex].descricao);
    setPontoEdit(id);
  }

  function handleEditInputPonto(text: string) {
    const _pontos = [...pontos];
    const pontoIndex = _pontos.findIndex(e => e.id === pontoEdit);

    _pontos[pontoIndex].descricao = text;

    setPontos(_pontos);
  }

  async function handleSaveEditPonto() {
    try {
      const novoPonto = pontos.find(e => e.id === pontoEdit);

      await alterarPontoUtilizacaoUsuario.execute(novoPonto, token);

      successToast('Ponto alterado');
      setPontoEdit(null);
    } catch (err) {
      const authExpirou = isTokenValid(err);
      errorToast(authExpirou || err);
    }
  }

  function handleRemovePonto(id: number) {
    setPontoRemove(id);
    openRemovePontoModal();
  }

  async function handleConfirmRemovePonto() {
    try {
      const pontoInativo: IINativarPontoUtilizacaoDTO = { id: pontoRemove, ativo: false };

      await inativarPontoUtilizacao.execute(pontoInativo, token);

      setPontos(pontos.filter(ponto => ponto.id !== pontoRemove));

      if (pontoEdit === pontoRemove) setPontoEdit(null);

      setPontoRemove(null);
      closeRemovePontoModal();

      successToast('Ponto de utilização removido');
    } catch (err) {
      const authExpirou = isTokenValid(err);
      errorToast(authExpirou || err);
    }
  }

  async function handleSaveNovoPonto(data: INovoPontoUtilizacao) {
    try {
      const novoPontoData: ICadastrarPontoUtilizacaoDTO = { ...data, usuarioAssociado: usuario };

      const novoPonto = await cadastrarPontoUtilizacao.execute(novoPontoData, token);

      setPontos([...pontos, novoPonto]);
      closeAddPontoModal();
      successToast(`${data.descricao} adicionado`);
    } catch (err) {
      const authExpirou = isTokenValid(err);
      errorToast(authExpirou || err);
    }
  }

  async function handleQrCodeClick(id: number) {
    const { descricao } = pontos.find(ponto => ponto.id === id) as PontoUtilizacao;

    const uri = await buscarQrCodePontoUtilizacao.execute(id, token);

    setQrCode({ descricao, uri });
    openQrCodeModal();
  }

  async function getPontosUtilizacao() {
    try {
      const pontosUtilizacao = await listarPontosUtilizacaoUsuario.execute(token);

      setPontos(pontosUtilizacao);
    } catch (err) {
      const authExpirou = isTokenValid(err);
      errorToast(authExpirou || err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (screen === 'Pontos de Utilização') {
      getPontosUtilizacao();
    } else setLoading(true);
  }, [screen]);

  return (
    <View style={styles.container}>
      <Header collapsed pontos={usuario.tipo === 1}>
        <View style={styles.content}>
          {loading ? (
            <Loading />
          ) : (
            <>
              <View style={styles.headerContainer}>
                <FontAwesome5 name="user-alt" size={18} />
                <Text style={styles.header}>Pontos de utilização</Text>
              </View>

              <View style={styles.cardContainer}>
                {pontos.map((e, i) => (
                  <Card key={e.id} style={styles.card}>
                    <View style={styles.cardContent}>
                      <TextInput
                        ref={textInputRef}
                        value={pontos[i].descricao}
                        style={styles.ponto}
                        editable={pontoEdit === e.id}
                        onChangeText={handleEditInputPonto}
                      />

                      <View style={styles.btnContainer}>
                        {pontoEdit === e.id && pontoDescHistory !== e.descricao && (
                          <Button
                            mode="text"
                            icon={<FontAwesome5 name="check" size={12} />}
                            color={theme.colors.white}
                            background={theme.colors.blue200}
                            style={styles.btnSave}
                            onPress={handleSaveEditPonto}
                          />
                        )}

                        <Button
                          mode="text"
                          icon={<FontAwesome5 name="qrcode" size={16} />}
                          color={theme.colors.text}
                          onPress={() => handleQrCodeClick(e.id as number)}
                        />

                        <Button
                          mode="text"
                          icon={<FontAwesome5 name="user-edit" size={16} />}
                          color={theme.colors.text}
                          onPress={() => handleEditPontoClick(e.id as number)}
                        />

                        <Button
                          mode="text"
                          icon={<FontAwesome5 name="trash" size={16} />}
                          color={theme.colors.red}
                          onPress={() => handleRemovePonto(e.id as number)}
                        />
                      </View>
                    </View>
                  </Card>
                ))}
              </View>

              <ModalQrCode
                uri={qrCode.uri}
                associado={usuario?.nome as string}
                header={qrCode.descricao}
                isOpen={qrCodeModal}
                onClose={closeQrCodeModal}
              />

              <Button
                text="Adicionar ponto"
                style={{ alignSelf: 'flex-end' }}
                onPress={openAddPontoModal}
              />

              <ModalConfirmacao
                header="Remover ponto de utilização?"
                isOpen={removePontoModal}
                onClose={closeRemovePontoModal}
                onConfirm={handleConfirmRemovePonto}
              />

              <ModalNovoPontoUtilizacao
                isOpen={addPontoModal}
                onClose={closeAddPontoModal}
                onAdd={handleSaveNovoPonto}
              />
            </>
          )}
        </View>
      </Header>
    </View>
  );
}
