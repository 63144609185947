import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { styles } from './styles';

interface ICard {
  backgroundColor?: string;
  children?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}

export function Card({ backgroundColor, style = {}, children }: ICard) {
  return (
    <View style={styles.container}>
      <View
        style={[
          styles.content,
          style,
          backgroundColor ? { backgroundColor: backgroundColor } : {},
        ]}
      >
        {children}
      </View>
    </View>
  );
}
