import { StyleSheet } from 'react-native';
import { theme } from '../../global/styles/theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    paddingHorizontal: 16,
  },
  card: {
    marginBottom: 16,
  },
  header: {
    marginBottom: 4,
    fontFamily: theme.fonts.roboto700,
    fontSize: 18,
  },
  footer: {
    marginTop: 4,
    fontFamily: theme.fonts.roboto400,
    fontSize: 14,
    alignSelf: 'flex-end',
  },
  image: {
    height: 427,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: 'transparent',
  },
  descricao: {
    marginBottom: 8,
    fontFamily: theme.fonts.roboto400,
    fontSize: 16,
  },
});
