import React from 'react';
import { TextStyle, ViewStyle, View,Text } from 'react-native';
import { Button as ButtonPaper } from 'react-native-paper';

import { theme } from '../../../global/styles/theme';

interface IButton {
  text?: string;
  icon?: React.ReactElement;
  mode?: 'text' | 'outlined' | 'contained';
  compact?: boolean;
  uppercase?: boolean;
  disabled?: boolean;
  style?: ViewStyle;
  textStyle?: TextStyle;
  color?: string;
  background?: string;
  onPress: () => void;
  subtitle?:React.ReactElement;  
}

const { white, text: black } = theme.colors;

export function Button(props: IButton) {
  const DEFAULT_THEME = {
    colors: {
      primary: props.background ?? theme.colors.blue200,
    },
  };

  return (
    <ButtonPaper
      mode={props.mode ?? 'contained'}
      compact={props.compact ?? true}
      uppercase={props.uppercase ?? false}
      disabled={props.disabled ?? false}
      labelStyle={[
        { color: !props.mode || props.mode === 'contained' ? white : black },

        props.color ? { color: props.color } : {},
        props.textStyle,
      ]}
      style={[{ borderColor: props.mode === 'outlined' ? black : 'transparent' }, props.style]}
      theme={DEFAULT_THEME}
      onPress={props.onPress}
    >
      {props.subtitle &&  <Text> {props.icon} <br/>{props.subtitle} </Text> }

      {!props.subtitle && props.icon && <View style={{ marginRight: props.text ? '1ch' : 0 }}>{props.icon}</View>}
      {!props.subtitle && props.text}
    </ButtonPaper>
  );
}
