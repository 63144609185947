import * as Location from 'expo-location';
import { isIOS } from 'react-device-detect';
import * as WebBrowser from 'expo-web-browser';
import React, { useState, useEffect } from 'react';
import { View, Text, Linking, Pressable } from 'react-native';
import { FontAwesome5, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';

import { PontoUtilizacao } from '../../../../entities/PontoUtilizacao';

import { useToast } from '../../../../hooks/useToast';

import { Card } from '../../../Common/Card';

import { styles } from './styles';

interface ICardAssociadoResgatarPontos {
  caixa: PontoUtilizacao;
}

export function CardAssociadoResgatarPontos({ caixa }: ICardAssociadoResgatarPontos) {
  const { errorToast } = useToast();

  const [endereco, setEndereco] = useState('');

  async function handleEnderecoClick(): Promise<void> {
    if (isIOS) return;

    const { status } = await Location.requestForegroundPermissionsAsync();

    if (status !== 'granted') return errorToast('Permissão de localização negada');

    const location = await Location.getCurrentPositionAsync({});

    const origin = `${location.coords.latitude},+${location.coords.longitude}`;
    const destination = endereco.split('/')[0];

    const api = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}`;

    await WebBrowser.openBrowserAsync(api);
  }

  async function handleWhatsAppClick(tel: string): Promise<void> {
    const whatsAppInstalado = await Linking.canOpenURL('whatsapp://send');

    if (whatsAppInstalado) await Linking.openURL(`whatsapp://send?phone=55${tel}`);
    else await Linking.openURL(`https://wa.me/55${tel}`);
  }

  async function handleEmailClick(): Promise<void> {
    const email = caixa.email;
    await Linking.openURL(`mailto:${email}`);
  }

  async function handleSiteClick(): Promise<void> {
    const site = `http://${caixa.usuarioAssociado.site}`;
    await WebBrowser.openBrowserAsync(site);
  }

  useEffect(() => {
    const _endereco = `${caixa.usuarioAssociado.endereco.endereco}, ${
      caixa.usuarioAssociado.enderecoNo
    } - ${caixa.usuarioAssociado.endereco.cidade.descricao}/${
      (caixa.usuarioAssociado.endereco.cidade as any).estado.uf
    }`;

    setEndereco(_endereco);
  }, [caixa]);

  return (
    <Card style={styles.container}>
      <Text style={styles.nome}>{caixa.usuarioAssociado.nome}</Text>
      <Text style={styles.desc}>{caixa.descricao}</Text>

      <Pressable style={styles.contentContainer} onPress={handleEnderecoClick}>
        <Text style={styles.text}>
          <View style={styles.iconContainer}>
            <FontAwesome5 name="map-marker-alt" size={16} />
          </View>

          {endereco}
        </Text>
      </Pressable>

      <Pressable
        style={styles.contentContainer}
        onPress={() => handleWhatsAppClick(caixa.usuarioAssociado.contatoTel1)}
      >
        <Text style={styles.text}>
          <View style={styles.iconContainer}>
            <FontAwesome5 name="whatsapp" size={16} />
          </View>

          {caixa.usuarioAssociado.contatoTel1}
        </Text>

        <Text style={styles.text}>
          {' - '}
          {caixa.usuarioAssociado.contatoNome1}
        </Text>
      </Pressable>

      {caixa.usuarioAssociado.contatoTel2 && (
        <Pressable
          style={styles.contentContainer}
          onPress={() => handleWhatsAppClick(caixa.usuarioAssociado.contatoTel2)}
        >
          <Text style={styles.text}>
            <View style={styles.iconContainer}>
              <FontAwesome5 name="phone" size={16} />
            </View>

            {caixa.usuarioAssociado.contatoTel2}
          </Text>

          <Text style={styles.text}>
            {' - '}
            {caixa.usuarioAssociado.contatoNome2}
          </Text>
        </Pressable>
      )}

      <Pressable style={styles.contentContainer} onPress={handleEmailClick}>
        <Text style={styles.text}>
          <View style={styles.iconContainer}>
            <Ionicons name="ios-mail" size={16} />
          </View>

          {caixa.usuarioAssociado.email}
        </Text>
      </Pressable>

      {caixa.usuarioAssociado.site && (
        <Pressable style={styles.contentContainer} onPress={handleSiteClick}>
          <Text style={styles.text}>
            <View style={styles.iconContainer}>
              <MaterialCommunityIcons name="web" size={16} />
            </View>

            {caixa.usuarioAssociado.site}
          </Text>
        </Pressable>
      )}
    </Card>
  );
}
