import { Screens } from '../contexts/ScreenContext';
import { UsuariosTipos } from '../entities/Usuario';

import { Home } from '../screens/Home';
import { Perfil } from '../screens/Perfil';
import { Extrato } from '../screens/Extrato';
import { Pedidos } from '../screens/Pedidos';
import { ResgatarPontos } from './ResgatarPontos';
import { Notificacoes } from '../screens/Notificacoes';
import { PontosDeUtilizacao } from './PontosDeUtilizacao';
import { NotificacoesDetalhes } from './NotificacoesDetalhes';
import { EncerrarConta } from './EncerrarConta';
import { Sobre } from './Sobre';
import { Termos } from './Termos';

interface IScreenList {
  screen: Screens;
  icon?: string;
  subtitle?:string;
  canAccess: UsuariosTipos[];
  dontShowOnDesktop?: boolean;
  dontShowBottomMenu?: boolean;
  showInMenu: boolean;
  auth: boolean;
  component: React.ComponentType;
}
export function Contato(){

}

export const ScreenList: IScreenList[] = [
  
  {
    screen: 'Sobre',
    icon: 'warehouse',
    subtitle:'Sobre',
    canAccess: [1, 2],
    showInMenu: true,
    auth: true,
    component: Sobre,
  },
  {
    screen: 'Extrato de Claritos',
    icon: 'file-invoice-dollar',
    subtitle:'Extrato',
    canAccess: [1, 2],
    showInMenu: true,
    auth: true,
    component: Extrato,
  },
  {
    screen: 'Meus Pedidos',
    icon: 'shopping-cart',
    subtitle:'Pedidos',
    canAccess: [1],
    showInMenu: true,
    auth: true,
    component: Pedidos,
  },
  {
    screen: 'Resgatar Claritos',
    icon: 'qrcode',
    subtitle:'Resgatar',
    canAccess: [1],
    dontShowOnDesktop: !__DEV__,
    showInMenu: true,
    auth: true,
    component: ResgatarPontos,
  },
  {
    screen: 'Notificações',
    icon: 'bell',
    subtitle:'Notificação',
    canAccess: [1, 2],
    showInMenu: true,
    auth: true,
    component: Notificacoes,
  },
  {
    screen: 'Pontos de Utilização',
    icon: 'store-alt',
    subtitle:'Utilização',
    canAccess: [2],
    showInMenu: true,
    auth: true,
    component: PontosDeUtilizacao,
  },
  {
    screen: 'Perfil',
    canAccess: [1, 2],
    icon: 'user',
    showInMenu: true,
    auth: true,
    component: Perfil,
    dontShowBottomMenu:true
  },
  {
    screen: 'Contato',
    canAccess: [1, 2],
    icon: 'envelope',
    showInMenu: true,
    auth: true,
    dontShowBottomMenu:true,
    component: Sobre,
  },
  {
    screen: 'Termos',
    canAccess: [1, 2],
    icon: 'file-contract',
    showInMenu: true,
    auth: true,
    dontShowBottomMenu:true,
    component: Termos,
  },
  {
    screen: 'Home',
    canAccess: [],
    showInMenu: false,
    auth: false,
    component: Home,
  },
  {
    screen: 'Notificações Detalhes',
    canAccess: [1, 2],
    showInMenu: false,
    auth: true,
    component: NotificacoesDetalhes,
  },
  {
    screen: 'EncerrarConta',
    subtitle:'EncerrarConta',
    canAccess: [1,2],
    showInMenu: false,
    dontShowBottomMenu:false,
    auth: true,
    component: EncerrarConta,
  },

];
