import { StyleSheet } from 'react-native';
import { theme } from '../../../global/styles/theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  card: {
    marginTop:16,    
    backgroundColor: 'white',
    padding: 8,
  },
  header: {
    marginBottom: 10,
    fontFamily: theme.fonts.roboto700,
    fontSize: 18,
  },
  footer: {
    marginTop: 4,
    fontFamily: theme.fonts.roboto400,
    fontSize: 14,
    alignSelf: 'flex-end',
  },

  descricao: {
    marginBottom: 8,
    fontFamily: theme.fonts.roboto400,
    fontSize: 16,
  },
});
