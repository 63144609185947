import { IApi } from '../../../api/IApi';
import { Usuario } from '../../../entities/Usuario';
import { ILogarUsuarioDTO } from './LogarUsuarioDTO';

interface ApiResponse {
  token: string;
  usuario: Usuario;
}

export class LogarUsuarioController {
  constructor(private api: IApi) {}

  async execute(data: ILogarUsuarioDTO): Promise<ApiResponse> {
    const usuario = await this.api.post<ApiResponse, ILogarUsuarioDTO>('auth/login', data);

    return usuario;
  }
}
