import { StyleSheet } from 'react-native';
import { theme } from '../../../../global/styles/theme';

export const styles = StyleSheet.create({
  active: {
    width: 60,
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
    
    backgroundColor: theme.colors.blue100,
    borderRadius: 9,
  },
  inactive: {
    width: 70,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',

    backgroundColor: 'transparent',
    borderRadius: 8,
  },
});
