import React from 'react';
import { Text } from 'react-native';

import { Card } from '../../../Common/Card';

import { styles } from './styles';

interface ICardWarning {
  header: string;
  content: string;
  component?: React.ReactNode;
}

export function CardWarning({ header, content, component }: ICardWarning) {
  return (
    <Card style={styles.container}>
      <Text style={styles.header}>{header}</Text>
      <Text style={styles.content}>{content}</Text>
      {component}
    </Card>
  );
}
