import { IApi } from '../../../api/IApi';
import { IBuscarTermoDeUsoDTO } from './BuscarTermoDeUsoDTO';

export class BuscarTermoDeUsoController {
  constructor(private api: IApi) {}

  async execute(): Promise<string> {
    const { termo } = await this.api.get<IBuscarTermoDeUsoDTO>('termos');

    return termo;
  }
}
