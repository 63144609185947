import Toast from 'react-native-toast-message';

interface IToast {
  type: 'success' | 'error';
  message: string;
}

export const useToast = () => {
  function handleMessage(data: any): string {
    if (data.message) return data.message;

    if (typeof data === 'string') return data;

    if (data?.response?.data?.message) return data?.response?.data?.message;

    return 'Erro inesperado';
  }

  const toast = ({ type, message }: IToast) => Toast.show({ type, text1: message });

  const successToast = (data: any) => toast({ type: 'success', message: handleMessage(data) });

  const errorToast = (data: any) => toast({ type: 'error', message: handleMessage(data) });

  return { successToast, errorToast };
};
