import { StyleSheet } from 'react-native';
import { theme } from '../../../../global/styles/theme';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.red,
    borderRadius: 4,
  },
  header: {
    marginBottom: 8,
    color: theme.colors.white,
    fontSize: 16,
    fontFamily: theme.fonts.roboto700,
  },
  content: {
    color: theme.colors.white,
    fontFamily: theme.fonts.roboto400,
  },
});
