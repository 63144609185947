import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  textInput: {
    backgroundColor: 'transparent',
  },
  footer: {
    marginTop: 16,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});
