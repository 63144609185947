import { StyleSheet } from 'react-native';
import { theme } from '../../../global/styles/theme';

export const styles = StyleSheet.create({
  container: {
    marginVertical: 8,
  },
  section: {
    flexDirection: 'row',
  },
  labelData: {
    marginTop: 6,
    marginBottom: 8,
  },
  time: {
    fontFamily: theme.fonts.roboto400,
    fontSize: 16,
  },
  content: {
    maxWidth: '100%',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerTxt: {
    fontSize: 18,
  },
  lblTitulo: {
    fontFamily: theme.fonts.roboto700,
  },
  associado: {
    fontSize: 15,
    fontFamily: theme.fonts.roboto700,
  },
  valor: {
    fontFamily: theme.fonts.roboto400,
  },
  body: {
    marginTop: 4,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  divider: {
    marginVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.text,
    opacity: 0.2,
  },
  btnResgatar: {
    height: 'fit-content',
    color: theme.colors.white,
  },
  box: {
    width: 32,
    height: 32,
    marginRight: 8,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: 32,
  },
});
