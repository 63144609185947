import { IApi } from '../../../api/IApi';
import { ISubscribeNotificacoesDTO } from './SubscribeNotificacoesDTO';

export class SubscribeNotificacoesController {
  constructor(private api: IApi) {}

  async execute(subscription: ISubscribeNotificacoesDTO, token: string): Promise<void> {
    await this.api.post<void, ISubscribeNotificacoesDTO>('notificacoes', subscription, token);
  }
}
