import { buscaCep } from '../../../services/Cep';
import { Validacoes } from '../Validacoes/Validacoes';
import { IAlterarPerfilUsuarioValidacaoDTO } from './AlterarPerfilUsuarioValidacaoDTO';

export class AlterarPerfilUsuarioValidacaoController {
  constructor(private validacoes: Validacoes) {}

  public async handle(props: IAlterarPerfilUsuarioValidacaoDTO): Promise<boolean> {
    if (!props.endereco.cep) throw new Error('CEP inválido');

    await buscaCep(props.endereco.cep);

    if (!props.endereco.endereco) throw new Error('Endereço inválido');

    if (props.enderecoNo <= 0) throw new Error('Número inválido');

    if (!props.endereco.bairro) throw new Error('Bairro inválido');

    if (!props.email || !this.validacoes.validaEmail(props.email))
      throw new Error('Email inválido');

    if (!this.validacoes.validaTelefone(props.contatoTel1)) throw new Error('Telefone inválido');

    if (!props.contatoNome1 || props.contatoNome1.trim().length < 2)
      throw new Error('Contato inválido');

    if (props.contatoTel2 && !this.validacoes.validaTelefone(props.contatoTel2))
      throw new Error('Telefone 2 inválido');

    if (props.contatoTel2 && !props.contatoNome2) throw new Error('Contato tel 2 inválido');

    if (props.site && !this.validacoes.validaSite(props.site)) throw new Error('Site inválido');

    return true;
  }
}
