export const theme = {
  colors: {
    blue50: '#6ee0f0',
    blue100: '#55CCDD',
    blue200: '#29B0C1',
    blue300: '#1c96a6',
    blue400: '#0c6b78',
    blue500: '#06535e',

    text: '#262626',
    white: '#FCFCFC',

    white_100: '#F2F2F2',
    white_600: '#707070',

    textInput: '#ceeaf0',

    red: '#bf0b0b',
    green: '#148018',
    yellow: '#edbb13',

    credito: '#0ca812',
  },

  fonts: {
    pontos: 'DMSans_400Regular',
    roboto400: 'Roboto_400Regular',
    roboto700: 'Roboto_700Bold',
    menu: 'Montserrat_500Medium',
  },
};
