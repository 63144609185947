import React from 'react';
import { View } from 'react-native';
import QrReader from 'react-qr-reader';

import { useToast } from '../../../hooks/useToast';

import { styles } from './styles';

interface IQrCodeResgatarPontos {
  onScan: (data: string | null) => void;
}

export function QrCodeResgatarPontos({ onScan }: IQrCodeResgatarPontos) {
  const { errorToast } = useToast();

  return (
    <View style={styles.container}>
      <View style={styles.camera}>
        <QrReader
          delay={300}
          onError={err => (__DEV__ ? onScan('1') : errorToast(err))}
          onScan={onScan}
        />
      </View>
    </View>
  );
}
