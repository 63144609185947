import { IApi } from '../../../api/IApi';
import { PontoUtilizacao } from '../../../entities/PontoUtilizacao';
import { ICadastrarPontoUtilizacaoDTO } from './CadastrarPontoUtilizacaoDTO';

export class CadastrarPontoUtilizacaoController {
  constructor(private api: IApi) {}

  async execute(data: ICadastrarPontoUtilizacaoDTO, token: string): Promise<PontoUtilizacao> {
    const novoPonto = await this.api.post<PontoUtilizacao, ICadastrarPontoUtilizacaoDTO>(
      'usuarios/pontosUtilizacao',
      data,
      token
    );

    return novoPonto;
  }
}
