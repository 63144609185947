import { IApi } from '../../../api/IApi';
import { Notificacao } from '../../../entities/Notificacao';

export class ListarNotificacoesController {
  constructor(private api: IApi) {}

  async execute(ultimoId: number, token: string): Promise<Notificacao[]> {
    const listaNotificacoes = await this.api.get<Notificacao[]>(
      `usuarios/notificacoes/${ultimoId}`,
      token
    );

    return listaNotificacoes;
  }
}
