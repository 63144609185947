import { StyleSheet } from 'react-native';
import { isIOS } from 'react-device-detect';
import { theme } from '../../global/styles/theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    padding: 16,
    marginTop: isIOS ? 16 : 0,
  },
  header: {
    marginVertical: 16,
  },
  titulo: {
    fontFamily: theme.fonts.roboto700,
    fontSize: 20,
  },
  data: {
    fontFamily: theme.fonts.roboto400,
    fontSize: 14,
    color: theme.colors.white_600,
  },
  imagem: {
    width: '100%',
  },
  voltarContainer: {
    justifyContent: 'flex-start',
  },
  voltar: {
    fontFamily: theme.fonts.roboto700,
    fontSize: 16,
  },
  modalImagem: {
    padding: 0,
    margin: 0,
    backgroundColor: 'black',
  },
});
