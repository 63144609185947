import { IAlterarSenhaUsuarioValidacaoDTO } from './AlterarSenhaUsuarioValidacaoDTO';

export class AlterarSenhaUsuarioValidacaoController {
  public handle({ senhaNova, senhaNovaConfirmacao }: IAlterarSenhaUsuarioValidacaoDTO): boolean {
    if (!senhaNova.length || !senhaNovaConfirmacao.length) throw new Error('Senha inválida');

    if (senhaNova === '' || senhaNovaConfirmacao === '') throw new Error('Senha inválida');

    if (senhaNova.length < 8) throw new Error('Senha inválida');

    if (senhaNova !== senhaNovaConfirmacao) throw new Error('Senhas não coincidem');

    return true;
  }
}
