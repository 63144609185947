import axios, { AxiosInstance } from 'axios';

import { IApi } from '../IApi';

class Axios implements IApi {
  private readonly baseUrl: string;
  private readonly server: AxiosInstance;

  constructor() {
    this.baseUrl = 'https://appglass.glasscontrol.com.br/apinova';
    
    
    this.server = axios.create({
      baseURL: this.baseUrl,
      headers: { 'Content-Type': 'application/json' },
      timeout: 20000,
    });
  }

  public async get<Res>(url: string, token?: string): Promise<Res> {
    const { data } = await this.server.get(url, { headers: { auth: token } });

    return data;
  }

  public async post<Res, Payload>(url: string, payload: Payload, token?: string): Promise<Res> {
    const { data } = await this.server.post(url, payload, { headers: { auth: token } });

    return data;
  }
}

const api = new Axios();

export { api };
