import React, { useEffect, useState } from 'react';
import { View, Text,Image } from 'react-native';
import { Checkbox } from 'react-native-paper';
import { Button } from '../../components/Common/Button';
import { Header } from '../../components/Common/Header';
import { Loading } from '../../components/Common/Loading';
import {PopUp} from '../../components/Common/Modal/PopUp';
import { useAuth } from '../../hooks/useAuth';
import { useScreen } from '../../hooks/useScreen';
import { styles } from './styles';
import EMPRESA from '../../../assets/Sobre/Empresa.png';
import FUNDO from '../../../assets/Sobre/fundo.jpg';
import Logo_Degrade from '../../../assets/Sobre/Logo_Degrade.png';
import FRASE from '../../../assets/Sobre/Frase.png';
import ICONE from '../../../assets/Sobre/icone.png';
import { useModal } from '../../hooks/useModal';

import { ImageBackground } from "react-native";

export function Sobre() {
  const { usuario, token, isTokenValid } = useAuth();
  const [reloadData, setReload] = useState(false);
  const { screen } = useScreen();


  const {
    openModal: openModal,
    closeModal: closeModal,
    isModalOpen: addModal,
    
  } = useModal(true);
  
  useEffect(() => {
    //if (screen === 'Sobre')
      //openModal();
  }, [screen]);  

  return (
    
    <View style={styles.container }  >
      <Header collapsed pontos={false} >
          <ImageBackground
          style={{width: '100%', height: '100%', padding:0,margin:0}}
          source={FUNDO}>
          <Image source={Logo_Degrade} style={styles.LogoDegrade} resizeMode="contain" />    
          <View style={styles.content}>
            <Text style={styles.descricao}>
              MAIS DE 20 ANOS DE HISTÓRIA E
            </Text>
            <Text style={styles.descricao}>
              INVESTIMENTO CONTÍNUO EM ALTA
            </Text>
            <Text style={styles.descricao}>
              TECNOLOGIA     
            </Text>
            <Image source={FRASE} style={styles.Frase} resizeMode="contain" />    
            
            <ImageBackground style={styles.empresa}source={EMPRESA}>
              <Text style={styles.Frase2}>
                PENSOU EM VIDROS? EXIJA CLARITY
              </Text>      
            </ImageBackground>              
            
            
            <Text style={styles.Lista}>
              <ul style={{listStyleType: "none", textAlign:'left' }}>
                <li>
                  <Text style={styles.NossoProdutos}>
                    NOSSOS PRODUTOS
                  </Text> 
                </li>              
              </ul>
              <ul style={{listStyleType: "none", textAlign:'left' }}>
                <li><Image source={ICONE} style={styles.Icone} resizeMode="contain" /> Temperados</li>
                <li><Image source={ICONE} style={styles.Icone} resizeMode="contain" /> Laminados</li>
                <li><Image source={ICONE} style={styles.Icone} resizeMode="contain" /> Temperados laminados</li>                            
                <li><Image source={ICONE} style={styles.Icone} resizeMode="contain" /> Habitat (vidro com controle solar)</li>
                <li><Image source={ICONE} style={styles.Icone} resizeMode="contain" /> Acidato</li>
                <li><Image source={ICONE} style={styles.Icone} resizeMode="contain" /> Espelhos</li>
              </ul>
            </Text>
            
          </View>
          </ImageBackground>
          <PopUp
                header="Estrada dos sonhos"
                isOpen={addModal}
                onClose={closeModal}
              />
        </Header>

    </View>
  );
}
