import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Header } from '../../components/Common/Header';
import {ModalTermos} from '../../components/Home/Cadastro/ModalTermos';
import { useAuth } from '../../hooks/useAuth';
import { styles } from './styles';
import { useModal } from '../../hooks/useModal';
import { useScreen } from '../../hooks/useScreen';
import { ImageBackground } from "react-native";
import { useNavigation } from '@react-navigation/native';

export function Termos() {
  const { usuario, token, isTokenValid } = useAuth();
  const { setScreen } = useScreen();
  const { navigate } = useNavigation();

  const {
    openModal: openModal,
    closeModal: closeModal,
    isModalOpen: addModal,
  } = useModal(true);
  const { screen } = useScreen();
  
  useEffect(() => {
    if (screen === 'Termos')
      openModal();
  }, [screen]);  

  function close(){
    closeModal();
    setScreen('Sobre');
    navigate('Sobre' as any);
  }
  return (
    
    <View style={styles.container }>
      <Header collapsed pontos={false} >
        <ModalTermos
                
                isOpen={addModal}
                onClose={close}
              />
        </Header>
       

    </View>
  );
}
