import { IApi } from '../../../api/IApi';
import { IINativarPontoUtilizacaoDTO } from './InativarPontoUtilizacaoDTO';

export class INativarPontoUtilizacaoController {
  constructor(private api: IApi) {}

  async execute(data: IINativarPontoUtilizacaoDTO, token: string): Promise<void> {
    await this.api.post<void, IINativarPontoUtilizacaoDTO>(
      'usuarios/pontosUtilizacao/desativar',
      data,
      token
    );
  }
}
