import { StyleSheet } from 'react-native';
import { isIOS } from 'react-device-detect';
import { theme } from '../../../global/styles/theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: isIOS ? 16 : 0,
  },
  wrapper: {
    height: '100vh',
  },
  content: {
    height: '100%',
    margin: 0,
  },
  loadingContainer: {
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    marginVertical: 16,
  },
  titulo: {
    fontFamily: theme.fonts.roboto700,
    fontSize: 20,
  },
  data: {
    fontFamily: theme.fonts.roboto400,
    fontSize: 14,
    color: theme.colors.white_600,
  },
  imagemContainer: {
    alignItems: 'flex-start',
    width: '100%',
    height: '100vh',
  },
  imagem: {
    // marginBottom: 16,
    width: '100%',
    height: '100vw',
  },
  voltarText: {
    marginHorizontal: 0,
    marginVertical: 0,
    fontSize: 16,
    fontFamily: theme.fonts.roboto700,
  },
  voltarBtn: {
    width: 'fit-content',
  },
  modalImagem: {
    padding: 0,
    margin: 0,
    backgroundColor: 'black',
  },
});
