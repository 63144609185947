import { cnpj, cpf } from 'cpf-cnpj-validator';
import validaTelefone from 'validar-telefone';
import { IValidacoes } from './IValidacoes';

export class Validacoes implements IValidacoes {
  public validaEmail(email: string): boolean {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    return regex.test(email.toLowerCase());
  }

  public validaTelefone(tel: string): boolean {
    const _tel = tel.trim();

    return validaTelefone(_tel, {
      permitirCelular: true,
      codigoAreaPresente: true,
      codigoPaisPossivel: false,
      validarTamanho: true,
    });
  }

  public validaSite(url: string): boolean {
    const _url = url.includes('http') ? url.trim() : `http://${url.trim()}`;

    try {
      new URL(_url);
      return true;
    } catch (err) {
      err;
    }

    return false;
  }

  public validaCnpjCpf(cnpjCpf: string): boolean {
    const _cnpj = cnpj.format(cnpjCpf.trim());
    const _cpf = cpf.format(cnpjCpf.trim());

    if (!cnpj.isValid(_cnpj) && !cpf.isValid(_cpf)) return false;

    return true;
  }

  public formataCnpjCpf(cnpjCpf: string): string {
    const _cnpj = cnpj.format(cnpjCpf.trim());
    const _cpf = cpf.format(cnpjCpf.trim());

    if (cnpj.isValid(_cnpj)) return cnpj.format(_cnpj);

    if (cpf.isValid(_cpf)) return cpf.format(_cpf);

    return cnpjCpf;
  }
}
