import React from 'react';
import { View, Text } from 'react-native';

import { theme } from '../../../global/styles/theme';

interface ISemMaisRegistro {
  pedido?: boolean;
  data: unknown[];
}

export function SemMaisRegistro({ pedido, data }: ISemMaisRegistro) {
  if (data.length <= 0) return null;

  return (
    <View
      style={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 16,
      }}
    >
      <Text
        style={{
          fontFamily: theme.fonts.roboto700,
          fontSize: 20,
          color: theme.colors.blue300,
          opacity: 0.5,
        }}
      >
        {`Você não possui mais ${pedido ? 'pedidos' : 'registros'}`}
      </Text>
    </View>
  );
}
