import React from 'react';
import { FlatList } from 'react-native';

import { Notificacao } from '../../../entities/Notificacao';

import { Loading } from '../../Common/Loading';
import { CardNotificacao } from '../CardNotificacao';
import { SemRegistro } from '../../Common/SemRegistro';
import { AtualizarLista } from '../../Common/AtualizarLista';

interface IListaNotificacoes {
  notificacoes: Notificacao[];
  loadingMore: boolean;
  onClick: () => void;
  onUpdate: () => void;
}

export function ListaNotificacoes({
  notificacoes,
  loadingMore,
  onClick,
  onUpdate,
}: IListaNotificacoes) {
  return (
    <FlatList
      data={notificacoes}
      keyExtractor={item => `notificacao_${item.id.toString()}`}
      renderItem={({ item }) => <CardNotificacao notificacao={item} onClick={onClick} />}
      ListFooterComponent={
        loadingMore ? (
          <Loading />
        ) : notificacoes.length > 0 ? (
          <AtualizarLista onPress={onUpdate} />
        ) : null
      }
      ListEmptyComponent={<SemRegistro screen="Meus Pedidos" />}
      contentContainerStyle={{
        flex: 1,
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    />
  );
}
