import { mobileModel, osName, osVersion } from 'react-device-detect';

import { subscribeNotificacoesController } from '../../useCases/Notificacao/Subscribe';

class PushNotification {
  private publicVapidKey =
    'BAgCSWyzz8qH7Vt5GeVNBys9PmAwUgpr9QfEmtlbfzNMFv4zMR1Mo8UaXpx_1Y_-uJ4NxnR0HxN_fmBL4KlYlB4';

  public register(token: string): void {
    if (!('serviceWorker' in navigator)) return;

    if (!('PushManager' in window)) return;

    this.registerServiceWorker(token);
  }

  private async registerServiceWorker(token: string): Promise<void | ServiceWorkerRegistration> {
    // manter caminho do service worker mapeado de acordo com o caminho relativo /static/js
    const sw = '../../sw-notificacao.js';

    const register = await navigator.serviceWorker.register(sw, { scope: '/' });

    const status = await Notification.requestPermission();

    if (status !== 'granted' && status !== 'denied')
      throw new Error('Permissão de notificações negada');

    const subscription = await register.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: this.urlBase64ToUint8Array(this.publicVapidKey),
    });

    const dto = { subscription, device: this.getDevice() };

    await subscribeNotificacoesController.execute(dto, token);
  }

  private getDevice(): string {
    const modelo = mobileModel === 'none' ? 'PC' : mobileModel;

    return `${modelo}_${osName}_${osVersion}`;
  }

  private urlBase64ToUint8Array(base64String: string): Uint8Array {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    outputArray.map((data, i) => (outputArray[i] = rawData.charCodeAt(i)));

    return outputArray;
  }
}

const pushNotification = new PushNotification();

export { pushNotification };
