import { IApi } from '../../../api/IApi';
import { IBuscarPontoUtilizacaoDTO } from './BuscarPontoUtilizacaoDTO';

export class BuscarPontoUtilizacaoController {
  constructor(private api: IApi) {}

  async execute(id: number, token: string): Promise<IBuscarPontoUtilizacaoDTO> {
    const pontoUtilizacao = await this.api.get<IBuscarPontoUtilizacaoDTO>(
      `usuarios/pontosUtilizacao/${id}`,
      token
    );

    return pontoUtilizacao;
  }
}
