import { IApi } from '../../../api/IApi';
import { IFatorConversaoUsuarioDTO } from './FatorConversaoUsuarioDTO';

export class FatorConversaoUsuarioController {
  constructor(private api: IApi) {}

  async execute(token: string): Promise<number> {
    const { fator } = await this.api.get<IFatorConversaoUsuarioDTO>('usuarios/fator', token);

    return fator;
  }
}
