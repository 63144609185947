import { View } from 'react-native';
import React, { useEffect } from 'react';
import { createDrawerNavigator, DrawerNavigationOptions } from '@react-navigation/drawer';

import { useAuth } from '../hooks/useAuth';
import { usePontos } from '../hooks/usePontos';

import { ScreenList } from '../screens';
import { DrawerMenu } from '../components/Menu/DrawerMenu';

import { theme } from '../global/styles/theme';

const SCREEN_OPTIONS: DrawerNavigationOptions = {
  drawerStyle: {
    backgroundColor: theme.colors.blue300,
    elevation: 5,
  },
  headerShown: false,
};

export function AuthRoutes() {
  const { usuario } = useAuth();
  const { setPontos } = usePontos();
  const { Navigator, Screen } = createDrawerNavigator();

  async function load() {
    await setPontos();
  }

  useEffect(() => {
    if (usuario) load();
  }, []);

  return (
    <View style={{ flex: 1, backgroundColor: theme.colors.white_100 }}>
      <Navigator
        backBehavior="initialRoute"
        screenOptions={SCREEN_OPTIONS}
        drawerContent={props => DrawerMenu({ props, userTipo: usuario?.tipo })}
      >
        {ScreenList.filter(e => e.auth).map(e => (
          <Screen key={e.screen} name={e.screen} component={e.component} />
        ))}
      </Navigator>
    </View>
  );
}
