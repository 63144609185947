import { IApi } from '../../../api/IApi';
import { IBuscarQrCodePontoUtilizacaoDTO } from './BuscarQrCodePontoUtilizacaoDTO';

export class BuscarQrCodePontoUtilizacaoController {
  constructor(private api: IApi) {}

  async execute(id: number, token: string): Promise<string> {
    const { qrCode } = await this.api.get<IBuscarQrCodePontoUtilizacaoDTO>(
      `usuarios/pontosUtilizacao/qrCode/${id}`,
      token
    );
    const base64 = `data:image/png;base64,${qrCode}`;

    return base64;
  }
}
