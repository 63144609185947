export const useNumberFormat = () => {
  const currency = (value: number): string =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);

  const milhar = (value: number): string =>
    new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
    }).format(value);

  return { currency, milhar };
};
