interface ICidade {
  descricao: string;
  ibgeId: number;
  uf: string;
}

export class Endereco {
  public cep!: string;
  public endereco!: string;
  public bairro!: string;
  public cidade!: ICidade;

  constructor(props: Endereco) {
    Object.assign(this, props);
  }
}
