import React from 'react';
import { View, Text } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';

import { ScreenList } from '../../../screens';

import { Screens } from '../../../contexts/ScreenContext';

import { styles } from './styles';
import { theme } from '../../../global/styles/theme';

interface ISemRegistro {
  screen: Screens;
}

export function SemRegistro({ screen }: ISemRegistro) {
  return (
    <View style={[styles.semRegistro, { marginTop: screen === 'Notificações' ? 124 : 132 }]}>
      <FontAwesome5
        name={ScreenList.find(e => e.screen === screen)?.icon ?? 'file-invoice-dollar'}
        size={72}
        color={theme.colors.blue300}
        style={{ opacity: 0.5 }}
      />
      <Text style={styles.semRegistroText}>Sem registro</Text>
    </View>
  );
}
