import React, { createContext, useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { Usuario } from '../entities/Usuario';

import { useToast } from '../hooks/useToast';

interface IAuthContext {
  usuario?: Usuario;
  token: string;
  loading: boolean;
  setUser: (usuario: Usuario) => Promise<void>;
  setToken: (token: string) => Promise<void>;
  isTokenValid: (err: any) => string;
  logout: () => void;
}

interface IAuthProvider {
  children: React.ReactNode;
}

export const AuthContext = createContext({} as IAuthContext);

export function AuthProvider({ children }: IAuthProvider) {
  const { errorToast } = useToast();

  const [token, setTokenState] = useState('');
  const [loading, setLoading] = useState(true);
  const [usuario, setUsuario] = useState<Usuario>();

  useEffect(() => {
    (async function loadStoragedData() {
      try {
        const usuarioSerializado = await AsyncStorage.getItem('@AuthData:User');
        const tokenSerializado = await AsyncStorage.getItem('@AuthData:Token');

        if (usuarioSerializado && tokenSerializado) {
          const userData: Usuario = JSON.parse(usuarioSerializado);
          const tokenData: string = JSON.parse(tokenSerializado);

          setUsuario(userData);
          setTokenState(tokenData);
        }
      } catch (err) {
        errorToast(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const setUser = async (usuario: Usuario) => {
    setUsuario(usuario);

    AsyncStorage.setItem('@AuthData:User', JSON.stringify(usuario));
  };

  const setToken = async (token: string) => {
    setTokenState(token);

    AsyncStorage.setItem('@AuthData:Token', JSON.stringify(token));
  };

  const isTokenValid = (err: any): string => {
    if (err?.response?.data === 'Token inválido') {
      logout();

      return 'Tempo de login expirou';
    }
  };

  const logout = async () => {
    setUsuario(undefined);
    setToken(undefined);

    AsyncStorage.removeItem('@AuthData:User');
    AsyncStorage.removeItem('@AuthData:Token');
  };

  return (
    <AuthContext.Provider
      value={{ usuario, token, loading, setUser, setToken, isTokenValid, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
}
