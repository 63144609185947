import { IApi } from '../../../../api/IApi';
import { IUtilizarPontosUsuarioDTO } from './UtilizarPontosUsuarioDTO';

export class UtilizarPontosUsuarioController {
  constructor(private api: IApi) {}

  async execute(pontos: IUtilizarPontosUsuarioDTO, token: string): Promise<void> {
    await this.api.post<void, IUtilizarPontosUsuarioDTO>('usuarios/utilizarPontos', pontos, token);
  }
}
