import { IApi } from '../../../api/IApi';
import { ICadastrarUsuarioDTO } from './CadastrarUsuarioDTO';

export class CadastrarUsuarioController {
  constructor(private api: IApi) {}

  async execute(data: ICadastrarUsuarioDTO): Promise<void> {
    await this.api.post<void, ICadastrarUsuarioDTO>('usuarios', data);
  }
}
