import React from 'react';
import moment from 'moment';
import { Card } from 'react-native-paper';
import { Pressable, Text } from 'react-native';

import { Notificacao } from '../../../entities/Notificacao';

import { useNotificacao } from '../../../hooks/useNotificacao';

import { styles } from './styles';

interface ICardNotificao {
  notificacao: Notificacao;
  onClick: () => void;
}

export function CardNotificacao({ notificacao, onClick }: ICardNotificao) {
  const { setNotificacao } = useNotificacao();

  function handleCardClick() {
    setNotificacao(notificacao);

    onClick();
  }

  return (
    <Card style={styles.card}>
      <Pressable onPress={handleCardClick}>
        <Text style={styles.header}>{notificacao.titulo}</Text>

        <Text style={styles.descricao}>{notificacao.descricao}</Text>

        <Text style={styles.footer}>
          <Text style={{ fontWeight: '700' }}>Publicado em: </Text>
          {moment(notificacao.cadastroEm).format('L')}
        </Text>
      </Pressable>
    </Card>
  );
}
