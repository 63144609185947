import React from 'react';
import { FontAwesome5 } from '@expo/vector-icons';
import { Text, Pressable, View } from 'react-native';
import { DrawerContentComponentProps } from '@react-navigation/drawer';

import { useScreen } from '../../../../hooks/useScreen';
import { Screens } from '../../../../contexts/ScreenContext';
import { Sobre,Teste } from '../../../../screens/Sobre';
import { styles } from './styles';

interface IDrawerOption extends DrawerContentComponentProps {
  screen: Screens;
  icon: string;
  index: number;
  currentPage: number;
}

export function DrawerOption({ screen, icon, index, currentPage, navigation }: IDrawerOption) {
  const { screen: activeScreen, setScreen, setReloadData } = useScreen();

  function handleButtonClick() {

    if(screen=='Contato')
    {
      window.open('mailto:test@example.com');
    }
    else{
      setScreen(screen);
      navigation.jumpTo(screen);
  
      if (screen === activeScreen) setReloadData(true);
    }
    
    
  }

  return (
    <Pressable
      style={[styles.container, index === currentPage ? styles.active : {}]}
      onPress={handleButtonClick}
    >
      <View style={{ width: 32 }}>
        <FontAwesome5 name={icon} style={styles.svg} />
      </View>

      <Text style={styles.option}>{screen}</Text>
    </Pressable>

    
  );
}
