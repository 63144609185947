import { StyleSheet } from 'react-native';
import { theme } from '../../../../global/styles/theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginVertical: 8,
  },
  content: {
    maxWidth: '100%',
    flexDirection: 'row',
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  valorContainer: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },
  valor: {
    fontFamily: theme.fonts.roboto700,
    fontSize: 20,
    marginLeft: 8,
  },
  pedidoContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
  },
  pedido: {
    fontFamily: theme.fonts.roboto400,
    fontSize: 16,
  },
  labelData: {
    marginTop: 6,
    marginBottom: 8,
  },
  time: {
    fontFamily: theme.fonts.roboto400,
    fontSize: 16,
  },
  remove: {
    padding: 8,
    marginLeft: 8,
  },
  caret: {
    width: 32,
    height: 32,
    marginRight: 8,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: 32,
  },
});
