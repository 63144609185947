import { Validacoes } from '../Validacoes/Validacoes';
import { ICadastrarPontoUtilizacaoValidacaoDTO } from './CadastrarPontoUtilizacaoValidacaoDTO';

export class CadastrarPontoUtilizacaoValidacaoController {
  constructor(private validacoes: Validacoes) {}

  public handle({ descricao, email }: ICadastrarPontoUtilizacaoValidacaoDTO): boolean {
    if (!descricao.length) throw new Error('Descrição inválida');

    if (descricao.length < 5) throw new Error('A descrição deve ter pelo menos 5 caracteres');

    if (descricao.length > 20) throw new Error('A descrição deve ter no máximo 20 caracteres');

    if (!this.validacoes.validaEmail(email)) throw new Error('Email inválido');

    return true;
  }
}
