import { buscarPontosUsuario } from '../useCases/Usuario/PontosUsuario';
import { fatorConversaoUsuario } from '../useCases/Usuario/FatorConversaoUsuario';

import { useAuth } from '../hooks/useAuth';

export const usePontos = () => {
  const { usuario, token, setUser } = useAuth();

  const setPontos = async (): Promise<void> => {
    if (usuario.tipo === 2) return;

    const { pontosTotal } = await buscarPontosUsuario.execute(token);

    const fator = await fatorConversaoUsuario.execute(token);

    const pontosDinheiro = pontosTotal * fator ?? 0;

    if (usuario) setUser({ ...usuario, pontosTotal, pontosDinheiro });
  };

  return { setPontos };
};
