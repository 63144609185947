import { View } from 'react-native';
import React, { useState } from 'react';

import { useAuth } from '../../hooks/useAuth';
import { useModal } from '../../hooks/useModal';

import { Header } from '../../components/Common/Header';
import { Loading } from '../../components/Common/Loading';
import { FormPerfil } from '../../components/Perfil/FormPerfil';
import { HeaderPerfil } from '../../components/Perfil/HeaderPerfil';
import { FooterPerfil } from '../../components/Perfil/FooterPerfil';
import { ModalAlterarSenha } from '../../components/Perfil/ModalAlterarSenha';

import { styles } from './styles';

export function Perfil() {
  const { usuario } = useAuth();
  const { isModalOpen, openModal, closeModal } = useModal();
  
  const [salvar, setSalvar] = useState(false);
  const [editando, setEditando] = useState(false);
  const [cancelar, setCancelar] = useState(false);

  if (!usuario) return <Loading />;

  return (
    <View style={styles.container}>
      <Header collapsed pontos={false}>
        <View style={styles.content}>
          <HeaderPerfil />

          <FormPerfil
            editando={editando}
            cancelar={cancelar}
            salvar={salvar}
            setEditando={() => setEditando(false)}
            setCancelar={() => setCancelar(false)}
            setSalvar={() => setSalvar(false)}
          />

          <FooterPerfil
            editando={editando}
            editar={() => setEditando(true)}
            salvar={() => setSalvar(true)}
            cancelar={() => setCancelar(true)}
            alterarSenha={openModal}
          />

        </View>
        
        <ModalAlterarSenha isOpen={isModalOpen} onClose={closeModal} />
      </Header>
    </View>
  );
}
