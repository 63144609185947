import moment from 'moment';
import React, { useEffect } from 'react';
import { Image, Pressable, ScrollView, Text, View } from 'react-native';

import { imagemNotificacao } from '../../useCases/Notificacao/ImagemNotificacao';

import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';
import { useModal } from '../../hooks/useModal';
import { useScreen } from '../../hooks/useScreen';
import { useNotificacao } from '../../hooks/useNotificacao';

import { Modal } from '../../components/Common/Modal';
import { Button } from '../../components/Common/Button';

import { styles } from './styles';
import { useNavigation } from '@react-navigation/native';

export function NotificacoesDetalhes() {
  const { token } = useAuth();
  const { errorToast } = useToast();
  const { navigate, goBack } = useNavigation();
  const { screen, setScreen } = useScreen();
  const { notificacao, setNotificacao } = useNotificacao();
  const { isModalOpen, openModal, closeModal } = useModal();

  async function getNotificacao() {
    try {
      const imagem = await imagemNotificacao.execute(notificacao.id, token);

      setNotificacao({ ...notificacao, imagem });
    } catch (err) {
      errorToast(err);
    }
  }

  function handleVoltarClick() {
    // navigate('Notificação' as any);
    setScreen('Notificações');
    goBack();
  }

  useEffect(() => {
    if (screen === 'Notificações Detalhes') getNotificacao();
    else setNotificacao(null);
  }, [screen]);

  return (
    <>
      <View style={styles.container}>
        <ScrollView style={styles.content} scrollEventThrottle={16} disableIntervalMomentum={true}>
          <View style={styles.voltarContainer}>
            <Button
              text="Voltar"
              mode="text"
              background="transparent"
              textStyle={styles.voltar}
              onPress={handleVoltarClick}
            />
          </View>

          <View style={styles.header}>
            <Text style={styles.titulo}>{notificacao?.titulo}</Text>
            <Text style={styles.data}>{moment(notificacao?.cadastroEm).format('L LTS')}</Text>
          </View>

          <Pressable onPress={openModal}>
            <Image style={styles.imagem} source={{ uri: notificacao?.imagem }} />
          </Pressable>
        </ScrollView>
      </View>

      <Modal
        fullScreen
        hideHeader
        isModalOpen={isModalOpen}
        children={<Image style={styles.imagem} source={{ uri: notificacao?.imagem }} />}
        setIsModalOpen={closeModal}
        style={styles.modalImagem}
      />
    </>
  );
}
