import axios from 'axios';
import { ICep } from '../ICep';
import { Endereco } from '../../../entities/Endereco';

interface ViaCepRes {
  cep: string;
  logradouro: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  erro?: boolean;
}

export class ViaCep implements ICep {
  async buscar(cep: string): Promise<Endereco> {
    const { data } = await axios.get<ViaCepRes>(`https://viacep.com.br/ws/${cep}/json/`);

    if (data.erro) throw new Error('Cep não encontrado');

    const endereco = new Endereco({
      cep: data.cep,
      endereco: data.logradouro,
      bairro: data.bairro,
      cidade: {
        descricao: data.localidade,
        ibgeId: Number(data.ibge),
        uf: data.uf,
      },
    });

    return endereco;
  }
}

const viaCep = new ViaCep().buscar;

export { viaCep };
