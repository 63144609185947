import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { FlatList, View, Text, Image } from 'react-native';
import React, { useRef, useEffect, useState } from 'react';

import { PontosUtilizados } from '../../../../entities/PontosUtilizados';

import { useAuth } from '../../../../hooks/useAuth';
import { useToast } from '../../../../hooks/useToast';
import { ITimeline } from '../../../../hooks/useTimeline';

import { Loading } from '../../../Common/Loading';
import { SemRegistro } from '../../../Common/SemRegistro';
import { DataTimeline } from '../../../Common/DataTimeline';
import { AtualizarLista } from '../../../Common/AtualizarLista';
import { CardAssociadoExtrato } from '../../CardExtrato/CardAssociadoExtrato';

import GCLogo from '../../../../assets/GC.png';
import ClarityLogo from '../../../../assets/LogoCompleto.png';

interface ITimelineAssociadoExtrato {
  extrato: ITimeline<PontosUtilizados>[];
  loadingMore: boolean;
  isPrinting: boolean;
  print: boolean;
  onPrint: () => void;
  onUpdate: () => void;
}

export function TimelineAssociadoExtrato({
  extrato,
  loadingMore,
  isPrinting,
  print,
  onPrint,
  onUpdate,
}: ITimelineAssociadoExtrato) {
  const { errorToast } = useToast();
  const { usuario } = useAuth();

  const [cardsToPrint, setCardsToPrint] = useState<number[]>([]);
  const listRef = useRef(null);

  const printExtrato = useReactToPrint({
    content: () => listRef.current,
    documentTitle: `Extrato de Claritos ${moment().format('L LTS')}`,
    suppressErrors: true,
    onBeforePrint: onPrint,
    onPrintError: (errorLocation, error) => {
      errorToast(error);
      console.log({ errorLocation, error });
    },
  });

  function addCardToPrint(pontoId: number) {
    setCardsToPrint([...cardsToPrint, pontoId]);
  }

  function removeCardToPrint(pontoId: number) {
    setCardsToPrint(cardsToPrint.filter(id => id !== pontoId));
  }

  useEffect(() => {
    if (print) printExtrato();
  }, [print]);

  useEffect(() => {
    if (isPrinting && !print) {
      let cards: number[] = [];

      extrato.map(e => e.timeline.map(p => cards.push(p.id)));

      setCardsToPrint(cards);
    }
  }, [isPrinting, extrato]);

  return (
    <>
      <FlatList
        data={extrato}
        keyExtractor={item => item.id.toString()}
        renderItem={({ item }) => (
          <View>
            <DataTimeline data={item.data} />

            {item.timeline.map(e => (
              <CardAssociadoExtrato
                key={e.pontos.disponivelEm}
                data={e}
                isPrinting={isPrinting}
                onAddToPrint={addCardToPrint}
                onRemoveToPrint={removeCardToPrint}
              />
            ))}
          </View>
        )}
        ListFooterComponent={
          loadingMore ? (
            <Loading />
          ) : extrato.length > 0 ? (
            <AtualizarLista onPress={onUpdate} />
          ) : null
        }
        ListEmptyComponent={<SemRegistro screen="Extrato de Claritos" />}
        contentContainerStyle={{
          flex: 1,
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        }}
        onEndReachedThreshold={0.5}
        initialNumToRender={20}
      />

      <View style={{ marginTop: print ? 200 : 0 }}>
        <FlatList
          ref={listRef}
          data={extrato}
          keyExtractor={item => item.id.toString()}
          ListHeaderComponent={
            <View style={{ marginTop: -16 }}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: 16,
                }}
              >
                <Image
                  source={ClarityLogo}
                  resizeMode="stretch"
                  style={{ width: 160, height: 56 }}
                />
                <Image source={GCLogo} resizeMode="stretch" style={{ width: 207, height: 35 }} />
              </View>

              <Text style={{ fontSize: 20, fontWeight: '700' }}>
                {usuario.razaoSocial} - {usuario.cnpjCPF}
              </Text>
              <Text style={{ fontSize: 18, fontWeight: '700', marginBottom: 32 }}>
                Emitido em: {moment().format('L LTS')}
              </Text>
            </View>
          }
          renderItem={({ item }) => (
            <View>
              {item.timeline.filter(e => cardsToPrint.includes(e.id)).length > 0 && (
                <DataTimeline data={item.data} />
              )}

              {item.timeline
                .filter(ponto => cardsToPrint.includes(ponto.id))
                .map(e => (
                  <CardAssociadoExtrato
                    key={e.pontos.disponivelEm}
                    data={e}
                    isPrinting={false}
                    onAddToPrint={addCardToPrint}
                    onRemoveToPrint={removeCardToPrint}
                  />
                ))}
            </View>
          )}
          ListEmptyComponent={<SemRegistro screen="Extrato de Claritos" />}
          contentContainerStyle={{
            flex: 1,
            flexDirection: 'column',
            height: '100%',
            width: '100%',
          }}
          onEndReachedThreshold={0.5}
          initialNumToRender={20}
          style={{ display: print ? 'flex' : 'none', padding: 32 }}
        />
      </View>
    </>
  );
}
