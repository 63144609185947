import { TextInput } from 'react-native-paper';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import React, { useState, useEffect } from 'react';

import { recuperarSenhaValidacao } from '../../../../useCases/Validacao/RecuperarSenha';
import { recuperarSenhaUsuario } from '../../../../useCases/Usuario/RecuperarSenhaUsuario';
import { IRecuperarSenhaUsuarioDTO } from '../../../../useCases/Usuario/RecuperarSenhaUsuario/RecuperarSenhaUsuarioDTO';

import { useToast } from '../../../../hooks/useToast';

import { Modal } from '../../../Common/Modal';
import { Button } from '../../../Common/Button';

import { styles } from './styles';
import { theme } from '../../../../global/styles/theme';
import { useKeyPressed } from '../../../../hooks/useKeyPressed';

interface IModalRecuperarSenha {
  isOpen: boolean;
  onClose: () => void;
}

export function ModalRecuperarSenha({ isOpen, onClose }: IModalRecuperarSenha) {
  const { blue50 } = theme.colors;

  const { onEnterPressed } = useKeyPressed();
  const { errorToast, successToast } = useToast();

  const [campos, setCampos] = useState<IRecuperarSenhaUsuarioDTO>({} as IRecuperarSenhaUsuarioDTO);

  async function handleSubmit() {
    try {
      recuperarSenhaValidacao.handle(campos);

      await recuperarSenhaUsuario.execute(campos);

      onClose();
      successToast('Verifique seu email');
    } catch (err) {
      errorToast(err);
    }
  }

  function handleKeyPressed(e: any) {
    if (onEnterPressed(e)) handleSubmit();
  }

  useEffect(() => {
    if (cpf.isValid(cpf.format(campos.cnpjCPF)))
      setCampos({ ...campos, cnpjCPF: cpf.format(campos.cnpjCPF) });

    if (cnpj.isValid(cnpj.format(campos.cnpjCPF)))
      setCampos({ ...campos, cnpjCPF: cnpj.format(campos.cnpjCPF) });
  }, [campos.cnpjCPF]);

  useEffect(() => setCampos({} as IRecuperarSenhaUsuarioDTO), [isOpen]);

  return (
    <Modal header="Esqueci minha senha" isModalOpen={isOpen} setIsModalOpen={onClose}>
      <TextInput
        label="CNPJ/CPF"
        keyboardType="number-pad"
        onChangeText={cnpjCPF => setCampos({ ...campos, cnpjCPF })}
        onKeyPress={handleKeyPressed}
        value={campos.cnpjCPF}
        onPressIn={() => {}}
        onPressOut={() => {}}
        autoComplete="true"
        style={styles.textInput}
        theme={{ colors: { primary: blue50 } }}
      />

      <TextInput
        label="Email"
        keyboardType="email-address"
        onChangeText={email => setCampos({ ...campos, email })}
        onKeyPress={handleKeyPressed}
        value={campos.email}
        onPressIn={() => {}}
        onPressOut={() => {}}
        autoComplete="true"
        style={styles.textInput}
        theme={{ colors: { primary: blue50 } }}
      />

      <Button
        text="Enviar"
        mode="contained"
        onPress={handleSubmit}
        style={{ alignSelf: 'flex-end', marginTop: 16 }}
      />
    </Modal>
  );
}
