import { StyleSheet, Dimensions } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    minHeight: Dimensions.get('screen').height,
    marginTop: -240,
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#000',
  },
  camera: {
    marginTop: '-50%',
  },
});
