import React from 'react';
import { View, Image, Text } from 'react-native';

import Passo1 from '../../../../../assets/instalarPwa/ios/1.png';
import Passo2 from '../../../../../assets/instalarPwa/ios/2.png';
import Passo3 from '../../../../../assets/instalarPwa/ios/3.png';

import { styles } from './styles';

export function IosInstalarPwa() {
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Text style={styles.text}>Pressione o botão de compartilhamento:</Text>
        <Image source={{ uri: Passo1 }} style={[styles.img, styles.img1]} />
      </View>

      <View style={styles.content}>
        <Text style={styles.text}>
          Em seguida, desça até encontrar a opção "Adicionar à Tela de Início" e pressione-a:
        </Text>
        <Image source={{ uri: Passo2 }} style={[styles.img, styles.img2]} />
      </View>

      <View style={styles.content}>
        <Text style={styles.text}>Por fim, pressione "Adicionar":</Text>
        <Image source={{ uri: Passo3 }} style={[styles.img, styles.img3]} />
      </View>

      <View style={styles.content}>
        <Text style={styles.text}>
          Agora o app do Clube de Pontos Clarity está instalado em seu smartphone e pode ser
          acessado da tela inicial. 👍
        </Text>
      </View>
    </View>
  );
}
