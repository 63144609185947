import { cnpj } from 'cpf-cnpj-validator';
import { ICadastrarUsuarioDTO } from '../../Usuario/CadastrarUsuario/CadastrarUsuarioDTO';
import { Validacoes } from '../Validacoes/Validacoes';

export class CadastrarUsuarioValidacaoController {
  constructor(private validacoes: Validacoes) {}

  public handle(props: ICadastrarUsuarioDTO): boolean {
    if (!props.cnpjCPF) throw new Error('Digite um CNPJ/CPF');

    if (!this.validacoes.validaCnpjCpf(props.cnpjCPF)) throw new Error('CNPJ/CPF inválido');

    if (cnpj.isValid(cnpj.format(props.cnpjCPF)) && !props.razaoSocial)
      throw new Error('Razão social inválida');

    if (!props.nome || props.nome.trim().length <= 4) throw new Error('Nome inválido');

    const nome = props.nome.trim();

    if (nome.length <= 4) throw new Error('Nome inválido');

    const nomeSplitted = nome.split(' ');

    if (nomeSplitted.length <= 1) throw new Error('Nome inválido');

    if (!props.endereco.cep) throw new Error('CEP inválido');

    if (!props.endereco.endereco) throw new Error('Endereço inválido');

    if (!props.enderecoNo || props.enderecoNo <= 0) throw new Error('Número inválido');

    if (!props.endereco.bairro) throw new Error('Bairro inválido');

    if (!props.email || !this.validacoes.validaEmail(props.email))
      throw new Error('Email inválido');

    if (!props.contatoTel1) throw new Error('WhatsApp inválido');

    if (!this.validacoes.validaTelefone(props.contatoTel1)) throw new Error('WhatsApp inválido');

    if (!props.contatoNome1 || props.contatoNome1.trim().length < 2)
      throw new Error('Contato inválido');

    if (props.contatoTel2 && !this.validacoes.validaTelefone(props.contatoTel2))
      throw new Error('Telefone 2 inválido');

    if (props.contatoTel2 && !props.contatoNome2) throw new Error('Contato tel 2 inválido');

    if (props.site && !this.validacoes.validaSite(props.site)) throw new Error('Site inválido');

    if (!props.senha) throw new Error('Senha inválida');

    if (props.senha.trim().length < 8) throw new Error(`Senha com mínimo de ${8} caracteres`);

    if (props.senha !== props.senhaConfirmacao) throw new Error('Senhas não coincidem');

    if (!props.aceite) throw new Error('Termos de uso não aceitos');

    return true;
  }
}
