import { IApi } from '../../../api/IApi';
import { IRecuperarSenhaUsuarioDTO } from './RecuperarSenhaUsuarioDTO';

export class RecuperarSenhaUsuarioController {
  constructor(private api: IApi) {}

  async execute(data: IRecuperarSenhaUsuarioDTO): Promise<void> {
    await this.api.post<void, IRecuperarSenhaUsuarioDTO>('usuarios/recuperarSenha', data);
  }
}
