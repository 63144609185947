import { IApi } from '../../../api/IApi';
import { PontoUtilizacao } from '../../../entities/PontoUtilizacao';
import { IListarPontosUtilizacaoDTO } from './ListarPontosUtilizacaoDTO';

export class ListarPontosUtilizacaoController {
  constructor(private api: IApi) {}

  async execute(token: string): Promise<PontoUtilizacao[]> {
    const { pontosUtilizacao } = await this.api.get<IListarPontosUtilizacaoDTO>(
      'usuarios/pontosUtilizacaoAssociados',
      token
    );

    return pontosUtilizacao;
  }
}
