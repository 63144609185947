import { StyleSheet } from 'react-native';
import { theme } from '../../../global/styles/theme';

export const styles = StyleSheet.create({
  semRegistro: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  semRegistroText: {
    fontFamily: theme.fonts.roboto700,
    fontSize: 32,
    color: theme.colors.blue300,
    opacity: 0.5,
  },
});
