import React from 'react';
import { View, ActivityIndicator, ViewStyle } from 'react-native';

import { theme } from '../../../global/styles/theme';

export function Loading() {
  return (
    <View style={{ flex: 1, justifyContent: 'center' }}>
      <ActivityIndicator color={theme.colors.blue100} animating={true} size="large" />
    </View>
  );
}
