import React, { useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import { Checkbox } from 'react-native-paper';

import { PontosUtilizados } from '../../../../entities/PontosUtilizados';

import { useNumberFormat } from '../../../../hooks/useNumberFormat';

import { Card } from '../../../Common/Card';

import { styles } from './styles';
import { theme } from '../../../../global/styles/theme';

interface ICardAssociadoExtrato {
  data: PontosUtilizados;
  isPrinting: boolean;
  onAddToPrint: (pontoId: number) => void;
  onRemoveToPrint: (pontoId: number) => void;
}

export function CardAssociadoExtrato({
  data,
  isPrinting,
  onAddToPrint,
  onRemoveToPrint,
}: ICardAssociadoExtrato) {
  const { currency } = useNumberFormat();

  const [isSelected, setIsSelected] = useState(true);

  function handleSelect() {
    if (isSelected) {
      setIsSelected(false);
      onRemoveToPrint(data.id);
    } else {
      setIsSelected(true);
      onAddToPrint(data.id);
    }
  }

  useEffect(() => {
    if (!isPrinting) setIsSelected(true);
  }, [isPrinting]);

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        {isPrinting && (
          <Checkbox
            status={isSelected ? 'checked' : 'unchecked'}
            color={theme.colors.blue100}
            uncheckedColor={theme.colors.blue100}
            onPress={handleSelect}
          />
        )}

        <Card style={{ paddingVertical: 8, paddingHorizontal: 12 }}>
          <View style={styles.header}>
            <View style={[styles.pedidoContainer, { flexDirection: 'column', maxWidth: '80%' }]}>
              <View style={{ flexDirection: 'row' }}>
                <Text style={[styles.pedido, { fontWeight: 'bold' }]}>
                  {data.pontosUtilizacao.descricao}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text style={[styles.pedido, { fontWeight: 'bold' }]}>Cliente: </Text>
                <Text style={styles.pedido}>{data.pontos.usuarioConsumidor.nome}</Text>
              </View>
            </View>

            <View style={styles.valorContainer}>
              <Text style={styles.valor}>{currency(data.valorTotal)}</Text>
              <Text style={styles.valor}>{`${data.pontos.pontosTotal} Claritos`}</Text>
            </View>
          </View>
        </Card>
      </View>
    </View>
  );
}
