import { View, Text } from 'react-native';
import { Button, TextInput } from 'react-native-paper';
import React, { useState, useEffect, SetStateAction } from 'react';

import { INovoPontoUtilizacao } from '../../../screens/PontosDeUtilizacao';
import { cadastrarPontoUtilizacaoValidacao } from '../../../useCases/Validacao/CadastrarPontoUtilizacaoValidacao';

import { useAuth } from '../../../hooks/useAuth';
import { useToast } from '../../../hooks/useToast';

import { Modal } from '../../Common/Modal';

import { theme } from '../../../global/styles/theme';

interface IModalNovoPontoUtilizacao {
  isOpen: boolean;
  onClose: React.Dispatch<SetStateAction<boolean>>;
  onAdd: (data: INovoPontoUtilizacao) => void;
}

export function ModalNovoPontoUtilizacao({ isOpen, onClose, onAdd }: IModalNovoPontoUtilizacao) {
  const { errorToast } = useToast();
  const { isTokenValid } = useAuth();

  const [email, setEmail] = useState('');
  const [descricao, setDescricao] = useState('');

  function handleAdicionarPonto() {
    try {
      cadastrarPontoUtilizacaoValidacao.handle({ descricao, email });

      onAdd({ descricao, email });
    } catch (err) {
      const authExpirou = isTokenValid(err);
      errorToast(authExpirou || err);
    }
  }

  useEffect(() => {
    setDescricao('');
    setEmail('');
  }, [isOpen]);

  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
      }}
    >
      <Modal
        isModalOpen={isOpen}
        setIsModalOpen={() => onClose(false)}
        header="Novo ponto de utilização"
      >
        <View>
          <View>
            <TextInput
              label="Descrição"
              onChangeText={setDescricao}
              value={descricao}
              onPressIn={() => {}}
              onPressOut={() => {}}
              autoComplete="true"
              theme={{
                colors: {
                  text: theme.colors.text,
                  accent: theme.colors.text,
                  primary: theme.colors.blue100,
                  placeholder: theme.colors.blue300,
                  background: 'transparent',
                },
              }}
              underlineColor={theme.colors.blue300}
              underlineColorAndroid={theme.colors.blue300}
            />

            <TextInput
              label="Email"
              onChangeText={setEmail}
              value={email}
              onPressIn={() => {}}
              onPressOut={() => {}}
              autoComplete="true"
              theme={{
                colors: {
                  text: theme.colors.text,
                  accent: theme.colors.text,
                  primary: theme.colors.blue100,
                  placeholder: theme.colors.blue300,
                  background: 'transparent',
                },
              }}
              underlineColor={theme.colors.blue300}
              underlineColorAndroid={theme.colors.blue300}
            />
          </View>

          <View
            style={{
              marginTop: 16,
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Button mode="contained" color={theme.colors.blue100} onPress={handleAdicionarPonto}>
              <Text style={{ color: theme.colors.white }}>Confirmar</Text>
            </Button>
          </View>
        </View>
      </Modal>
    </View>
  );
}
