import React, { useState, useEffect } from 'react';
import { View, Text, Pressable } from 'react-native';

import { Pedido } from '../../../entities/Pedido';

import { useNumberFormat } from '../../../hooks/useNumberFormat';

import { Card } from '../../Common/Card';
import { Button } from '../../Common/Button';

import { styles } from './styles';
import { theme } from '../../../global/styles/theme';

interface ICardPedidos {
  pedido: Pedido;
  onResgate: (id: number) => void;
  onClick: (id: number) => void;
}

export function CardPedidos({ pedido, onResgate, onClick }: ICardPedidos) {
  const { milhar } = useNumberFormat();

  const [cor, setCor] = useState('');

  function handleClick() {
    onClick(pedido.id);
  }

  useEffect(() => {
    setCor(pedido.status === 3 ? theme.colors.credito : theme.colors.red);
  }, [pedido]);

  return (
    <Pressable style={styles.container} onPress={handleClick}>
      <View style={styles.content}>
        <Card style={{ paddingVertical: 8, paddingHorizontal: 12 }}>
          <View style={styles.header}>
            <Text style={[styles.headerTxt, styles.lblTitulo, { fontSize: 14 }]}>
              {pedido.pedidoCliente.length >= 15
                ? `${pedido.pedidoCliente.slice(0, 16)}...`
                : pedido.pedidoCliente}{' '}
              {pedido.pedidoCliente.length < 16 && `(${pedido.referencia})`}
              {/* {pedido.pedidoCliente.length >= 20
                ? `${pedido.pedidoCliente.slice(0, 21)}...`
                : pedido.pedidoCliente}{' '}
              {pedido.pedidoCliente.length < 21 && `(${pedido.referencia})`} */}
            </Text>

            <Text
              style={[
                styles.headerTxt,
                styles.valor,
                { color: cor, alignSelf: 'flex-end', fontSize: 14 },
              ]}
            >
              {pedido.statusDesc}
            </Text>
          </View>

          <Text style={styles.associado}>{pedido.usuarioAssociado?.nome}</Text>

          <View style={styles.body}>
            <View>
              <Text style={styles.valor}>
                <Text style={styles.lblTitulo}>Claritos gerados: </Text>
                {milhar(pedido.pontosTotal)}
              </Text>

              <Text style={styles.valor}>
                <Text style={styles.lblTitulo}>Quantidade de peças: </Text>
                {milhar(pedido.quantidadeTotal)}
              </Text>

              <Text style={styles.valor}>
                <Text style={styles.lblTitulo}>Metragem total: </Text>
                {milhar(pedido.areaTotal)}m²
              </Text>

              <Text style={styles.valor}>
                <Text style={styles.lblTitulo}>Peso: </Text>
                {milhar(pedido.pesoTotal)}Kg
              </Text>
            </View>

            {pedido.status === 2 && (
              <Button
                text="Resgatar Claritos"
                style={styles.btnResgatar}
                onPress={() => onResgate(pedido.id)}
              />
            )}
          </View>
        </Card>
      </View>
    </Pressable>
  );
}
