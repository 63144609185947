import React from 'react';
import { Image, View, Text } from 'react-native';

import Passo1 from '../../../../../assets/instalarPwa/android/1.png';
import Passo2 from '../../../../../assets/instalarPwa/android/2.png';

import { styles } from './styles';

export function AndroidInstalarPwa() {
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Text style={styles.text}>Pressione o botão de opções no canto superior direito:</Text>
        <Image source={{ uri: Passo1 }} style={[styles.img, styles.img1]} />
      </View>

      <View style={styles.content}>
        <Text style={styles.text}>
          Em seguida, encontre a opção "Adicionar à tela inicial" e pressione-a:
        </Text>
        <Image source={{ uri: Passo2 }} style={[styles.img, styles.img2]} />
      </View>

      <View style={styles.content}>
        <Text style={styles.text}>
          Agora o app do Clube de Pontos Clarity está instalado em seu smartphone e pode ser
          acessado da tela inicial. 👍
        </Text>
      </View>
    </View>
  );
}
