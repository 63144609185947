import { IApi } from '../../../../api/IApi';
import { IImagemUsuarioDTO } from '../ImagemUsuarioDTO';

export class AlterarImagemUsuarioController {
  constructor(private api: IApi) {}

  async execute(data: IImagemUsuarioDTO, token: string): Promise<void> {
    await this.api.post<void, IImagemUsuarioDTO>('usuarios/imagem', data, token);
  }
}
