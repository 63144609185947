import React, { useState } from 'react';
import { View, Text } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';

import { IListarExtratoUsuarioDTO } from '../../../../useCases/Usuario/ListarExtratoUsuario/ListarExtratoUsuarioDTO';

import { Card } from '../../../Common/Card';

import { styles } from './styles';
import { theme } from '../../../../global/styles/theme';

interface ICardConsumidorExtrato {
  data: IListarExtratoUsuarioDTO;
}

export function CardConsumidorExtrato({ data }: ICardConsumidorExtrato) {
  const [expirado] = useState(data.tipo === 4);
  const [isCredito] = useState(data.tipo === 2);
  const [cor] = useState(isCredito ? theme.colors.credito : theme.colors.text);
  const [caretCor] = useState(isCredito ? cor : theme.colors.red);

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <View style={styles.caret}>
          <FontAwesome5 name={isCredito ? 'caret-up' : 'caret-down'} size={28} color={caretCor} />
        </View>

        <Card style={{ paddingVertical: 8, paddingHorizontal: 12 }}>
          <View style={styles.header}>
            <View style={styles.pedidoContainer}>
              {data.pontosPedido && (
                <Text style={[styles.pedido, { fontWeight: 'bold', color: cor }]}>
                  {`${data.pontosPedido.pedidoCliente} (${data.pontosPedido.referencia})`}
                </Text>
              )}

              {expirado && (
                <Text style={[styles.pedido, { fontWeight: 'bold', color: cor }]}>
                  CLARITOS EXPIRADOS
                </Text>
              )}

              {data.pontosUtilizados && (
                <Text style={[styles.pedido, { fontWeight: 'bold', color: cor }]}>
                  {`${data.pontosUtilizados.pontosUtilizacao.usuarioAssociado.nome} (${data.pontosUtilizados.pontosUtilizacao.descricao})`}
                </Text>
              )}
            </View>

            <View style={styles.valorContainer}>
              <Text style={[styles.valor, { color: cor }]}>
                {isCredito ? '+ ' : '- '}
                {data.pontosTotal}
              </Text>
            </View>
          </View>
        </Card>
      </View>
    </View>
  );
}
