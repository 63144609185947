import { StyleSheet } from 'react-native';
import { theme } from '../../../global/styles/theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    paddingHorizontal: 16,
    marginTop: -16,
  },
  avatarContainer: {
    position: 'relative',
    width: 156,
    height: 156,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 156,
  },
  btnCamera: {
    position: 'absolute',
    right: 0,
    bottom: 16,
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 40,
    backgroundColor: theme.colors.white,
    elevation: 3,
  },
  h1: {
    fontSize: 20,
    fontFamily: theme.fonts.roboto700,
  },
  btnSair: {
    width: '100%',
    height: 32,
    marginTop: 16,
    borderRadius: 5,
    backgroundColor: theme.colors.blue200,
    justifyContent: 'center',
  },
  btnSairText: {
    color: theme.colors.white,
    fontFamily: theme.fonts.roboto400,
    fontSize: 20,
    alignSelf: 'center',
  },
  flexbox: {
    maxWidth: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textInput: {
    backgroundColor: 'transparent',
  },
  textInput75: {
    backgroundColor: 'transparent',
    width: '74%',
  },
  textInput50: {
    backgroundColor: 'transparent',
    maxWidth: '49%',
  },
  textInput25: {
    backgroundColor: 'transparent',
    maxWidth: '24%',
  },
  footer: {
    marginVertical: 16,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});
