import React, { useState, useEffect } from 'react';
import { LinearGradient } from 'expo-linear-gradient';
import { View, Image, ScrollView } from 'react-native';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';

import { pushNotification } from '../../services/PushNotification';
import { logarUsuario } from '../../useCases/Usuario/LogarUsuario';
import { cadastrarUsuario } from '../../useCases/Usuario/CadastrarUsuario';
import { buscarImagemUsuario } from '../../useCases/Usuario/ImagemUsuario';
import { ILogarUsuarioDTO } from '../../useCases/Usuario/LogarUsuario/LogarUsuarioDTO';
import { ICadastrarUsuarioDTO } from '../../useCases/Usuario/CadastrarUsuario/CadastrarUsuarioDTO';

import { useAuth } from '../../hooks/useAuth';
import { useModal } from '../../hooks/useModal';
import { useToast } from '../../hooks/useToast';
import { useScreen } from '../../hooks/useScreen';

import { Modal } from '../../components/Common/Modal';
import { Button } from '../../components/Common/Button';
import { Loading } from '../../components/Common/Loading';
import { FormLogin } from '../../components/Home/Login/FormLogin';
import { CardWarning } from '../../components/Home/Login/CardWarning';
import { FormCadastro } from '../../components/Home/Cadastro/FormCadastro';
import { IosInstalarPwa } from '../../components/Home/Login/InstalarPwa/IosInstalarPwa';
import { AndroidInstalarPwa } from '../../components/Home/Login/InstalarPwa/AndroidInstalarPwa';

import Logo from '../../assets/LogoHorizontal.png';

import { styles } from './styles';
import { theme } from '../../global/styles/theme';

export type TipoTela = 'login' | 'cadastro';

export function Home() {
  const { setScreen } = useScreen();
  const { setUser, setToken } = useAuth();
  const { errorToast, successToast } = useToast();
  const { isModalOpen, openModal, closeModal } = useModal();

  const {
    isModalOpen: isLoadModalOpen,
    openModal: openLoadModal,
    closeModal: closeLoadModal,
  } = useModal();

  const [isPWA, setIsPWA] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [loginScreen, setLoginScreen] = useState(true);
  const [warning, setWarning] = useState<boolean>(false);

  const { white, blue100, blue400 } = theme.colors;

  async function handleLogin(data: ILogarUsuarioDTO) {
    try {
      const { usuario, token } = await logarUsuario.execute(data);
      await setToken(token);

      const imagem = await buscarImagemUsuario.execute(token);
      await setUser({ ...usuario, imagem });

      pushNotification.register(token);

      setScreen('Sobre');
    } catch (err) {
      if (err?.response?.data === 'Usuário não está liberado para utilizar o sistema')
        setWarning(true);

      errorToast(err);
    }
  }

  async function handleCadastro(data: ICadastrarUsuarioDTO) {
    try {
      await cadastrarUsuario.execute(data);

      successToast('Cadastro efetuado com sucesso');

      setLoginScreen(true);
    } catch (err) {
      errorToast(err);
    }
  }

  function handleScreenChange(tipo: TipoTela) {
    setWarning(false);

    if (tipo === 'login') return setLoginScreen(true);

    if (tipo === 'cadastro') return setLoginScreen(false);
  }

  useEffect(() => setIsPWA(window.matchMedia('(display-mode: standalone)').matches), []);

  useEffect(() => {
    openLoadModal();

    setTimeout(() => closeLoadModal(), 500);
  }, []);

  return (
    <ScrollView style={styles.container}>
      <View style={styles.innerContainer}>
        <LinearGradient colors={[blue400, blue100]} style={{ flex: 1, height: '100vh' }}>
          <View style={{ marginTop: 40 }}>
            <Image source={Logo} style={styles.logo} onLoadEnd={() => setIsLoading(false)} />
          </View>

          {isLoading ? (
            <View style={{ marginTop: 48 }}>
              <Loading />
            </View>
          ) : (
            <View style={styles.content}>
              {loginScreen ? (
                <>
                  <FormLogin handleLogin={handleLogin} handleScreenChange={handleScreenChange} />

                  <View style={{ marginTop: 16, alignSelf: 'center' }}>
                    {warning && (
                      <CardWarning
                        header="Nenhum pedido realizado!"
                        content={`Você ainda não possui pedidos cadastrados.\nFaça sua compra no Clube Clarity mais próximo para liberar seu acesso!`}
                      />
                    )}

                    {!isPWA && isMobile && (
                      <View style={{ maxWidth: 300 }}>
                        <CardWarning
                          header="Instale o App!"
                          content={`Para uma melhor experiência com o Clube de Pontos Clarity, adicione-o à sua tela inicial!`}
                          component={
                            <Button
                              text="Veja como instalar"
                              mode="outlined"
                              color={white}
                              onPress={openModal}
                              style={{ marginTop: 16, borderColor: white }}
                            />
                          }
                        />
                      </View>
                    )}
                  </View>
                </>
              ) : (
                <FormCadastro
                  handleCadastro={handleCadastro}
                  handleScreenChange={handleScreenChange}
                />
              )}
            </View>
          )}

          <Modal header="Como instalar o app" isModalOpen={isModalOpen} setIsModalOpen={closeModal}>
            <ScrollView style={{ maxHeight: 500 }}>
              {isAndroid && <AndroidInstalarPwa />}
              {isIOS && <IosInstalarPwa />}
            </ScrollView>
          </Modal>
        </LinearGradient>
      </View>

      <Modal isModalOpen={isLoadModalOpen} setIsModalOpen={closeLoadModal} displayNone />
    </ScrollView>
  );
}
