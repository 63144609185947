import { cnpj, cpf } from 'cpf-cnpj-validator';
import React, { useEffect, useState } from 'react';
import { ScrollView, View, Text } from 'react-native';
import { Checkbox, TextInput } from 'react-native-paper';

import { cadastrarUsuarioValidacao } from '../../../../useCases/Validacao/CadastrarUsuarioValidacao';
import { ICadastrarUsuarioDTO } from '../../../../useCases/Usuario/CadastrarUsuario/CadastrarUsuarioDTO';

import { TipoTela } from '../../../../screens/Home';

import { useCep } from '../../../../hooks/useCep';
import { useToast } from '../../../../hooks/useToast';
import { useModal } from '../../../../hooks/useModal';

import { ModalTermos } from '../ModalTermos';
import { Button } from '../../../Common/Button';
import { TextInputSenha } from '../../../Common/TextInputSenha';

import { styles } from './styles';
import { theme } from '../../../../global/styles/theme';

interface IFormCadastro {
  handleCadastro: (props: ICadastrarUsuarioDTO) => void;
  handleScreenChange: (tipo: TipoTela) => void;
}

const { white, blue50, blue300, textInput } = theme.colors;

const DEFAULT_THEME = { colors: { primary: blue50, placeholder: textInput, text: white } };

export function FormCadastro({ handleCadastro, handleScreenChange }: IFormCadastro) {
  const { errorToast } = useToast();
  const { endereco, observerCep } = useCep();
  const { isModalOpen, openModal, closeModal } = useModal();

  const [aceite, setAceite] = useState(false);
  const [senhaConfirmacao, setSenhaConfirmacao] = useState('');
  const [razaoSocialDisabled, setRazaoSocialDisabled] = useState(false);
  const [campos, setCampos] = useState<ICadastrarUsuarioDTO>({} as ICadastrarUsuarioDTO);

  observerCep(campos?.endereco?.cep ?? '');

  async function handleSubmit() {
    try {
      cadastrarUsuarioValidacao.handle({ ...campos, senhaConfirmacao, aceite });

      const cnpjCPF = razaoSocialDisabled
        ? cpf.format(campos.cnpjCPF)
        : cnpj.format(campos.cnpjCPF);

      handleCadastro({ ...campos, cnpjCPF, ativo: true });
    } catch (err) {
      errorToast(err);
    }
  }

  useEffect(() => setCampos({ ...campos, endereco }), [endereco]);

  useEffect(() => {
    if (cpf.isValid(cpf.format(campos.cnpjCPF))) {
      const cnpjCPF = cpf.format(campos.cnpjCPF);

      setRazaoSocialDisabled(true);
      setCampos({ ...campos, cnpjCPF, razaoSocial: null });
    } else if (cnpj.isValid(cnpj.format(campos.cnpjCPF))) {
      const cnpjCPF = cnpj.format(campos.cnpjCPF);

      setRazaoSocialDisabled(false);
      setCampos({ ...campos, cnpjCPF });
    } else setRazaoSocialDisabled(false);
  }, [campos.cnpjCPF]);

  return (
    <View style={styles.container}>
      <ScrollView style={styles.content}>
        <View style={{ maxWidth: 300 }}>
          <View>
            <TextInput
              label="CNPJ/CPF*"
              autoComplete="true"
              keyboardType="number-pad"
              onChangeText={cnpjCPF => setCampos({ ...campos, cnpjCPF })}
              value={campos.cnpjCPF}
              onPressIn={() => {}}
              onPressOut={() => {}}
              style={styles.textInput}
              theme={DEFAULT_THEME}
            />

            <TextInput
              label="Razão Social"
              autoComplete="true"
              keyboardType="default"
              onChangeText={razaoSocial => setCampos({ ...campos, razaoSocial })}
              disabled={razaoSocialDisabled}
              value={razaoSocialDisabled ? '' : campos.razaoSocial}
              onPressIn={() => {}}
              onPressOut={() => {}}
              style={styles.textInput}
              theme={DEFAULT_THEME}
            />

            <TextInput
              label="Nome*"
              autoComplete="true"
              keyboardType="default"
              onChangeText={nome => setCampos({ ...campos, nome })}
              value={campos.nome}
              onPressIn={() => {}}
              onPressOut={() => {}}
              style={styles.textInput}
              theme={DEFAULT_THEME}
            />

            <TextInput
              label="CEP*"
              autoComplete="true"
              keyboardType="number-pad"
              onChangeText={cep => setCampos({ ...campos, endereco: { ...campos.endereco, cep } })}
              value={campos.endereco?.cep}
              maxLength={9}
              onPressIn={() => {}}
              onPressOut={() => {}}
              style={styles.textInput}
              theme={DEFAULT_THEME}
            />

            <View style={{ flexDirection: 'row', maxWidth: 320 }}>
              <TextInput
                label="Endereço*"
                autoComplete="true"
                keyboardType="default"
                onChangeText={endereco =>
                  setCampos({ ...campos, endereco: { ...campos.endereco, endereco } })
                }
                value={campos.endereco?.endereco}
                onPressIn={() => {}}
                onPressOut={() => {}}
                editable={false}
                style={[styles.textInput, { width: 200 }]}
                theme={DEFAULT_THEME}
              />

              <TextInput
                label="Nº*"
                autoComplete="true"
                keyboardType="number-pad"
                onChangeText={e =>
                  !isNaN(Number(e)) && setCampos({ ...campos, enderecoNo: Number(e) })
                }
                value={campos.enderecoNo as any}
                onPressIn={() => {}}
                onPressOut={() => {}}
                style={[styles.textInput, { maxWidth: 84, marginLeft: 16 }]}
                theme={DEFAULT_THEME}
              />
            </View>

            <TextInput
              label="Complemento"
              autoComplete="true"
              keyboardType="default"
              onChangeText={enderecoCompl => setCampos({ ...campos, enderecoCompl })}
              value={campos.enderecoCompl}
              onPressIn={() => {}}
              onPressOut={() => {}}
              style={styles.textInput}
              theme={DEFAULT_THEME}
            />

            <TextInput
              label="Bairro*"
              autoComplete="true"
              keyboardType="default"
              onChangeText={bairro =>
                setCampos({ ...campos, endereco: { ...campos.endereco, bairro } })
              }
              value={campos.endereco?.bairro}
              onPressIn={() => {}}
              onPressOut={() => {}}
              editable={false}
              style={styles.textInput}
              theme={DEFAULT_THEME}
            />

            <TextInput
              label="Email*"
              autoComplete="true"
              keyboardType="email-address"
              onChangeText={email => setCampos({ ...campos, email })}
              value={campos.email}
              onPressIn={() => {}}
              onPressOut={() => {}}
              style={styles.textInput}
              theme={DEFAULT_THEME}
            />

            <View
              style={{
                flexDirection: 'row',
                maxWidth: 320,
              }}
            >
              <TextInput
                label="WhatsApp*"
                autoComplete="true"
                keyboardType="number-pad"
                onChangeText={contatoTel1 => setCampos({ ...campos, contatoTel1 })}
                value={campos.contatoTel1}
                onPressIn={() => {}}
                onPressOut={() => {}}
                style={[styles.textInput, { width: 140 }]}
                theme={DEFAULT_THEME}
              />

              <TextInput
                label="Contato*"
                autoComplete="true"
                keyboardType="default"
                onChangeText={contatoNome1 => setCampos({ ...campos, contatoNome1 })}
                value={campos.contatoNome1}
                onPressIn={() => {}}
                onPressOut={() => {}}
                style={[styles.textInput, { maxWidth: 144, marginLeft: 16 }]}
                theme={DEFAULT_THEME}
              />
            </View>

            <View style={{ flexDirection: 'row', maxWidth: 320 }}>
              <TextInput
                label="Telefone 2"
                autoComplete="true"
                keyboardType="number-pad"
                onChangeText={contatoTel2 => setCampos({ ...campos, contatoTel2 })}
                value={campos.contatoTel2}
                onPressIn={() => {}}
                onPressOut={() => {}}
                style={[styles.textInput, { width: 140 }]}
                theme={DEFAULT_THEME}
              />

              <TextInput
                label="Contato"
                autoComplete="true"
                keyboardType="default"
                onChangeText={contatoNome2 => setCampos({ ...campos, contatoNome2 })}
                value={campos.contatoNome2}
                onPressIn={() => {}}
                onPressOut={() => {}}
                style={[styles.textInput, { maxWidth: 144, marginLeft: 16 }]}
                theme={DEFAULT_THEME}
              />
            </View>

            <TextInput
              label="Site"
              autoComplete="true"
              keyboardType="url"
              onChangeText={site => setCampos({ ...campos, site })}
              value={campos.site}
              onPressIn={() => {}}
              onPressOut={() => {}}
              style={styles.textInput}
              theme={DEFAULT_THEME}
            />

            <TextInputSenha
              label="Senha*"
              onChangeText={senha => setCampos({ ...campos, senha })}
              value={campos.senha}
              style={styles.textInput}
              theme={DEFAULT_THEME}
            />

            <TextInputSenha
              label="Senha (confirmação)*"
              onChangeText={setSenhaConfirmacao}
              value={senhaConfirmacao}
              style={styles.textInput}
              theme={DEFAULT_THEME}
            />

            <View style={styles.aceiteContainer}>
              <Checkbox
                status={aceite ? 'checked' : 'unchecked'}
                onPress={() => setAceite(!aceite)}
                color={white}
              />

              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Text style={styles.termosText}>Li e aceito os</Text>
                <Button
                  text="termos de uso"
                  mode="text"
                  onPress={openModal}
                  color={theme.colors.text}
                />
              </View>
            </View>
          </View>
        </View>

        <ModalTermos isOpen={isModalOpen} onClose={closeModal} />

        <View style={styles.btnContainer}>
          <Button
            text="Voltar"
            mode="text"
            color={white}
            onPress={() => handleScreenChange('login')}
          />

          <Button text="Cadastrar" color={white} background={blue300} onPress={handleSubmit} />
        </View>
      </ScrollView>
    </View>
  );
}
