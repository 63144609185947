import React from 'react';
import { useNavigation } from '@react-navigation/native';
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';

import { useScreen } from '../../../../hooks/useScreen';
import { Screens } from '../../../../contexts/ScreenContext';

import { Button } from '../../../Common/Button';

import { styles } from './styles';
import { theme } from '../../../../global/styles/theme';

interface IBottomMenuButton {
  screen: Screens;
  icon: string;
  subtitle?:string;
}

export function BottomMenuButton({ screen, icon, subtitle }: IBottomMenuButton) {
  const { navigate } = useNavigation();
  const { white, blue200 } = theme.colors;
  const { setScreen, screen: activeScreen, setReloadData } = useScreen();

  function handleClick() {
    setScreen(screen);
    navigate(screen as any);

    if (screen === activeScreen) setReloadData(true);
  }

  return (
    <Button
      icon={
        <FontAwesome5 name={icon} color={screen === activeScreen ? white : blue200} size={30} />
      }
      mode="text"
      onPress={handleClick}
      style={screen === activeScreen ? styles.active : styles.inactive}
      subtitle={<FontAwesome5 color={screen === activeScreen ? white : blue200} size={9}>{subtitle}</FontAwesome5>}
    />
  );
}
