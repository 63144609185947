import { Dimensions, StyleSheet } from 'react-native';
import { theme } from '../../../../global/styles/theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    // maxHeight: Dimensions.get('screen').height - 300,
  },
  btnContainer: {
    width: '100%',
    marginTop: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textInput: {
    backgroundColor: 'transparent',
  },
  btnCadastrar: {
    color: theme.colors.white,
    fontFamily: theme.fonts.roboto400,
  },
  btnLogin: {
    paddingVertical: 8,
    paddingHorizontal: 20,
    color: theme.colors.white,
    backgroundColor: 'transparent',
    borderRadius: 4,
    borderWidth: 1.5,
    borderColor: theme.colors.blue50,
    fontFamily: theme.fonts.roboto400,
  },
  label: {
    color: theme.colors.white,
    fontFamily: theme.fonts.roboto400,
    fontSize: 12,
  },
  btnText: {
    color: theme.colors.white,
    fontFamily: theme.fonts.roboto400,
  },
  divider: {
    borderBottomWidth: 2,
    borderBottomColor: theme.colors.blue50,
    marginTop: 24,
    opacity: 0.3,
  },
  aceiteContainer: {
    marginTop: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  termosContainer: {
    height: 400,
    textAlign: 'justify',
  },
  termosText: {
    fontFamily: theme.fonts.roboto400,
    color: theme.colors.white,
  },
});
