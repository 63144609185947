import { StyleSheet } from 'react-native';
import { theme } from '../../../global/styles/theme';

export const styles = StyleSheet.create({
  avatar: {
    borderColor: theme.colors.blue100,
    borderWidth: 2,
  },
  textAvatar: {
    backgroundColor: theme.colors.blue200,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: theme.colors.white,
    fontFamily: theme.fonts.roboto700,
  },
  avatarSm: {
    width: 32,
    height: 32,
    borderRadius: 32,
  },
  avatarMd: {
    width: 48,
    height: 48,
    borderRadius: 48,
  },
  avatarLg: {
    width: 60,
    height: 60,
    borderRadius: 60,
  },
  avatarXl: {
    width: 148,
    height: 148,
    borderRadius: 148,
  },
  textSm: {
    fontSize: 14,
  },
  textMd: {
    fontSize: 20,
  },
  textLg: {
    fontSize: 24,
  },
  textXl: {
    fontSize: 64,
  },
});
