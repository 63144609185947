import { View } from 'react-native';
import React, { useState, useEffect } from 'react';

import { alterarSenhaUsuario } from '../../../useCases/Usuario/AlterarSenhaUsuario';
import { alterarSenhaUsuarioValidacao } from '../../../useCases/Validacao/AlterarSenhaUsuarioValidacao';
import { IAlterarSenhaUsuarioDTO } from '../../../useCases/Usuario/AlterarSenhaUsuario/AlterarSenhaUsuarioDTO';

import { useAuth } from '../../../hooks/useAuth';
import { useToast } from '../../../hooks/useToast';

import { Modal } from '../../Common/Modal';
import { Button } from '../../Common/Button';
import { TextInputSenha } from '../../Common/TextInputSenha';

import { styles } from './styles';
import { theme } from '../../../global/styles/theme';

interface IModalAlterarSenha {
  isOpen: boolean;
  onClose: () => void;
}

interface ISenha {
  senhaAtual?: string;
  senhaNova?: string;
  senhaNovaConfirmacao?: string;
}

const DEFAULT_THEME = {
  colors: {
    primary: theme.colors.blue50,
    text: theme.colors.text,
    placeholder: theme.colors.blue300,
  },
};

export function ModalAlterarSenha({ isOpen, onClose }: IModalAlterarSenha) {
  const { token, isTokenValid } = useAuth();
  const { errorToast, successToast } = useToast();

  const [senha, setSenha] = useState<ISenha>({} as ISenha);

  async function handleAlterarSenha() {
    try {
      const senhaAntiga = senha.senhaAtual?.trim() ?? '';
      const senhaNova = senha.senhaNova?.trim() ?? '';
      const senhaNovaConfirmacao = senha.senhaNovaConfirmacao?.trim() ?? '';

      alterarSenhaUsuarioValidacao.handle({ senhaNova, senhaNovaConfirmacao });

      const userData: IAlterarSenhaUsuarioDTO = {
        senhaAntiga,
        senhaNova,
      };

      await alterarSenhaUsuario.execute(userData, token);

      onClose();
      successToast('Senha alterada');
    } catch (err) {
      const authExpirou = isTokenValid(err);
      errorToast(authExpirou || err);
    }
  }

  useEffect(() => {
    if (!isOpen) setSenha({} as ISenha);
  }, [isOpen]);

  return (
    <Modal header="Alterar senha" isModalOpen={isOpen} setIsModalOpen={onClose}>
      <TextInputSenha
        label="Senha atual"
        onChangeText={senhaAtual => setSenha({ ...senha, senhaAtual })}
        value={senha?.senhaAtual}
        style={styles.textInput}
        theme={DEFAULT_THEME}
        eyeColor={theme.colors.blue300}
        underlineColor={theme.colors.blue300}
      />

      <TextInputSenha
        label="Senha nova"
        onChangeText={senhaNova => setSenha({ ...senha, senhaNova })}
        value={senha?.senhaNova}
        style={styles.textInput}
        theme={DEFAULT_THEME}
        eyeColor={theme.colors.blue300}
        underlineColor={theme.colors.blue300}
      />

      <TextInputSenha
        label="Senha atual (confirmação)"
        onChangeText={senhaNovaConfirmacao => setSenha({ ...senha, senhaNovaConfirmacao })}
        value={senha.senhaNovaConfirmacao}
        style={styles.textInput}
        theme={DEFAULT_THEME}
        eyeColor={theme.colors.blue300}
        underlineColor={theme.colors.blue300}
      />

      <View style={styles.footer}>
        <Button text="Cancelar" mode="outlined" onPress={onClose} />

        <Button
          text="Confirmar"
          mode="contained"
          onPress={handleAlterarSenha}
          style={{ marginLeft: 8 }}
        />
      </View>
    </Modal>
  );
}
