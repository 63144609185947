import { StyleSheet } from 'react-native';
import { theme } from '../../global/styles/theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    paddingHorizontal: 16,
    marginTop: 0,
  },
  header: {
    marginLeft: 8,
    fontSize: 26,
    fontFamily: theme.fonts.roboto700,
  },
  headerContainer: {
    marginBottom: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardContainer: {
    marginVertical: 8,
  },
  card: {
    marginBottom: 12,
  },
  cardContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  ponto: {
    fontFamily: theme.fonts.roboto700,
    fontSize: 16,
  },
  btnContainer: {
    flexDirection: 'row',
  },
  btnSave: {
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: theme.colors.blue200,
    borderRadius: 16,
  },
});
