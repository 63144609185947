import { StyleSheet } from 'react-native';
import { theme } from '../../global/styles/theme';
import { isBrowser } from 'react-device-detect';

export const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  content: {
    marginLeft:'auto',
    marginRight:'auto',
    width:350,
  },
  descricao:{
    fontFamily: theme.fonts.roboto400,
    fontSize: 14,
    textAlign:'center'  
  },
  empresa:{
    width:350,
    height:190,
        
  },
  LogoDegrade:{
    width:350,
    height:80,
    marginTop:isBrowser?'2%':'20%',
    marginLeft:'auto',
    marginRight:'auto'
  },
  Frase:{
    width:350,
    height:95
    
  },
  Frase2:{
    fontFamily: theme.fonts.roboto700,
    color:theme.colors.blue500,
    fontSize: 16,
    textAlign:'center',
    margin:0
  },
  Lista:{
    fontFamily: theme.fonts.roboto400,
    fontSize: 14,    
    paddingRight:10,
    marginTop:'50px'
  },
  NossoProdutos:{
    fontFamily: theme.fonts.roboto700,
    color:theme.colors.blue500,
    fontSize: 14,
    
    
    
  },
  Icone:{
    width:13,
    height:13,  
  }
});

