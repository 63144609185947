import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { ScreenList } from '../screens';

export function AppRoutes() {
  const { Navigator, Screen } = createStackNavigator();

  return (
    <Navigator screenOptions={{ headerShown: false }}>
      {ScreenList.filter(screen => !screen.auth).map(e => (
        <Screen key={e.screen} name={e.screen} component={e.component} />
      ))}
    </Navigator>
  );
}
