import { View,Text } from 'react-native';
import React, { useEffect, useState } from 'react';
import { FontAwesome5 } from '@expo/vector-icons';
import { RadioButton } from 'react-native-paper';

import { Extrato as ExtratoItem } from '../../entities/Extrato';
import { PontosUtilizados } from '../../entities/PontosUtilizados';
import { listarExtratoUsuario } from '../../useCases/Usuario/ListarExtratoUsuario';
import { listarExtratoUsuarioAssociado } from '../../useCases/Usuario/UsuarioAssociado/ListarExtratoUsuarioAssociado';

import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';
import { useScreen } from '../../hooks/useScreen';
import { usePontos } from '../../hooks/usePontos';
import { useTimeline, ITimeline } from '../../hooks/useTimeline';

import { Header } from '../../components/Common/Header';
import { Loading } from '../../components/Common/Loading';
import { SemMaisRegistro } from '../../components/Common/SemMaisRegistro';
import { TimelineAssociadoExtrato } from '../../components/Extrato/TimelineExtrato/TimelineAssociadoExtrato';
import { TimelineConsumidorExtrato } from '../../components/Extrato/TimelineExtrato/TimelineConsumidorExtrato';

import { styles } from './styles';
import { Button } from '../../components/Common/Button';
import { theme } from '../../global/styles/theme';
import { IFiltroExtratoUsuarioDTO } from '../../useCases/Usuario/ListarExtratoUsuario/ListarExtratoUsuarioDTO';

export function Extrato() {
  const { setPontos } = usePontos();
  const { errorToast } = useToast();
  const { usuario, token, isTokenValid } = useAuth();
  const { screen, reloadData, setReloadData } = useScreen();
  const { extratoPontosConsumidor, extratoPontosAssociado } = useTimeline();

  const [reload, setReload] = useState(false);
  const [ultimoId, setUltimoId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [semRegistro, setSemRegistro] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [extrato, setExtrato] = useState<ITimeline<ExtratoItem | PontosUtilizados>[]>([]);

  const [isPrinting, setIsPrinting] = useState(false);
  const [print, setPrint] = useState(false);
  const [filtro, setChecked] = useState('T');

  
  async function getExtrato() {
    try {
      if (extrato.length > 0) setLoadingMore(true);

      
      let body={ultimoId:ultimoId,
                filtro:filtro} as IFiltroExtratoUsuarioDTO
      
     
      const listaExtrato =
        usuario.tipo === 1
          ? await listarExtratoUsuario.execute(body, token)
          : await listarExtratoUsuarioAssociado.execute(ultimoId, token);

      const timeline =
        usuario.tipo === 1
          ? extratoPontosConsumidor(listaExtrato as ExtratoItem[])
          : extratoPontosAssociado(listaExtrato as PontosUtilizados[]);

      setSemRegistro(timeline.length <= 0);

      if (timeline.length <= 0) return;

      if (extrato.length > 0) {
        if (extrato.length && extrato[extrato.length - 1].data === timeline[0].data) {
          const _extrato = [...extrato];
          const _timeline = [...timeline];

          _extrato[_extrato.length - 1].timeline.push(..._timeline[0].timeline);
          _timeline.shift();

          setExtrato([..._extrato, ..._timeline]);
        } else setExtrato([...extrato, ...timeline]);
      } else setExtrato(timeline);

      setSemRegistro(listaExtrato.length === 0);

      setUltimoId(
        timeline[timeline.length - 1].timeline[timeline[timeline.length - 1].timeline.length - 1].id
      );
    } catch (err) {
      const authExpirou = isTokenValid(err);
      errorToast(authExpirou || err);
    } finally {
      setReloadData(false);
      setReload(false);
      setLoading(false);
      setLoadingMore(false);
    }
  }
  function SetFiltro(filtroNovo){
    setChecked(filtroNovo);
    setReloadData(true);

  }
  function handleImpressao() {
    if (!isPrinting) return setIsPrinting(true);

    setPrint(true);
  }

  function onPrint() {
    setIsPrinting(false);
    setPrint(false);
  }

  useEffect(() => {
    if (screen === 'Extrato de Claritos') {
      setPontos();
      getExtrato();
    } else {
      setExtrato([]);
      setUltimoId(0);
      setLoading(true);
    }
  }, [screen]);

  useEffect(() => {
    if (ultimoId === 0 && reload) getExtrato();
  }, [ultimoId]);

  useEffect(() => {
    if (reloadData && screen === 'Extrato de Claritos') {
      setLoading(true);
      setExtrato([]);
      setReload(true);
      setUltimoId(0);
    }
  }, [reloadData]);
  
  return (
    <View style={styles.container}>
      <Header collapsed={usuario.tipo === 2} pontos={false}>
        <View style={styles.content}>
          {loading ? (
            <View style={{ marginTop: '50%' }}>
              <Loading />
            </View>
          ) : (
            <>
              {usuario.tipo === 1 ? (
                <View>
                  <View style={styles.containerFiltro}>
        
                    <Text style={styles.textIcon} >
                      <FontAwesome5 name='filter' color={theme.colors.blue300} style={styles.textIcon} />
                    </Text>
                    <Text  onPress={() => SetFiltro('E')} style={styles.textInput25} >
                      <RadioButton
                        value="S"
                        status={ filtro === 'E' ? 'checked' : 'unchecked' }
                        onPress={() => SetFiltro('E')}
                        
                      /> {'\n'} ENTRADA   
                    </Text>
                    <Text  onPress={() => SetFiltro('S')} style={styles.textInput25} >
                      <RadioButton
                        value="S"
                        status={ filtro === 'S' ? 'checked' : 'unchecked' }
                        onPress={() => SetFiltro('S')}
                        
                      /> {'\n'}SAÍDA   
                    </Text>
                    <Text  onPress={() => SetFiltro('T')} style={styles.textInput25} >
                      <RadioButton
                        value="S"
                        status={ filtro === 'T' ? 'checked' : 'unchecked' }
                        onPress={() => SetFiltro('T')}
                      /> {'\n'} TODOS
                      
                    </Text>
                  </View>
                  <TimelineConsumidorExtrato
                    extrato={extrato as ITimeline<ExtratoItem>[]}
                    loadingMore={loadingMore}
                    onUpdate={getExtrato}
                  />
                </View>
              ) : (
                <>
                  <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                    {isPrinting && (
                      <Button
                        mode="outlined"
                        text="Cancelar"
                        onPress={() => setIsPrinting(false)}
                      />
                    )}

                    <Button
                      text={isPrinting ? 'Imprimir' : 'Selecionar impressão'}
                      icon={<FontAwesome5 name="print" size={16} />}
                      onPress={handleImpressao}
                      style={{ marginLeft: 8 }}
                    />
                  </View>

                  <TimelineAssociadoExtrato
                    extrato={extrato as ITimeline<PontosUtilizados>[]}
                    loadingMore={loadingMore}
                    isPrinting={isPrinting}
                    print={print}
                    onPrint={onPrint}
                    onUpdate={getExtrato}
                  />
                </>
              )}

              {semRegistro && <SemMaisRegistro data={extrato} />}
            </>
          )}
        </View>
      </Header>
    </View>
  );
}
