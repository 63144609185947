import { StyleSheet } from 'react-native';
import { theme } from '../../../../../global/styles/theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    marginVertical: 8,
  },
  img: {
    marginVertical: 8,
  },
  img1: {
    width: 48,
    height: 48,
    alignSelf: 'center',
  },
  img2: {
    width: '100%',
    height: 56,
  },
  text: {
    fontSize: 16,
    fontFamily: theme.fonts.roboto700,
  },
});
