import { IApi } from '../../../../api/IApi';
import { IImagemUsuarioDTO } from '../ImagemUsuarioDTO';

export class BuscarImagemUsuarioController {
  constructor(private api: IApi) {}

  async execute(token: string): Promise<string | null> {
    const { imagem } = await this.api.get<IImagemUsuarioDTO>('usuarios/imagem', token);

    if (!imagem) return;

    const base64 = `data:image/png;base64,${imagem}`;

    return base64;
  }
}
