import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({

  Imagem:{
    
    width:'40vh',
    height:'40vh',   
        
  },
  TT:{
    height:'55vh',
    textAlign:'center'
  }
});

