import { StyleSheet } from 'react-native';
import { theme } from '../../../global/styles/theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    marginTop: 0,
  },
  btnContainer: {
    marginTop: 16,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  tipoValorLabel: {
    fontFamily: theme.fonts.roboto700,
    marginLeft: 8,
    fontSize: 26,
  },
});
