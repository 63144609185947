import { useState } from 'react';

export const useModal = (status=false) => {
  const [isModalOpen, setIsModalOpen] = useState(status);

  const openModal = () => setIsModalOpen(true);

  const closeModal = () => setIsModalOpen(false);

  return { isModalOpen, openModal, closeModal };
};
