import React, { createContext, useState } from 'react';

import { Notificacao } from '../entities/Notificacao';

interface INotificacaoContext {
  notificacao: Notificacao;
  setNotificacao: (_notificacao: Notificacao) => void;
}

interface INotificacaoContextProvider {
  children: React.ReactNode;
}

export const NotificacaoContext = createContext({} as INotificacaoContext);

export function NotificacaoProvider({ children }: INotificacaoContextProvider) {
  const [notificacao, setNotificacaoAtiva] = useState<Notificacao>();

  const setNotificacao = (_notificacao: Notificacao) => setNotificacaoAtiva(_notificacao);

  return (
    <NotificacaoContext.Provider value={{ notificacao, setNotificacao }}>
      {children}
    </NotificacaoContext.Provider>
  );
}
