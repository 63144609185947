import React from 'react';
import { locale } from 'moment';
import { useFonts } from 'expo-font';
import { StatusBar } from 'react-native';
import AppLoading from 'expo-app-loading';
import Toast from 'react-native-toast-message';
import { NavigationContainer } from '@react-navigation/native';
import { Provider as PaperProvider } from 'react-native-paper';

import { DMSans_400Regular } from '@expo-google-fonts/dm-sans';
import { Montserrat_500Medium } from '@expo-google-fonts/montserrat';
import { Roboto_400Regular, Roboto_700Bold } from '@expo-google-fonts/roboto';

import { Routes } from './src/routes';
import { AuthProvider } from './src/contexts/AuthContext';
import { ScreenProvider } from './src/contexts/ScreenContext';
import { BottomMenu } from './src/components/Menu/BottomMenu';
import { BaseToast } from './src/components/Common/BaseToast';
import { NotificacaoProvider } from './src/contexts/NotificacaoContext';

export default function App() {
  locale('pt-br');

  const [isFontsLoaded] = useFonts({
    Roboto_400Regular,
    Roboto_700Bold,
    DMSans_400Regular,
    Montserrat_500Medium,
  });

  const toastConfig = {
    success: ({ type, text1 }) => <BaseToast type={type} text1={text1} />,
    error: ({ type, text1 }) => <BaseToast type={type} text1={text1} />,
  };

  if (!isFontsLoaded) return <AppLoading />;

  return (
    <ScreenProvider>
      <AuthProvider>
        <NotificacaoProvider>
          <PaperProvider>
            <StatusBar barStyle="light-content" backgroundColor="transparent" translucent />

            <NavigationContainer>
              <Routes />
              <BottomMenu />

              <Toast ref={ref => Toast.setRef(ref)} config={toastConfig} />
            </NavigationContainer>
          </PaperProvider>
        </NotificacaoProvider>
      </AuthProvider>
    </ScreenProvider>
  );
}
