import { ILogarUsuarioDTO } from '../../Usuario/LogarUsuario/LogarUsuarioDTO';
import { Validacoes } from '../Validacoes/Validacoes';

export class LogarUsuarioValidacaoController {
  constructor(private validacoes: Validacoes) {}

  public handle({ cnpjCPF, senha }: ILogarUsuarioDTO) {
    if (!cnpjCPF || !this.validacoes.validaCnpjCpf(cnpjCPF)) throw new Error('CNPJ/CPF inválido');

    if (!senha || senha.length < 8) throw new Error('Senha inválida');

    const cnpjCPFFormatado = this.validacoes.formataCnpjCpf(cnpjCPF);

    const login: ILogarUsuarioDTO = { cnpjCPF: cnpjCPFFormatado, senha };

    return login;
  }
}
