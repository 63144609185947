import React from 'react';
import { FontAwesome5 } from '@expo/vector-icons';
import { Text, Pressable, View } from 'react-native';

import { useAuth } from '../../../../hooks/useAuth';

import { styles } from './styles';

export function DrawerOptionLogout() {
  const { logout } = useAuth();

  return (
    <Pressable style={styles.container} onPress={logout}>
      <View style={{ width: 32 }}>
        <FontAwesome5 name="door-open" style={styles.svg} />
      </View>

      <Text style={styles.option}>Sair</Text>
    </Pressable>
  );
}
