import { View,Text } from 'react-native';
import React, { useState } from 'react';

import { Button } from '../../components/Common/Button';
import { TextInputSenha } from '../../components/Common/TextInputSenha';

import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';

import { Header } from '../../components/Common/Header';
import { Loading } from '../../components/Common/Loading';

import { IEncerrarContaDTO } from '../../useCases/EncerrarConta/EncerrarContaDTO';
import { encerrarContaController } from '../../useCases/EncerrarConta';

import { useNavigation } from '@react-navigation/native';
import { useScreen } from '../../hooks/useScreen';

import { styles } from './styles';
import { theme } from '../../global/styles/theme';

interface ISenha {
  senhaAtual?: string;
}

export function EncerrarConta() {
  const { usuario,token,logout } = useAuth();
  const { errorToast, successToast } = useToast();  
  
  const { setScreen } = useScreen();
  const { navigate } = useNavigation();
  if (!usuario) return <Loading />;

   function CancelarEncerramento(){
    setScreen('Sobre');
    navigate('Sobre' as any);
  }
  const [senha, setSenha] = useState<ISenha>({} as ISenha);
  async function Encerrar(){
    const encerrarContaDTO = {} as IEncerrarContaDTO;

    encerrarContaDTO.senha=senha.senhaAtual;
    try
    {
      await encerrarContaController.execute(encerrarContaDTO, token);
      successToast('Conta encerrada com sucesso');      
      logout();
    }
    catch(e){
      errorToast('Erro ao encerrar a conta: '+e);
    }
    
    
  }

  const DEFAULT_THEME = {
    colors: {
      primary: theme.colors.blue50,
      text: theme.colors.text,
      placeholder: theme.colors.blue300,
    },
  };
  return (
    <View style={styles.container}>
      <Header collapsed pontos={false}>
            <Text>
              Ao encerrar a sua conta todos os seus dados serão apagados e você não poderá recuperar, deseja mesmo continuar ?
            </Text>

            <TextInputSenha
            label="Senha atual"
            onChangeText={senhaAtual => setSenha({ ...senha, senhaAtual })}
            value={senha?.senhaAtual}
            style={styles.textInput}
            theme={DEFAULT_THEME}
            eyeColor={theme.colors.blue300}
            underlineColor={theme.colors.blue300}
            />

            <View style={styles.botoes}>

              <Button
                text="Encerrar conta"
                mode="outlined"
                compact={false}
                uppercase={true}
                onPress={Encerrar}
                style={{ marginLeft: 8 }}
              />
              <Button
                text="Cancelar"
                mode="outlined"
                compact={false}
                uppercase={true}
                onPress={CancelarEncerramento}
                style={{ marginLeft: 8 }}
              />

          </View>
      </Header>
    </View>
  );
}
