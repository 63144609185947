import { IApi } from '../../../api/IApi';
import { IListarPedidosUsuarioDTO } from './ListarPedidosUsuarioDTO';

interface IStatusPedido {
  status: number;
  descricao: string;
}

export class ListarPedidosUsuarioController {
  constructor(private api: IApi) {}

  async execute(ultimoId: number, token: string): Promise<IListarPedidosUsuarioDTO[]> {
    const listaPedidos = await this.api.get<IListarPedidosUsuarioDTO[]>(
      `usuarios/pedidos/${ultimoId}`,
      token
    );

    const statusDesc: IStatusPedido[] = [
      { status: 1, descricao: 'Produzindo' },
      { status: 2, descricao: 'Claritos Disponíveis' },
      { status: 3, descricao: 'Claritos Resgatados' },
      { status: 4, descricao: 'Claritos Perdidos' },
    ];

    listaPedidos.forEach((pedido, i) => {
      listaPedidos[i].statusDesc = statusDesc.find(e => e.status === pedido.status).descricao;
    });

    return listaPedidos;
  }
}
