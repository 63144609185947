import React from 'react';
import { View, Text } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';

import { styles } from './styles';

export function BaseToast({ type, text1 }) {
  const backgroundColor = {
    error: '#ec4427',
    success: '#32CD32',
  };

  const borderColor = {
    error: '#b93119',
    success: '#25b325',
  };

  return (
    <View
      style={[
        styles.container,
        { backgroundColor: backgroundColor[type], borderColor: borderColor[type] },
      ]}
    >
      {type === 'success' ? (
        <FontAwesome5 name="check" size={20} color="white" />
      ) : (
        <FontAwesome5 name="times" size={20} color="white" />
      )}
      <Text style={styles.text}>{text1}</Text>
    </View>
  );
}
