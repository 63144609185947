import React, { useState, useEffect } from 'react';
import { Text, View, Pressable, Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { useAuth } from '../../../../hooks/useAuth';
import { useScreen } from '../../../../hooks/useScreen';

import { Avatar } from '../../../Common/Avatar';

import CLARITY_LOGO from '../../../../assets/LogoCompleto.png';

import { styles } from './styles';

export function DrawerHeader() {
  const { setScreen } = useScreen();
  const { navigate } = useNavigation();
  const [nome, setNome] = useState('');
  const { usuario } = useAuth();

  useEffect(() => {
    if (usuario && usuario.nome.length > 23) {
      const _nome = usuario.nome.slice(0, 21);

      setNome(`${_nome}...`);
    }
  }, [usuario]);

  function handleProfileClick() {
    setScreen('Perfil');
    navigate('Perfil' as any);
  }

  return (
    <View style={styles.container}>
      <Pressable onPress={handleProfileClick}>
        <Avatar
          userInfo={{ nome: usuario?.nome ?? '', avatar: usuario?.imagem ?? null }}
          tamanho="lg"
        />
      </Pressable>

      <View style={styles.info}>
        <Pressable style={styles.userContainer} onPress={handleProfileClick}>
          <Text style={styles.username}>{nome !== '' ? nome : usuario?.nome}</Text>
        </Pressable>

        <Image source={CLARITY_LOGO} style={styles.clarityLogo} resizeMode="contain" />
      </View>
    </View>
  );
}
