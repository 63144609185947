import { View } from 'react-native';
import React, { useState, useEffect } from 'react';

import { Notificacao } from '../../entities/Notificacao';
import { pushNotification } from '../../services/PushNotification';
import { listarNotificacoes } from '../../useCases/Notificacao/ListarNotificacoes';

import { useAuth } from '../../hooks/useAuth';
import { useModal } from '../../hooks/useModal';
import { useToast } from '../../hooks/useToast';
import { usePontos } from '../../hooks/usePontos';
import { useScreen } from '../../hooks/useScreen';

import { Header } from '../../components/Common/Header';
import { Loading } from '../../components/Common/Loading';
import { SemRegistro } from '../../components/Common/SemRegistro';
import { ListaNotificacoes } from '../../components/Notificacoes/ListaNotificacoes';
import { ModalDetalhesNotificacao } from '../../components/Notificacoes/ModalDetalhesNotificacao';

import { styles } from './styles';

export function Notificacoes() {
  const { screen } = useScreen();
  const { setPontos } = usePontos();
  const { errorToast } = useToast();
  const { usuario, token, isTokenValid } = useAuth();
  const { isModalOpen, openModal, closeModal } = useModal();

  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [ultimoId, setUltimoId] = useState(0);
  const [notificacoes, setNotificacoes] = useState<Notificacao[]>([]);

  async function getNotificacoes() {
    try {
      if (notificacoes.length > 0) setLoadingMore(true);

      const listaNotificacoes = await listarNotificacoes.execute(ultimoId, token);

      setNotificacoes([...notificacoes, ...listaNotificacoes]);

      if (listaNotificacoes.length) setUltimoId(listaNotificacoes[listaNotificacoes.length - 1].id);
    } catch (err) {
      const authExpirou = isTokenValid(err);
      errorToast(authExpirou || err);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  }

  useEffect(() => {
    if (screen === 'Notificações') {
      getNotificacoes();
      setPontos();

      pushNotification.register(token);
    } else {
      setNotificacoes([]);
      setUltimoId(0);
      setLoading(true);
    }
  }, [screen]);

  return (
    <View style={styles.container}>
      <Header collapsed={usuario.tipo === 2} pontos={usuario.tipo === 1}>
        <View style={styles.content}>
          {loading ? (
            <View style={{ marginTop: '50%' }}>
              <Loading />
            </View>
          ) : notificacoes.length ? (
            <ListaNotificacoes
              notificacoes={notificacoes}
              onClick={openModal}
              onUpdate={getNotificacoes}
              loadingMore={loadingMore}
            />
          ) : (
            <SemRegistro screen="Notificações" />
          )}
        </View>

        <ModalDetalhesNotificacao isOpen={isModalOpen} onClose={closeModal} />
        
      </Header>
    </View>
  );
}
