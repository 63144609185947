import { StyleSheet } from 'react-native';
import { theme } from '../../../../global/styles/theme';

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingVertical: 8,
    paddingHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  info: {
    marginLeft: 16,
  },
  userContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    top: 10,
  },
  username: {
    fontFamily: theme.fonts.roboto700,
    fontSize: 16,
    color: theme.colors.white,
  },
  clarityLogo: {
    height: 48,
    top: 6,
  },
});
