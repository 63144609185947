import { View } from 'react-native';
import React, { useState, useEffect } from 'react';

import { PontoUtilizacao } from '../../entities/PontoUtilizacao';
import { buscarPontoUtilizacao } from '../../useCases/PontoUtilizacao/BuscarPontoUtilizacao';

import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';
import { useScreen } from '../../hooks/useScreen';
import { usePontos } from '../../hooks/usePontos';

import { Header } from '../../components/Common/Header';
import { FormResgatarPontos } from '../../components/ResgatarPontos/FormResgatarPontos';
import { QrCodeResgatarPontos } from '../../components/ResgatarPontos/QrCodeResgatarPontos';
import { AssociadoResgatarPontos } from '../../components/ResgatarPontos/AssociadoResgatarPontos';

import { styles } from './styles';

export function ResgatarPontos() {
  const { screen } = useScreen();
  const { setPontos } = usePontos();
  const { errorToast } = useToast();
  const { token, isTokenValid } = useAuth();

  const [pagar, setPagar] = useState(false);
  const [scanned, setScanned] = useState(false);
  const [pontoUtilizacao, setPontoUtilizacao] = useState({} as PontoUtilizacao);

  async function handleQRCode(data: string) {
    if (!data) return;

    try {
      const id = Number(data);
      await setPontos();

      if (isNaN(id)) throw new Error('QR Code inválido');

      const pontoUtilizacao = await buscarPontoUtilizacao.execute(id, token);

      setPontoUtilizacao(pontoUtilizacao);
      setScanned(true);
    } catch (err) {
      const authExpirou = isTokenValid(err);
      errorToast(authExpirou || err);
    }
  }

  useEffect(() => {
    if (screen === 'Resgatar Claritos') {
      setPontos();
      setPagar(false);
      setScanned(false);
    }
  }, [screen]);

  return (
    <View style={[styles.container, { backgroundColor: scanned ? 'transparent' : 'black' }]}>
      <Header hidden={!scanned}>
        {!scanned ? (
          <QrCodeResgatarPontos onScan={handleQRCode} />
        ) : !pagar ? (
          <AssociadoResgatarPontos
            pontoUtilizacao={pontoUtilizacao}
            onConfirmar={() => setPagar(true)}
            onCancelar={() => setScanned(false)}
          />
        ) : (
          <FormResgatarPontos
            onVoltar={() => setPagar(false)}
            pontoUtilizacao={pontoUtilizacao.id}
          />
        )}
      </Header>
    </View>
  );
}
