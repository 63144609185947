import { IApi } from '../../../api/IApi';
import { Notificacao } from '../../../entities/Notificacao';

export class ImagemNotificacaoController {
  constructor(private api: IApi) {}

  async execute(id: number, token: string): Promise<string> {
    const { imagem } = await this.api.get<Notificacao>(`usuarios/notificacoes/imagem/${id}`, token);

    return imagem;
  }
}
