import { IApi } from '../../../../api/IApi';
import { IBuscarPontosUsuarioDTO } from './BuscarPontosUsuarioDTO';

export class BuscarPontosUsuarioController {
  constructor(private api: IApi) {}

  async execute(token: string): Promise<IBuscarPontosUsuarioDTO> {
    const pontos = await this.api.get<IBuscarPontosUsuarioDTO>('usuarios/pontos', token);

    return pontos;
  }
}
