import { View } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';

import { Pedido } from '../../entities/Pedido';
import { listarPedidosUsuario } from '../../useCases/Usuario/ListarPedidosUsuario';
import { IPedidoSint } from '../../useCases/Usuario/ListarPedidosUsuario/ListarPedidosUsuarioDTO';

import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';
import { useModal } from '../../hooks/useModal';
import { useScreen } from '../../hooks/useScreen';
import { usePontos } from '../../hooks/usePontos';
import { useTimeline, ITimeline } from '../../hooks/useTimeline';

import { Header } from '../../components/Common/Header';
import { Loading } from '../../components/Common/Loading';
import { SemMaisRegistro } from '../../components/Common/SemMaisRegistro';
import { TimelinePedidos } from '../../components/Pedidos/TimelinePedidos';
import { ModalPedidoSintetizado } from '../../components/Pedidos/ModalPedidoSintetizado';

import { styles } from './styles';

export function Pedidos() {
  const { setPontos } = usePontos();
  const { navigate } = useNavigation();
  const { extratoPedidos } = useTimeline();
  const { token, isTokenValid } = useAuth();
  const { errorToast, successToast } = useToast();
  const { isModalOpen, openModal, closeModal } = useModal();
  const { screen, setScreen, reloadData, setReloadData } = useScreen();

  const [reload, setReload] = useState(false);
  const [ultimoId, setUltimoId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [semRegistro, setSemRegistro] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [pedidos, setPedidos] = useState<ITimeline<Pedido>[]>([]);

  const [referencia, setReferencia] = useState(0);
  const [pedidoCliente, setPedidoCliente] = useState('');
  const [pedidoSint, setPedidoSint] = useState<IPedidoSint[]>([]);

  async function handleResgate(success: boolean) {
    if (success) {
      await setPontos();
      successToast('Claritos resgatados');
      setScreen('Extrato de Claritos');
      navigate('Extrato de Claritos' as any);
    } else {
      errorToast(`Claritos já resgatados`);
      setLoading(true);
      setReload(true);
      setPedidos([]);

      setTimeout(() => setUltimoId(0), 500);
    }
  }

  async function getPedidos() {
    try {
      if (pedidos.length > 0) setLoadingMore(true);

      const listaPedidos = await listarPedidosUsuario.execute(ultimoId, token);
      const timeline = extratoPedidos(listaPedidos);

      setSemRegistro(timeline.length <= 0);

      if (timeline.length <= 0) return;

      if (pedidos.length > 0) {
        // if (timeline.length > 0 && pedidos.at(-1).data === timeline.at(-1).data) {
        if (
          timeline.length > 0 &&
          pedidos[pedidos.length - 1].data === timeline[timeline.length - 1].data
        ) {
          const _pedidos = [...pedidos];
          const _timeline = [...timeline];

          // _pedidos.at(-1).timeline.push(..._timeline.at(0).timeline);
          _pedidos[_pedidos.length - 1].timeline.push(..._timeline[0].timeline);
          _timeline.shift();

          setPedidos([..._pedidos, ..._timeline]);
        } else setPedidos([...pedidos, ...timeline]);
      } else setPedidos(timeline);

      setSemRegistro(listaPedidos.length === 0);

      // setUltimoId(timeline.at(-1).timeline.at(-1).id);
      setUltimoId(
        timeline[timeline.length - 1].timeline[timeline[timeline.length - 1].timeline.length - 1].id
      );
    } catch (err) {
      const authExpirou = isTokenValid(err);
      errorToast(authExpirou || err);
    } finally {
      setReloadData(false);
      setReload(false);
      setLoading(false);
      setLoadingMore(false);
    }
  }

  function handleOpenSintModalClick(
    pedidosSint: IPedidoSint[],
    pedidoCliente: string,
    referencia: number
  ) {
    setPedidoSint(pedidosSint);
    setPedidoCliente(pedidoCliente);
    setReferencia(referencia);
    openModal();
  }

  useEffect(() => {
    if (screen === 'Meus Pedidos') {
      setPontos();
      getPedidos();
    } else {
      setPedidos([]);
      setUltimoId(0);
      setLoading(true);
    }
  }, [screen]);

  useEffect(() => {
    if (ultimoId === 0 && reload) getPedidos();
  }, [ultimoId]);

  useEffect(() => {
    if (reloadData && screen === 'Meus Pedidos') {
      setLoading(true);
      setPedidos([]);
      setReload(true);
      setUltimoId(0);
    }
  }, [reloadData]);

  return (
    <View style={styles.container}>
      <Header>
        <View style={styles.content}>
          {loading ? (
            <View style={{ marginTop: '50%' }}>
              <Loading />
            </View>
          ) : (
            <>
              <TimelinePedidos
                pedidos={pedidos}
                loadingMore={loadingMore}
                onUpdate={getPedidos}
                onResgate={handleResgate}
                onOpenSint={handleOpenSintModalClick}
              />

              {semRegistro && <SemMaisRegistro pedido data={pedidos} />}
            </>
          )}
        </View>

        <ModalPedidoSintetizado
          sint={pedidoSint}
          pedidoCliente={pedidoCliente}
          referencia={referencia}
          isOpen={isModalOpen}
          onClose={closeModal}
        />
      </Header>
    </View>
  );
}
