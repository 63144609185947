import { IApi } from '../../api/IApi';
import { IEncerrarContaDTO } from './EncerrarContaDTO';

export class EncerrarContaController {
    constructor(private api: IApi) {}
  
    async execute(data: IEncerrarContaDTO, token: string): Promise<void> {
      await this.api.post<void, IEncerrarContaDTO>('usuarios/EncerrarConta', data, token);
    }
  }
  