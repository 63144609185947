import { FontAwesome5 } from '@expo/vector-icons';
import React, { useState, useEffect } from 'react';
import { View, Text, TextInput } from 'react-native';
import { useNavigation } from '@react-navigation/core';
import CurrencyInput from 'react-native-currency-input';

import { utilizarPontosUsuario } from '../../../useCases/Usuario/PontosUsuario';
import { fatorConversaoUsuario } from '../../../useCases/Usuario/FatorConversaoUsuario';
import { IUtilizarPontosUsuarioDTO } from '../../../useCases/Usuario/PontosUsuario/UtilizarPontosUsuario/UtilizarPontosUsuarioDTO';

import { useAuth } from '../../../hooks/useAuth';
import { useToast } from '../../../hooks/useToast';
import { useModal } from '../../../hooks/useModal';
import { usePontos } from '../../../hooks/usePontos';
import { useScreen } from '../../../hooks/useScreen';
import { useNumberFormat } from '../../../hooks/useNumberFormat';

import { Card } from '../../Common/Card';
import { Badge } from '../../Common/Badge';
import { Button } from '../../Common/Button';
import { Loading } from '../../Common/Loading';
import { ModalConfirmacao } from '../../Common/Modal/ModalConfirmacao';

import { styles } from './styles';

interface IFormResgatarPontos {
  pontoUtilizacao: number;
  onVoltar: () => void;
}

export function FormResgatarPontos({ pontoUtilizacao, onVoltar }: IFormResgatarPontos) {
  const { setPontos } = usePontos();
  const { setScreen } = useScreen();
  const { navigate } = useNavigation();
  const { milhar, currency } = useNumberFormat();
  const { successToast, errorToast } = useToast();
  const { usuario, token, isTokenValid } = useAuth();
  const { isModalOpen, openModal, closeModal } = useModal();

  const [fator, setFator] = useState(0);
  const [valorTotal, setValorTotal] = useState(0);
  const [pontosTotal, setPontosTotal] = useState(0);
  const [pontosDifValor, setPontosDifValor] = useState(0);
  const [confirmaConversao, setConfirmaConversao] = useState(false);

  const getFator = async () => setFator(await fatorConversaoUsuario.execute(token));

  function handleDinheiroInputChange(dinheiroInp: number) {
    let dinheiro = dinheiroInp;
    let dinheiroEmPontos = dinheiroInp / fator;

    if (dinheiroEmPontos > usuario.pontosTotal) return;

    if (isNaN(dinheiroEmPontos)) return;

    if (!Number.isInteger(dinheiroEmPontos)) {
      dinheiroEmPontos = Math.floor(dinheiroEmPontos) + 1;

      if (dinheiroEmPontos > usuario.pontosTotal) throw new Error('Claritos insuficientes');

      setPontosDifValor(dinheiroEmPontos * fator);
    } else setPontosDifValor(0);

    setValorTotal(dinheiro);
    setPontosTotal(dinheiroEmPontos >= 1 ? dinheiroEmPontos : 0);
  }

  function handlePontosInputChange(pontosInp: string) {
    const _pontos = Number(pontosInp.replace('.', ''));

    if (isNaN(_pontos)) return;

    if (_pontos > usuario.pontosTotal) return;

    setPontosDifValor(0);
    setPontosTotal(_pontos);
    setValorTotal(_pontos * fator);
  }

  function handleVoltarClick() {
    setPontosTotal(0);
    setValorTotal(0);
    setFator(0);
    setConfirmaConversao(false);
    setPontosDifValor(0);
    onVoltar();
  }

  function handleConfirmarConversaoClick() {
    setConfirmaConversao(true);
    setValorTotal(pontosDifValor);
    setPontosDifValor(0);
    closeModal();
  }

  async function handleConfirmarPagamentoClick() {
    if (pontosDifValor > 0) return openModal();

    try {
      if (pontosTotal <= 0 || valorTotal <= 0)
        throw new Error('Insira alguma quantidade de dinheiro');

      if (pontosTotal > usuario.pontosTotal) throw new Error('Claritos insuficientes');

      const pontosUtilizados: IUtilizarPontosUsuarioDTO = {
        pontosTotal,
        valorTotal,
        pontoUtilizacao,
      };

      await utilizarPontosUsuario.execute(pontosUtilizados, token);

      await setPontos();
      successToast('Pagamento efetuado');
      setScreen('Extrato de Claritos');
      navigate('Extrato de Claritos' as any);
    } catch (err) {
      const authExpirou = isTokenValid(err);
      errorToast(authExpirou || err);
    } finally {
      setConfirmaConversao(false);
    }
  }

  useEffect(() => {
    if (confirmaConversao) handleConfirmarPagamentoClick();
  }, [pontosDifValor]);

  useEffect(() => {
    getFator();
  }, []);

  if (fator === 0) return <Loading />;

  return (
    <>
      <View style={styles.container}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginVertical: 16,
          }}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <FontAwesome5 name="dollar-sign" size={20} />
            <Text style={styles.tipoValorLabel}>Dinheiro</Text>
          </View>

          <Badge type="info" text={`1 ponto = ${currency(fator)}`} />
        </View>

        <Card>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <CurrencyInput
              value={valorTotal}
              onChangeValue={handleDinheiroInputChange}
              keyboardType="number-pad"
              prefix="R$ "
              delimiter="."
              separator=","
              precision={2}
              minValue={0}
              style={styles.textInput}
            />
          </View>
        </Card>

        {/* <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginVertical: 16,
          }}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <FontAwesome5 name="coins" size={20} />
            <Text style={styles.tipoValorLabel}>Claritos</Text>
          </View>

          {pontosDifValor > 0 && (
            <Badge
              type="warning"
              text={`${pontosTotal} ponto${pontosTotal > 1 ? 's' : ''} = ${currency(
                pontosDifValor
              )}`}
            />
          )}
        </View>

        <Card>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <TextInput
              placeholder="0"
              keyboardType="number-pad"
              value={milhar(pontosTotal)}
              onChangeText={handlePontosInputChange}
              style={styles.textInput}
            />
          </View>
        </Card> */}

        <View style={styles.btnContainer}>
          <Button text="Voltar" mode="outlined" onPress={handleVoltarClick} />

          <Button
            text="Confirmar pagamento"
            mode="contained"
            onPress={handleConfirmarPagamentoClick}
            style={{ marginLeft: 8 }}
          />
        </View>
      </View>

      <ModalConfirmacao
        header="Valores divergentes. Confirmar correção?"
        body={
          <View>
            <View style={{ flexDirection: 'row' }}>
              <Text style={[styles.text, styles.bold, styles.modalDivergente]}>
                Valor em dinheiro:{' '}
              </Text>
              <Text style={[styles.text, styles.modalDivergente]}>{currency(valorTotal)}</Text>
            </View>

            <View style={{ flexDirection: 'row' }}>
              <Text style={[styles.text, styles.bold, styles.modalDivergente]}>
                Valor em Claritos:{' '}
              </Text>
              <Text style={[styles.text, styles.modalDivergente]}>{milhar(pontosTotal)}</Text>
            </View>

            <View>
              <View style={{ flexDirection: 'row', marginTop: 16 }}>
                <Text style={[styles.text, styles.bold, styles.modalCorrigido]}>
                  Valor em dinheiro corrigido:{' '}
                </Text>
                <Text style={[styles.text, styles.modalCorrigido]}>{currency(pontosDifValor)}</Text>
              </View>

              <Text style={{ opacity: 0.5 }}>{`(${milhar(pontosTotal)} * ${
                Math.round(fator * 100) / 100
              } = ${currency(pontosDifValor)})`}</Text>
            </View>
          </View>
        }
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={handleConfirmarConversaoClick}
      />
    </>
  );
}
