import React from 'react';

import { useAuth } from '../hooks/useAuth';
import { Loading } from '../components/Common/Loading';

import { AuthRoutes } from './auth.routes';
import { AppRoutes } from './app.routes';

export function Routes() {
  const { usuario, loading } = useAuth();

  if (loading) return <Loading />;

  return usuario ? <AuthRoutes /> : <AppRoutes />;
}
