import { Button } from 'react-native-paper';
import { View, Text, Image } from 'react-native';
import { useReactToPrint } from 'react-to-print';
import React, { useState, SetStateAction, useRef } from 'react';
import { exportComponentAsPNG } from 'react-component-export-image';

import { Modal } from '../../Common/Modal';

import Logo from '../../../assets/LogoVertical.png';

import { Loading } from '../../Common/Loading';
import { theme } from '../../../global/styles/theme';

interface IModalNovoPontoUtilizacao {
  uri: string;
  associado: string;
  header: string;
  isOpen: boolean;
  onClose: React.Dispatch<SetStateAction<boolean>>;
}

export function ModalQrCode({
  uri,
  associado,
  header: desc,
  isOpen,
  onClose,
}: IModalNovoPontoUtilizacao) {
  const QrRef = useRef(null);

  const [loading, setLoading] = useState(true);

  const print = useReactToPrint({ content: () => QrRef.current });

  const download = async () => exportComponentAsPNG(QrRef, { fileName: desc });

  return (
    <Modal header={desc} isModalOpen={isOpen} setIsModalOpen={() => onClose(false)}>
      <View style={{ alignItems: 'center' }}>
        <View ref={QrRef} style={{ alignItems: 'center', padding: 16 }}>
          <Image
            source={{ uri: Logo }}
            style={{ width: 160, height: 160, display: loading ? 'none' : 'flex' }}
            onLoadEnd={() => setLoading(false)}
          />

          {loading ? (
            <View style={{ marginVertical: 32 }}>
              <Loading />
            </View>
          ) : (
            <>
              <Image source={{ uri }} style={{ width: 200, height: 200 }} />

              <Text
                style={{
                  fontFamily: theme.fonts.roboto700,
                  fontSize: 20,
                  marginVertical: 16,
                }}
              >
                {associado} - {desc}
              </Text>
            </>
          )}
        </View>

        {!loading && (
          <View
            style={{
              marginTop: 16,
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Button mode="contained" color={theme.colors.blue100} onPress={print}>
              <Text style={{ color: theme.colors.white }}>Imprimir</Text>
            </Button>

            <Button
              mode="contained"
              color={theme.colors.blue100}
              onPress={download}
              style={{ marginLeft: 16 }}
            >
              <Text style={{ color: theme.colors.white }}>Baixar</Text>
            </Button>
          </View>
        )}
      </View>
    </Modal>
  );
}
