import React from 'react';
import { Text } from 'react-native';

import { theme } from '../../../global/styles/theme';

interface IBadge {
  text?: string;
  icon?: React.ElementType;
  type: 'info' | 'warning';
}

export function Badge({ icon, text, type }: IBadge) {
  const { blue100, red } = theme.colors;
  const color = { info: blue100, warning: red };

  return (
    <Text
      style={{
        paddingVertical: 4,
        paddingHorizontal: 8,
        borderRadius: 16,
        backgroundColor: color[type],
        opacity: 0.9,
        color: theme.colors.white,
        fontFamily: theme.fonts.roboto400,
        fontSize: 12,
      }}
    >
      {icon}
      {text}
    </Text>
  );
}
