import { StyleSheet } from 'react-native';
import { theme } from '../../global/styles/theme';

export const styles = StyleSheet.create({
  container: {
    
  },
  textInput: {
    backgroundColor: 'transparent',
  },
  botoes:{
    marginTop:'60px',
    marginRight:'10px',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  }
});
