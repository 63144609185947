import { View,Text } from 'react-native';
import { TextInput,Checkbox } from 'react-native-paper';
import React, { useEffect, useState } from 'react';

import { alterarCadastroUsuario } from '../../../useCases/Usuario/AlterarCadastroUsuario';
import { alterarPerfilUsuarioValidacao } from '../../../useCases/Validacao/AlterarPerfilUsuarioValidacao';
import { IAlterarCadastroUsuarioDTO } from '../../../useCases/Usuario/AlterarCadastroUsuario/AlterarCadastroUsuarioDTO';

import { useCep } from '../../../hooks/useCep';
import { useAuth } from '../../../hooks/useAuth';
import { useToast } from '../../../hooks/useToast';

import { Card } from '../../Common/Card';

import { styles } from './styles';
import { theme } from '../../../global/styles/theme';

interface IFormPerfil {
  editando: boolean;
  cancelar: boolean;
  salvar: boolean;
  setEditando: () => void;
  setCancelar: () => void;
  setSalvar: () => void;
}

const DEFAULT_THEME = {
  colors: {
    text: theme.colors.text,
    primary: theme.colors.blue50,
    placeholder: theme.colors.blue300,
  },
};

export function FormPerfil({
  editando,
  cancelar,
  salvar,
  setEditando,
  setCancelar,
  setSalvar,
}: IFormPerfil) {
  const { endereco, observerCep } = useCep();
  const { errorToast, successToast } = useToast();
  const { usuario, token, setUser, isTokenValid } = useAuth();

  const [campos, setCampos] = useState<IAlterarCadastroUsuarioDTO>({ ...usuario });
  const [receberNotificacao, setSalvarCampos] = useState(usuario.receberNotificacao);

  observerCep(campos?.endereco?.cep ?? '');

  async function handleSalvarAlteracoes() {
    try {

           
      await alterarPerfilUsuarioValidacao.handle(campos);

      const dadosAlterados = {} as IAlterarCadastroUsuarioDTO;

      campos.receberNotificacao=receberNotificacao;

      for (const campo in campos)
        dadosAlterados[campo] = campos[campo];

      await alterarCadastroUsuario.execute(dadosAlterados, token);

      setUser({ ...usuario, ...campos });
      setSalvar();
      setEditando();

      successToast('Dados alterados');
    } catch (err) {
      const authExpirou = isTokenValid(err);
      errorToast(authExpirou || err);
    }
  }

  useEffect(() => {
    if (salvar) handleSalvarAlteracoes();
  }, [salvar]);

  useEffect(() => {
    if (cancelar) {
      setCampos({ ...usuario });
      setSalvarCampos(usuario.receberNotificacao);
      setCancelar();
      setEditando();
    }
  }, [cancelar]);

  useEffect(() => setCampos({ ...campos, endereco }), [endereco]);

  return (
    <View style={styles.container}>
      <View style={{ marginTop: 16 }}>
        <Card>
          <TextInput
            label="CEP*"
            keyboardType="number-pad"
            onChangeText={cep => setCampos({ ...campos, endereco: { ...campos.endereco, cep } })}
            value={campos.endereco?.cep}
            maxLength={9}
            onPressIn={() => {}}
            onPressOut={() => {}}
            editable={editando}
            autoComplete="true"
            style={styles.textInput}
            theme={DEFAULT_THEME}
            underlineColor={theme.colors.blue300}
          />

          <View style={styles.flexbox}>
            <TextInput
              label="Endereço*"
              keyboardType="default"
              onChangeText={endereco =>
                setCampos({ ...campos, endereco: { ...campos.endereco, endereco } })
              }
              value={campos?.endereco?.endereco}
              onPressIn={() => {}}
              onPressOut={() => {}}
              editable={false}
              autoComplete="true"
              style={styles.textInput75}
              theme={DEFAULT_THEME}
              underlineColor={theme.colors.blue300}
            />

            <TextInput
              label="Nº*"
              keyboardType="number-pad"
              onChangeText={enderecoNo =>
                setCampos({
                  ...campos,
                  enderecoNo: !isNaN(Number(enderecoNo)) ? Number(enderecoNo) : campos.enderecoNo,
                })
              }
              value={campos.enderecoNo.toString()}
              onPressIn={() => {}}
              onPressOut={() => {}}
              editable={editando}
              autoComplete="true"
              style={styles.textInput25}
              theme={DEFAULT_THEME}
              underlineColor={theme.colors.blue300}
            />
          </View>

          <TextInput
            label="Complemento"
            keyboardType="default"
            onChangeText={enderecoCompl => setCampos({ ...campos, enderecoCompl })}
            value={campos.enderecoCompl ?? ''}
            onPressIn={() => {}}
            onPressOut={() => {}}
            editable={editando}
            autoComplete="true"
            style={styles.textInput}
            theme={DEFAULT_THEME}
            underlineColor={theme.colors.blue300}
          />

          <TextInput
            label="Bairro*"
            keyboardType="default"
            onChangeText={bairro =>
              setCampos({ ...campos, endereco: { ...campos.endereco, bairro } })
            }
            value={campos?.endereco?.bairro}
            onPressIn={() => {}}
            onPressOut={() => {}}
            editable={false}
            autoComplete="true"
            style={styles.textInput}
            theme={DEFAULT_THEME}
            underlineColor={theme.colors.blue300}
          />

          <TextInput
            label="Email*"
            keyboardType="email-address"
            onChangeText={email => setCampos({ ...campos, email })}
            value={campos.email}
            onPressIn={() => {}}
            onPressOut={() => {}}
            editable={editando}
            autoComplete="true"
            style={styles.textInput}
            theme={DEFAULT_THEME}
            underlineColor={theme.colors.blue300}
          />

          <View style={styles.flexbox}>
            <TextInput
              label="WhatsApp*"
              keyboardType="number-pad"
              onChangeText={contatoTel1 => setCampos({ ...campos, contatoTel1 })}
              value={campos.contatoTel1}
              onPressIn={() => {}}
              onPressOut={() => {}}
              editable={editando}
              autoComplete="true"
              style={styles.textInput50}
              theme={DEFAULT_THEME}
              underlineColor={theme.colors.blue300}
            />

            <TextInput
              label="Contato*"
              keyboardType="default"
              onChangeText={contatoNome1 => setCampos({ ...campos, contatoNome1 })}
              value={campos.contatoNome1 ?? ''}
              onPressIn={() => {}}
              onPressOut={() => {}}
              editable={editando}
              autoComplete="true"
              style={styles.textInput50}
              theme={DEFAULT_THEME}
              underlineColor={theme.colors.blue300}
            />
          </View>

          <View style={styles.flexbox}>
            <TextInput
              label="Telefone 2"
              keyboardType="number-pad"
              onChangeText={contatoTel2 => setCampos({ ...campos, contatoTel2 })}
              value={campos.contatoTel2 ?? ''}
              onPressIn={() => {}}
              onPressOut={() => {}}
              editable={editando}
              autoComplete="true"
              style={styles.textInput50}
              theme={DEFAULT_THEME}
              underlineColor={theme.colors.blue300}
            />

            <TextInput
              label="Contato"
              keyboardType="default"
              onChangeText={contatoNome2 => setCampos({ ...campos, contatoNome2 })}
              value={campos.contatoNome2 ?? ''}
              onPressIn={() => {}}
              onPressOut={() => {}}
              editable={editando}
              autoComplete="true"
              style={styles.textInput50}
              theme={DEFAULT_THEME}
              underlineColor={theme.colors.blue300}
            />
          </View>

          <TextInput
            label="Site"
            keyboardType="url"
            onChangeText={site => setCampos({ ...campos, site })}
            value={campos.site ?? ''}
            onPressIn={() => {}}
            onPressOut={() => {}}
            editable={editando}
            autoComplete="true"
            style={[styles.textInput, { marginBottom: 8 }]}
            theme={DEFAULT_THEME}
            underlineColor={theme.colors.blue300}
          />
          <Text  onPress={() => {if(editando)setSalvarCampos(!receberNotificacao)}} >
            <Checkbox status={receberNotificacao ? 'checked' : 'unchecked'}
              onPress={() => { if (editando) setSalvarCampos(!receberNotificacao) }}
              theme={DEFAULT_THEME}/> Receber notificações
          </Text>

        </Card>
      </View>
    </View>
  );
}
