import { StyleSheet } from 'react-native';
import { theme } from '../../../../global/styles/theme';

export const styles = StyleSheet.create({
  container: {
    marginLeft: -16,
    marginBottom: 12,
    paddingHorizontal: 16,
    paddingVertical: 14,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.colors.blue400,

    borderTopEndRadius: 8,
    borderBottomEndRadius: 8,
  },
  option: {
    color: theme.colors.white,
    fontFamily: theme.fonts.menu,
    fontSize: 16,
  },
  active: {
    backgroundColor: theme.colors.blue200,
  },
  svg: {
    marginRight: 8,
    fontSize: 16,
    color: theme.colors.white,
    alignSelf: 'center',
  },
});
