import { StyleSheet } from 'react-native';
import { theme } from '../../../global/styles/theme';

export const styles = StyleSheet.create({
  container: {
    width: 300,
    maxWidth: '100%',
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',

    borderRadius: 4,
    borderWidth: 1,
    borderBottomWidth: 5,
  },
  text: {
    marginLeft: 8,
    color: 'white',
    fontSize: 16,
    fontFamily: theme.fonts.roboto400,
  },
});
