import React, { useState, useEffect } from 'react';

import { buscarTermoDeUso } from '../../../../useCases/TermoDeUso/BuscarTermoDeUso';

import { useToast } from '../../../../hooks/useToast';

import { Modal } from '../../../Common/Modal';
import { Loading } from '../../../Common/Loading';


interface IModalTermos {
  isOpen: boolean;
  onClose: () => void;
}

export function ModalTermos({ isOpen, onClose }: IModalTermos) {
  const { errorToast } = useToast();

  const [termos, setTermos] = useState('');
  const [loading, setLoading] = useState(true);

  async function getTermos() {
    try {
      const termo = await buscarTermoDeUso.execute();

      //setTermos(termo);
      setLoading(false);
    } catch (err) {
      //setTermos(html);
      setLoading(false);
      //errorToast('Carregado termos mockados');
    }
  }

  useEffect(() => {
    if (isOpen && termos === '') getTermos();
  }, [isOpen]);
  return (
    <Modal
      isModalOpen={isOpen}
      setIsModalOpen={onClose}
      transparente
      header="Termos e condições de uso"
    >
      {loading ? (
        <Loading />
      ) : (
        <div
          dangerouslySetInnerHTML={{ __html: `<html xmlns:v="urn:schemas-microsoft-com:vml"
          xmlns:o="urn:schemas-microsoft-com:office:office"
          xmlns:w="urn:schemas-microsoft-com:office:word"
          xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
          xmlns="http://www.w3.org/TR/REC-html40">
          
          <head>
          <meta http-equiv=Content-Type content="text/html; charset=windows-1252">
          <meta name=ProgId content=Word.Document>
          <meta name=Generator content="Microsoft Word 15">
          <meta name=Originator content="Microsoft Word 15">
          <link rel=File-List
          href="TERMOS%20E%20CONDIÇÕES%20DE%20USO%20APP%20CLARITO%20(002)_arquivos/filelist.xml">
          <link rel=Edit-Time-Data
          href="TERMOS%20E%20CONDIÇÕES%20DE%20USO%20APP%20CLARITO%20(002)_arquivos/editdata.mso">
          <!--[if !mso]>
          <style>
          v\:* {behavior:url(#default#VML);}
          o\:* {behavior:url(#default#VML);}
          w\:* {behavior:url(#default#VML);}
          .shape {behavior:url(#default#VML);}
          </style>
          <![endif]--><!--[if gte mso 9]><xml>
           <o:DocumentProperties>
            <o:Author>Cibele Buono</o:Author>
            <o:Template>Normal</o:Template>
            <o:LastAuthor>Nilson da Cunha de Jesus</o:LastAuthor>
            <o:Revision>2</o:Revision>
            <o:TotalTime>920</o:TotalTime>
            <o:LastPrinted>2023-08-24T12:25:00Z</o:LastPrinted>
            <o:Created>2023-08-24T12:42:00Z</o:Created>
            <o:LastSaved>2023-08-24T12:42:00Z</o:LastSaved>
            <o:Pages>7</o:Pages>
            <o:Words>2995</o:Words>
            <o:Characters>16173</o:Characters>
            <o:Lines>134</o:Lines>
            <o:Paragraphs>38</o:Paragraphs>
            <o:CharactersWithSpaces>19130</o:CharactersWithSpaces>
            <o:Version>16.00</o:Version>
           </o:DocumentProperties>
           <o:OfficeDocumentSettings>
            <o:AllowPNG/>
           </o:OfficeDocumentSettings>
          </xml><![endif]-->
          <link rel=themeData
          href="TERMOS%20E%20CONDIÇÕES%20DE%20USO%20APP%20CLARITO%20(002)_arquivos/themedata.thmx">
          <link rel=colorSchemeMapping
          href="TERMOS%20E%20CONDIÇÕES%20DE%20USO%20APP%20CLARITO%20(002)_arquivos/colorschememapping.xml">
          <!--[if gte mso 9]><xml>
           <w:WordDocument>
            <w:SpellingState>Clean</w:SpellingState>
            <w:GrammarState>Clean</w:GrammarState>
            <w:TrackMoves>false</w:TrackMoves>
            <w:TrackFormatting/>
            <w:HyphenationZone>21</w:HyphenationZone>
            <w:PunctuationKerning/>
            <w:ValidateAgainstSchemas/>
            <w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid>
            <w:IgnoreMixedContent>false</w:IgnoreMixedContent>
            <w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText>
            <w:DoNotPromoteQF/>
            <w:LidThemeOther>PT-BR</w:LidThemeOther>
            <w:LidThemeAsian>X-NONE</w:LidThemeAsian>
            <w:LidThemeComplexScript>X-NONE</w:LidThemeComplexScript>
            <w:Compatibility>
             <w:BreakWrappedTables/>
             <w:SnapToGridInCell/>
             <w:WrapTextWithPunct/>
             <w:UseAsianBreakRules/>
             <w:DontGrowAutofit/>
             <w:SplitPgBreakAndParaMark/>
             <w:EnableOpenTypeKerning/>
             <w:DontFlipMirrorIndents/>
             <w:OverrideTableStyleHps/>
            </w:Compatibility>
            <m:mathPr>
             <m:mathFont m:val="Cambria Math"/>
             <m:brkBin m:val="before"/>
             <m:brkBinSub m:val="&#45;-"/>
             <m:smallFrac m:val="off"/>
             <m:dispDef/>
             <m:lMargin m:val="0"/>
             <m:rMargin m:val="0"/>
             <m:defJc m:val="centerGroup"/>
             <m:wrapIndent m:val="1440"/>
             <m:intLim m:val="subSup"/>
             <m:naryLim m:val="undOvr"/>
            </m:mathPr></w:WordDocument>
          </xml><![endif]--><!--[if gte mso 9]><xml>
           <w:LatentStyles DefLockedState="false" DefUnhideWhenUsed="false"
            DefSemiHidden="false" DefQFormat="false" DefPriority="99"
            LatentStyleCount="376">
            <w:LsdException Locked="false" Priority="0" QFormat="true" Name="Normal"/>
            <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 1"/>
            <w:LsdException Locked="false" Priority="9" SemiHidden="true"
             UnhideWhenUsed="true" QFormat="true" Name="heading 2"/>
            <w:LsdException Locked="false" Priority="9" SemiHidden="true"
             UnhideWhenUsed="true" QFormat="true" Name="heading 3"/>
            <w:LsdException Locked="false" Priority="9" SemiHidden="true"
             UnhideWhenUsed="true" QFormat="true" Name="heading 4"/>
            <w:LsdException Locked="false" Priority="9" SemiHidden="true"
             UnhideWhenUsed="true" QFormat="true" Name="heading 5"/>
            <w:LsdException Locked="false" Priority="9" SemiHidden="true"
             UnhideWhenUsed="true" QFormat="true" Name="heading 6"/>
            <w:LsdException Locked="false" Priority="9" SemiHidden="true"
             UnhideWhenUsed="true" QFormat="true" Name="heading 7"/>
            <w:LsdException Locked="false" Priority="9" SemiHidden="true"
             UnhideWhenUsed="true" QFormat="true" Name="heading 8"/>
            <w:LsdException Locked="false" Priority="9" SemiHidden="true"
             UnhideWhenUsed="true" QFormat="true" Name="heading 9"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="index 1"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="index 2"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="index 3"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="index 4"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="index 5"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="index 6"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="index 7"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="index 8"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="index 9"/>
            <w:LsdException Locked="false" Priority="39" SemiHidden="true"
             UnhideWhenUsed="true" Name="toc 1"/>
            <w:LsdException Locked="false" Priority="39" SemiHidden="true"
             UnhideWhenUsed="true" Name="toc 2"/>
            <w:LsdException Locked="false" Priority="39" SemiHidden="true"
             UnhideWhenUsed="true" Name="toc 3"/>
            <w:LsdException Locked="false" Priority="39" SemiHidden="true"
             UnhideWhenUsed="true" Name="toc 4"/>
            <w:LsdException Locked="false" Priority="39" SemiHidden="true"
             UnhideWhenUsed="true" Name="toc 5"/>
            <w:LsdException Locked="false" Priority="39" SemiHidden="true"
             UnhideWhenUsed="true" Name="toc 6"/>
            <w:LsdException Locked="false" Priority="39" SemiHidden="true"
             UnhideWhenUsed="true" Name="toc 7"/>
            <w:LsdException Locked="false" Priority="39" SemiHidden="true"
             UnhideWhenUsed="true" Name="toc 8"/>
            <w:LsdException Locked="false" Priority="39" SemiHidden="true"
             UnhideWhenUsed="true" Name="toc 9"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Normal Indent"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="footnote text"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="annotation text"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="header"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="footer"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="index heading"/>
            <w:LsdException Locked="false" Priority="35" SemiHidden="true"
             UnhideWhenUsed="true" QFormat="true" Name="caption"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="table of figures"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="envelope address"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="envelope return"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="footnote reference"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="annotation reference"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="line number"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="page number"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="endnote reference"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="endnote text"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="table of authorities"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="macro"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="toa heading"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="List"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="List Bullet"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="List Number"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="List 2"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="List 3"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="List 4"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="List 5"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="List Bullet 2"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="List Bullet 3"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="List Bullet 4"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="List Bullet 5"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="List Number 2"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="List Number 3"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="List Number 4"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="List Number 5"/>
            <w:LsdException Locked="false" Priority="10" QFormat="true" Name="Title"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Closing"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Signature"/>
            <w:LsdException Locked="false" Priority="1" SemiHidden="true"
             UnhideWhenUsed="true" Name="Default Paragraph Font"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Body Text"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Body Text Indent"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="List Continue"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="List Continue 2"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="List Continue 3"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="List Continue 4"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="List Continue 5"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Message Header"/>
            <w:LsdException Locked="false" Priority="11" QFormat="true" Name="Subtitle"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Salutation"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Date"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Body Text First Indent"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Body Text First Indent 2"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Note Heading"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Body Text 2"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Body Text 3"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Body Text Indent 2"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Body Text Indent 3"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Block Text"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Hyperlink"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="FollowedHyperlink"/>
            <w:LsdException Locked="false" Priority="22" QFormat="true" Name="Strong"/>
            <w:LsdException Locked="false" Priority="20" QFormat="true" Name="Emphasis"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Document Map"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Plain Text"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="E-mail Signature"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="HTML Top of Form"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="HTML Bottom of Form"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Normal (Web)"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="HTML Acronym"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="HTML Address"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="HTML Cite"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="HTML Code"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="HTML Definition"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="HTML Keyboard"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="HTML Preformatted"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="HTML Sample"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="HTML Typewriter"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="HTML Variable"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Normal Table"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="annotation subject"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="No List"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Outline List 1"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Outline List 2"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Outline List 3"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Simple 1"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Simple 2"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Simple 3"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Classic 1"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Classic 2"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Classic 3"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Classic 4"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Colorful 1"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Colorful 2"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Colorful 3"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Columns 1"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Columns 2"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Columns 3"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Columns 4"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Columns 5"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Grid 1"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Grid 2"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Grid 3"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Grid 4"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Grid 5"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Grid 6"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Grid 7"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Grid 8"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table List 1"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table List 2"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table List 3"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table List 4"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table List 5"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table List 6"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table List 7"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table List 8"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table 3D effects 1"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table 3D effects 2"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table 3D effects 3"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Contemporary"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Elegant"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Professional"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Subtle 1"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Subtle 2"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Web 1"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Web 2"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Web 3"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Balloon Text"/>
            <w:LsdException Locked="false" Priority="39" Name="Table Grid"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Table Theme"/>
            <w:LsdException Locked="false" SemiHidden="true" Name="Placeholder Text"/>
            <w:LsdException Locked="false" Priority="1" QFormat="true" Name="No Spacing"/>
            <w:LsdException Locked="false" Priority="60" Name="Light Shading"/>
            <w:LsdException Locked="false" Priority="61" Name="Light List"/>
            <w:LsdException Locked="false" Priority="62" Name="Light Grid"/>
            <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1"/>
            <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2"/>
            <w:LsdException Locked="false" Priority="65" Name="Medium List 1"/>
            <w:LsdException Locked="false" Priority="66" Name="Medium List 2"/>
            <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1"/>
            <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2"/>
            <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3"/>
            <w:LsdException Locked="false" Priority="70" Name="Dark List"/>
            <w:LsdException Locked="false" Priority="71" Name="Colorful Shading"/>
            <w:LsdException Locked="false" Priority="72" Name="Colorful List"/>
            <w:LsdException Locked="false" Priority="73" Name="Colorful Grid"/>
            <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 1"/>
            <w:LsdException Locked="false" Priority="61" Name="Light List Accent 1"/>
            <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 1"/>
            <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 1"/>
            <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 1"/>
            <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 1"/>
            <w:LsdException Locked="false" SemiHidden="true" Name="Revision"/>
            <w:LsdException Locked="false" Priority="34" QFormat="true"
             Name="List Paragraph"/>
            <w:LsdException Locked="false" Priority="29" QFormat="true" Name="Quote"/>
            <w:LsdException Locked="false" Priority="30" QFormat="true"
             Name="Intense Quote"/>
            <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 1"/>
            <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 1"/>
            <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 1"/>
            <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 1"/>
            <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 1"/>
            <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 1"/>
            <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 1"/>
            <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 1"/>
            <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 2"/>
            <w:LsdException Locked="false" Priority="61" Name="Light List Accent 2"/>
            <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 2"/>
            <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 2"/>
            <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 2"/>
            <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 2"/>
            <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 2"/>
            <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 2"/>
            <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 2"/>
            <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 2"/>
            <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 2"/>
            <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 2"/>
            <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 2"/>
            <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 2"/>
            <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 3"/>
            <w:LsdException Locked="false" Priority="61" Name="Light List Accent 3"/>
            <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 3"/>
            <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 3"/>
            <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 3"/>
            <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 3"/>
            <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 3"/>
            <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 3"/>
            <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 3"/>
            <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 3"/>
            <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 3"/>
            <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 3"/>
            <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 3"/>
            <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 3"/>
            <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 4"/>
            <w:LsdException Locked="false" Priority="61" Name="Light List Accent 4"/>
            <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 4"/>
            <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 4"/>
            <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 4"/>
            <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 4"/>
            <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 4"/>
            <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 4"/>
            <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 4"/>
            <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 4"/>
            <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 4"/>
            <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 4"/>
            <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 4"/>
            <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 4"/>
            <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 5"/>
            <w:LsdException Locked="false" Priority="61" Name="Light List Accent 5"/>
            <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 5"/>
            <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 5"/>
            <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 5"/>
            <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 5"/>
            <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 5"/>
            <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 5"/>
            <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 5"/>
            <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 5"/>
            <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 5"/>
            <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 5"/>
            <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 5"/>
            <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 5"/>
            <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 6"/>
            <w:LsdException Locked="false" Priority="61" Name="Light List Accent 6"/>
            <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 6"/>
            <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 6"/>
            <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 6"/>
            <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 6"/>
            <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 6"/>
            <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 6"/>
            <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 6"/>
            <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 6"/>
            <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 6"/>
            <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 6"/>
            <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 6"/>
            <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 6"/>
            <w:LsdException Locked="false" Priority="19" QFormat="true"
             Name="Subtle Emphasis"/>
            <w:LsdException Locked="false" Priority="21" QFormat="true"
             Name="Intense Emphasis"/>
            <w:LsdException Locked="false" Priority="31" QFormat="true"
             Name="Subtle Reference"/>
            <w:LsdException Locked="false" Priority="32" QFormat="true"
             Name="Intense Reference"/>
            <w:LsdException Locked="false" Priority="33" QFormat="true" Name="Book Title"/>
            <w:LsdException Locked="false" Priority="37" SemiHidden="true"
             UnhideWhenUsed="true" Name="Bibliography"/>
            <w:LsdException Locked="false" Priority="39" SemiHidden="true"
             UnhideWhenUsed="true" QFormat="true" Name="TOC Heading"/>
            <w:LsdException Locked="false" Priority="41" Name="Plain Table 1"/>
            <w:LsdException Locked="false" Priority="42" Name="Plain Table 2"/>
            <w:LsdException Locked="false" Priority="43" Name="Plain Table 3"/>
            <w:LsdException Locked="false" Priority="44" Name="Plain Table 4"/>
            <w:LsdException Locked="false" Priority="45" Name="Plain Table 5"/>
            <w:LsdException Locked="false" Priority="40" Name="Grid Table Light"/>
            <w:LsdException Locked="false" Priority="46" Name="Grid Table 1 Light"/>
            <w:LsdException Locked="false" Priority="47" Name="Grid Table 2"/>
            <w:LsdException Locked="false" Priority="48" Name="Grid Table 3"/>
            <w:LsdException Locked="false" Priority="49" Name="Grid Table 4"/>
            <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark"/>
            <w:LsdException Locked="false" Priority="51" Name="Grid Table 6 Colorful"/>
            <w:LsdException Locked="false" Priority="52" Name="Grid Table 7 Colorful"/>
            <w:LsdException Locked="false" Priority="46"
             Name="Grid Table 1 Light Accent 1"/>
            <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 1"/>
            <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 1"/>
            <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 1"/>
            <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 1"/>
            <w:LsdException Locked="false" Priority="51"
             Name="Grid Table 6 Colorful Accent 1"/>
            <w:LsdException Locked="false" Priority="52"
             Name="Grid Table 7 Colorful Accent 1"/>
            <w:LsdException Locked="false" Priority="46"
             Name="Grid Table 1 Light Accent 2"/>
            <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 2"/>
            <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 2"/>
            <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 2"/>
            <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 2"/>
            <w:LsdException Locked="false" Priority="51"
             Name="Grid Table 6 Colorful Accent 2"/>
            <w:LsdException Locked="false" Priority="52"
             Name="Grid Table 7 Colorful Accent 2"/>
            <w:LsdException Locked="false" Priority="46"
             Name="Grid Table 1 Light Accent 3"/>
            <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 3"/>
            <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 3"/>
            <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 3"/>
            <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 3"/>
            <w:LsdException Locked="false" Priority="51"
             Name="Grid Table 6 Colorful Accent 3"/>
            <w:LsdException Locked="false" Priority="52"
             Name="Grid Table 7 Colorful Accent 3"/>
            <w:LsdException Locked="false" Priority="46"
             Name="Grid Table 1 Light Accent 4"/>
            <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 4"/>
            <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 4"/>
            <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 4"/>
            <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 4"/>
            <w:LsdException Locked="false" Priority="51"
             Name="Grid Table 6 Colorful Accent 4"/>
            <w:LsdException Locked="false" Priority="52"
             Name="Grid Table 7 Colorful Accent 4"/>
            <w:LsdException Locked="false" Priority="46"
             Name="Grid Table 1 Light Accent 5"/>
            <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 5"/>
            <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 5"/>
            <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 5"/>
            <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 5"/>
            <w:LsdException Locked="false" Priority="51"
             Name="Grid Table 6 Colorful Accent 5"/>
            <w:LsdException Locked="false" Priority="52"
             Name="Grid Table 7 Colorful Accent 5"/>
            <w:LsdException Locked="false" Priority="46"
             Name="Grid Table 1 Light Accent 6"/>
            <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 6"/>
            <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 6"/>
            <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 6"/>
            <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 6"/>
            <w:LsdException Locked="false" Priority="51"
             Name="Grid Table 6 Colorful Accent 6"/>
            <w:LsdException Locked="false" Priority="52"
             Name="Grid Table 7 Colorful Accent 6"/>
            <w:LsdException Locked="false" Priority="46" Name="List Table 1 Light"/>
            <w:LsdException Locked="false" Priority="47" Name="List Table 2"/>
            <w:LsdException Locked="false" Priority="48" Name="List Table 3"/>
            <w:LsdException Locked="false" Priority="49" Name="List Table 4"/>
            <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark"/>
            <w:LsdException Locked="false" Priority="51" Name="List Table 6 Colorful"/>
            <w:LsdException Locked="false" Priority="52" Name="List Table 7 Colorful"/>
            <w:LsdException Locked="false" Priority="46"
             Name="List Table 1 Light Accent 1"/>
            <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 1"/>
            <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 1"/>
            <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 1"/>
            <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 1"/>
            <w:LsdException Locked="false" Priority="51"
             Name="List Table 6 Colorful Accent 1"/>
            <w:LsdException Locked="false" Priority="52"
             Name="List Table 7 Colorful Accent 1"/>
            <w:LsdException Locked="false" Priority="46"
             Name="List Table 1 Light Accent 2"/>
            <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 2"/>
            <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 2"/>
            <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 2"/>
            <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 2"/>
            <w:LsdException Locked="false" Priority="51"
             Name="List Table 6 Colorful Accent 2"/>
            <w:LsdException Locked="false" Priority="52"
             Name="List Table 7 Colorful Accent 2"/>
            <w:LsdException Locked="false" Priority="46"
             Name="List Table 1 Light Accent 3"/>
            <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 3"/>
            <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 3"/>
            <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 3"/>
            <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 3"/>
            <w:LsdException Locked="false" Priority="51"
             Name="List Table 6 Colorful Accent 3"/>
            <w:LsdException Locked="false" Priority="52"
             Name="List Table 7 Colorful Accent 3"/>
            <w:LsdException Locked="false" Priority="46"
             Name="List Table 1 Light Accent 4"/>
            <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 4"/>
            <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 4"/>
            <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 4"/>
            <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 4"/>
            <w:LsdException Locked="false" Priority="51"
             Name="List Table 6 Colorful Accent 4"/>
            <w:LsdException Locked="false" Priority="52"
             Name="List Table 7 Colorful Accent 4"/>
            <w:LsdException Locked="false" Priority="46"
             Name="List Table 1 Light Accent 5"/>
            <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 5"/>
            <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 5"/>
            <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 5"/>
            <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 5"/>
            <w:LsdException Locked="false" Priority="51"
             Name="List Table 6 Colorful Accent 5"/>
            <w:LsdException Locked="false" Priority="52"
             Name="List Table 7 Colorful Accent 5"/>
            <w:LsdException Locked="false" Priority="46"
             Name="List Table 1 Light Accent 6"/>
            <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 6"/>
            <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 6"/>
            <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 6"/>
            <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 6"/>
            <w:LsdException Locked="false" Priority="51"
             Name="List Table 6 Colorful Accent 6"/>
            <w:LsdException Locked="false" Priority="52"
             Name="List Table 7 Colorful Accent 6"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Mention"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Smart Hyperlink"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Hashtag"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Unresolved Mention"/>
            <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
             Name="Smart Link"/>
           </w:LatentStyles>
          </xml><![endif]-->
          <style>
          <!--
           /* Font Definitions */
           @font-face
            {font-family:Wingdings;
            panose-1:5 0 0 0 0 0 0 0 0 0;
            mso-font-charset:2;
            mso-generic-font-family:auto;
            mso-font-pitch:variable;
            mso-font-signature:0 268435456 0 0 -2147483648 0;}
          @font-face
            {font-family:"Cambria Math";
            panose-1:2 4 5 3 5 4 6 3 2 4;
            mso-font-charset:0;
            mso-generic-font-family:roman;
            mso-font-pitch:variable;
            mso-font-signature:-536869121 1107305727 33554432 0 415 0;}
          @font-face
            {font-family:Calibri;
            panose-1:2 15 5 2 2 2 4 3 2 4;
            mso-font-charset:0;
            mso-generic-font-family:swiss;
            mso-font-pitch:variable;
            mso-font-signature:-469750017 -1040178053 9 0 511 0;}
           /* Style Definitions */
           p.MsoNormal, li.MsoNormal, div.MsoNormal
            {mso-style-unhide:no;
            mso-style-qformat:yes;
            mso-style-parent:"";
            margin-top:0cm;
            margin-right:0cm;
            margin-bottom:8.0pt;
            margin-left:0cm;
            line-height:107%;
            mso-pagination:widow-orphan;
            font-size:11.0pt;
            font-family:"Calibri",sans-serif;
            mso-ascii-font-family:Calibri;
            mso-ascii-theme-font:minor-latin;
            mso-fareast-font-family:Calibri;
            mso-fareast-theme-font:minor-latin;
            mso-hansi-font-family:Calibri;
            mso-hansi-theme-font:minor-latin;
            mso-bidi-font-family:"Times New Roman";
            mso-bidi-theme-font:minor-bidi;
            mso-fareast-language:EN-US;}
          h2
            {mso-style-priority:9;
            mso-style-unhide:no;
            mso-style-qformat:yes;
            mso-style-link:"Título 2 Char";
            mso-margin-top-alt:auto;
            margin-right:0cm;
            mso-margin-bottom-alt:auto;
            margin-left:0cm;
            mso-pagination:widow-orphan;
            mso-outline-level:2;
            font-size:18.0pt;
            font-family:"Times New Roman",serif;
            mso-fareast-font-family:"Times New Roman";
            font-weight:bold;}
          h3
            {mso-style-priority:9;
            mso-style-unhide:no;
            mso-style-qformat:yes;
            mso-style-link:"Título 3 Char";
            mso-margin-top-alt:auto;
            margin-right:0cm;
            mso-margin-bottom-alt:auto;
            margin-left:0cm;
            mso-pagination:widow-orphan;
            mso-outline-level:3;
            font-size:13.5pt;
            font-family:"Times New Roman",serif;
            mso-fareast-font-family:"Times New Roman";
            font-weight:bold;}
          a:link, span.MsoHyperlink
            {mso-style-noshow:yes;
            mso-style-priority:99;
            color:blue;
            text-decoration:underline;
            text-underline:single;}
          a:visited, span.MsoHyperlinkFollowed
            {mso-style-noshow:yes;
            mso-style-priority:99;
            color:#954F72;
            mso-themecolor:followedhyperlink;
            text-decoration:underline;
            text-underline:single;}
          p
            {mso-style-noshow:yes;
            mso-style-priority:99;
            mso-margin-top-alt:auto;
            margin-right:0cm;
            mso-margin-bottom-alt:auto;
            margin-left:0cm;
            mso-pagination:widow-orphan;
            font-size:12.0pt;
            font-family:"Times New Roman",serif;
            mso-fareast-font-family:"Times New Roman";}
          p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
            {mso-style-priority:34;
            mso-style-unhide:no;
            mso-style-qformat:yes;
            margin-top:0cm;
            margin-right:0cm;
            margin-bottom:8.0pt;
            margin-left:36.0pt;
            mso-add-space:auto;
            line-height:107%;
            mso-pagination:widow-orphan;
            font-size:11.0pt;
            font-family:"Calibri",sans-serif;
            mso-ascii-font-family:Calibri;
            mso-ascii-theme-font:minor-latin;
            mso-fareast-font-family:Calibri;
            mso-fareast-theme-font:minor-latin;
            mso-hansi-font-family:Calibri;
            mso-hansi-theme-font:minor-latin;
            mso-bidi-font-family:"Times New Roman";
            mso-bidi-theme-font:minor-bidi;
            mso-fareast-language:EN-US;}
          p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
            {mso-style-priority:34;
            mso-style-unhide:no;
            mso-style-qformat:yes;
            mso-style-type:export-only;
            margin-top:0cm;
            margin-right:0cm;
            margin-bottom:0cm;
            margin-left:36.0pt;
            mso-add-space:auto;
            line-height:107%;
            mso-pagination:widow-orphan;
            font-size:11.0pt;
            font-family:"Calibri",sans-serif;
            mso-ascii-font-family:Calibri;
            mso-ascii-theme-font:minor-latin;
            mso-fareast-font-family:Calibri;
            mso-fareast-theme-font:minor-latin;
            mso-hansi-font-family:Calibri;
            mso-hansi-theme-font:minor-latin;
            mso-bidi-font-family:"Times New Roman";
            mso-bidi-theme-font:minor-bidi;
            mso-fareast-language:EN-US;}
          p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
            {mso-style-priority:34;
            mso-style-unhide:no;
            mso-style-qformat:yes;
            mso-style-type:export-only;
            margin-top:0cm;
            margin-right:0cm;
            margin-bottom:0cm;
            margin-left:36.0pt;
            mso-add-space:auto;
            line-height:107%;
            mso-pagination:widow-orphan;
            font-size:11.0pt;
            font-family:"Calibri",sans-serif;
            mso-ascii-font-family:Calibri;
            mso-ascii-theme-font:minor-latin;
            mso-fareast-font-family:Calibri;
            mso-fareast-theme-font:minor-latin;
            mso-hansi-font-family:Calibri;
            mso-hansi-theme-font:minor-latin;
            mso-bidi-font-family:"Times New Roman";
            mso-bidi-theme-font:minor-bidi;
            mso-fareast-language:EN-US;}
          p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
            {mso-style-priority:34;
            mso-style-unhide:no;
            mso-style-qformat:yes;
            mso-style-type:export-only;
            margin-top:0cm;
            margin-right:0cm;
            margin-bottom:8.0pt;
            margin-left:36.0pt;
            mso-add-space:auto;
            line-height:107%;
            mso-pagination:widow-orphan;
            font-size:11.0pt;
            font-family:"Calibri",sans-serif;
            mso-ascii-font-family:Calibri;
            mso-ascii-theme-font:minor-latin;
            mso-fareast-font-family:Calibri;
            mso-fareast-theme-font:minor-latin;
            mso-hansi-font-family:Calibri;
            mso-hansi-theme-font:minor-latin;
            mso-bidi-font-family:"Times New Roman";
            mso-bidi-theme-font:minor-bidi;
            mso-fareast-language:EN-US;}
          span.Ttulo2Char
            {mso-style-name:"Título 2 Char";
            mso-style-priority:9;
            mso-style-unhide:no;
            mso-style-locked:yes;
            mso-style-link:"Título 2";
            mso-ansi-font-size:18.0pt;
            mso-bidi-font-size:18.0pt;
            font-family:"Times New Roman",serif;
            mso-ascii-font-family:"Times New Roman";
            mso-fareast-font-family:"Times New Roman";
            mso-hansi-font-family:"Times New Roman";
            mso-bidi-font-family:"Times New Roman";
            mso-fareast-language:PT-BR;
            font-weight:bold;}
          span.Ttulo3Char
            {mso-style-name:"Título 3 Char";
            mso-style-priority:9;
            mso-style-unhide:no;
            mso-style-locked:yes;
            mso-style-link:"Título 3";
            mso-ansi-font-size:13.5pt;
            mso-bidi-font-size:13.5pt;
            font-family:"Times New Roman",serif;
            mso-ascii-font-family:"Times New Roman";
            mso-fareast-font-family:"Times New Roman";
            mso-hansi-font-family:"Times New Roman";
            mso-bidi-font-family:"Times New Roman";
            mso-fareast-language:PT-BR;
            font-weight:bold;}
          span.SpellE
            {mso-style-name:"";
            mso-spl-e:yes;}
          span.GramE
            {mso-style-name:"";
            mso-gram-e:yes;}
          .MsoChpDefault
            {mso-style-type:export-only;
            mso-default-props:yes;
            font-family:"Calibri",sans-serif;
            mso-ascii-font-family:Calibri;
            mso-ascii-theme-font:minor-latin;
            mso-fareast-font-family:Calibri;
            mso-fareast-theme-font:minor-latin;
            mso-hansi-font-family:Calibri;
            mso-hansi-theme-font:minor-latin;
            mso-bidi-font-family:"Times New Roman";
            mso-bidi-theme-font:minor-bidi;
            mso-font-kerning:0pt;
            mso-ligatures:none;
            mso-fareast-language:EN-US;}
          .MsoPapDefault
            {mso-style-type:export-only;
            margin-bottom:8.0pt;
            line-height:107%;}
          @page WordSection1
            {size:595.3pt 841.9pt;
            margin:70.85pt 3.0cm 70.85pt 3.0cm;
            mso-header-margin:35.4pt;
            mso-footer-margin:35.4pt;
            mso-paper-source:0;}
          div.WordSection1
            {page:WordSection1;}
           /* List Definitions */
           @list l0
            {mso-list-id:435373098;
            mso-list-template-ids:-1883452766;}
          @list l0:level1
            {mso-level-number-format:bullet;
            mso-level-text:\F0B7;
            mso-level-tab-stop:36.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;
            mso-ansi-font-size:10.0pt;
            font-family:Symbol;}
          @list l0:level2
            {mso-level-number-format:bullet;
            mso-level-text:\F0B7;
            mso-level-tab-stop:72.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;
            mso-ansi-font-size:10.0pt;
            font-family:Symbol;}
          @list l0:level3
            {mso-level-number-format:bullet;
            mso-level-text:\F0B7;
            mso-level-tab-stop:108.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;
            mso-ansi-font-size:10.0pt;
            font-family:Symbol;}
          @list l0:level4
            {mso-level-number-format:bullet;
            mso-level-text:\F0B7;
            mso-level-tab-stop:144.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;
            mso-ansi-font-size:10.0pt;
            font-family:Symbol;}
          @list l0:level5
            {mso-level-number-format:bullet;
            mso-level-text:\F0B7;
            mso-level-tab-stop:180.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;
            mso-ansi-font-size:10.0pt;
            font-family:Symbol;}
          @list l0:level6
            {mso-level-number-format:bullet;
            mso-level-text:\F0B7;
            mso-level-tab-stop:216.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;
            mso-ansi-font-size:10.0pt;
            font-family:Symbol;}
          @list l0:level7
            {mso-level-number-format:bullet;
            mso-level-text:\F0B7;
            mso-level-tab-stop:252.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;
            mso-ansi-font-size:10.0pt;
            font-family:Symbol;}
          @list l0:level8
            {mso-level-number-format:bullet;
            mso-level-text:\F0B7;
            mso-level-tab-stop:288.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;
            mso-ansi-font-size:10.0pt;
            font-family:Symbol;}
          @list l0:level9
            {mso-level-number-format:bullet;
            mso-level-text:\F0B7;
            mso-level-tab-stop:324.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;
            mso-ansi-font-size:10.0pt;
            font-family:Symbol;}
          @list l1
            {mso-list-id:653609629;
            mso-list-template-ids:962859162;}
          @list l1:level1
            {mso-level-number-format:bullet;
            mso-level-text:\F0B7;
            mso-level-tab-stop:36.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;
            mso-ansi-font-size:10.0pt;
            font-family:Symbol;}
          @list l1:level2
            {mso-level-number-format:bullet;
            mso-level-text:o;
            mso-level-tab-stop:72.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;
            mso-ansi-font-size:10.0pt;
            font-family:"Courier New";
            mso-bidi-font-family:"Times New Roman";}
          @list l1:level3
            {mso-level-number-format:bullet;
            mso-level-text:\F0A7;
            mso-level-tab-stop:108.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;
            mso-ansi-font-size:10.0pt;
            font-family:Wingdings;}
          @list l1:level4
            {mso-level-number-format:bullet;
            mso-level-text:\F0A7;
            mso-level-tab-stop:144.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;
            mso-ansi-font-size:10.0pt;
            font-family:Wingdings;}
          @list l1:level5
            {mso-level-number-format:bullet;
            mso-level-text:\F0A7;
            mso-level-tab-stop:180.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;
            mso-ansi-font-size:10.0pt;
            font-family:Wingdings;}
          @list l1:level6
            {mso-level-number-format:bullet;
            mso-level-text:\F0A7;
            mso-level-tab-stop:216.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;
            mso-ansi-font-size:10.0pt;
            font-family:Wingdings;}
          @list l1:level7
            {mso-level-number-format:bullet;
            mso-level-text:\F0A7;
            mso-level-tab-stop:252.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;
            mso-ansi-font-size:10.0pt;
            font-family:Wingdings;}
          @list l1:level8
            {mso-level-number-format:bullet;
            mso-level-text:\F0A7;
            mso-level-tab-stop:288.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;
            mso-ansi-font-size:10.0pt;
            font-family:Wingdings;}
          @list l1:level9
            {mso-level-number-format:bullet;
            mso-level-text:\F0A7;
            mso-level-tab-stop:324.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;
            mso-ansi-font-size:10.0pt;
            font-family:Wingdings;}
          @list l2
            {mso-list-id:1119371068;
            mso-list-template-ids:1254098906;}
          @list l2:level1
            {mso-level-number-format:alpha-upper;
            mso-level-tab-stop:36.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;}
          @list l2:level2
            {mso-level-number-format:alpha-upper;
            mso-level-tab-stop:72.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;}
          @list l2:level3
            {mso-level-number-format:alpha-upper;
            mso-level-tab-stop:108.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;}
          @list l2:level4
            {mso-level-number-format:alpha-upper;
            mso-level-tab-stop:144.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;}
          @list l2:level5
            {mso-level-number-format:alpha-upper;
            mso-level-tab-stop:180.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;}
          @list l2:level6
            {mso-level-number-format:alpha-upper;
            mso-level-tab-stop:216.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;}
          @list l2:level7
            {mso-level-number-format:alpha-upper;
            mso-level-tab-stop:252.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;}
          @list l2:level8
            {mso-level-number-format:alpha-upper;
            mso-level-tab-stop:288.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;}
          @list l2:level9
            {mso-level-number-format:alpha-upper;
            mso-level-tab-stop:324.0pt;
            mso-level-number-position:left;
            text-indent:-18.0pt;}
          ol
            {margin-bottom:0cm;}
          ul
            {margin-bottom:0cm;}
          -->
          </style>
          <!--[if gte mso 10]>
          <style>
           /* Style Definitions */
           table.MsoNormalTable
            {mso-style-name:"Tabela normal";
            mso-tstyle-rowband-size:0;
            mso-tstyle-colband-size:0;
            mso-style-noshow:yes;
            mso-style-priority:99;
            mso-style-parent:"";
            mso-padding-alt:0cm 5.4pt 0cm 5.4pt;
            mso-para-margin-top:0cm;
            mso-para-margin-right:0cm;
            mso-para-margin-bottom:8.0pt;
            mso-para-margin-left:0cm;
            line-height:107%;
            mso-pagination:widow-orphan;
            font-size:11.0pt;
            font-family:"Calibri",sans-serif;
            mso-ascii-font-family:Calibri;
            mso-ascii-theme-font:minor-latin;
            mso-hansi-font-family:Calibri;
            mso-hansi-theme-font:minor-latin;
            mso-bidi-font-family:"Times New Roman";
            mso-bidi-theme-font:minor-bidi;
            mso-fareast-language:EN-US;}
          </style>
          <![endif]--><!--[if gte mso 9]><xml>
           <o:shapedefaults v:ext="edit" spidmax="1026"/>
          </xml><![endif]--><!--[if gte mso 9]><xml>
           <o:shapelayout v:ext="edit">
            <o:idmap v:ext="edit" data="1"/>
           </o:shapelayout></xml><![endif]-->
          </head>
          
          <body lang=PT-BR link=blue vlink="#954F72" style="tab-interval:35.4pt;
          word-wrap:break-word">
          
          <div class=WordSection1>
          
          <p class=MsoNormal><b><span style="mso-bidi-font-family:Calibri;mso-bidi-theme-font:
          minor-latin">TERMOS E CONDIÇÕES DE USO APP CLARITO<o:p></o:p></span></b></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">Este Termo e Condições de Uso (“Termos de Uso”),
          descrevem os termos e as condições aplicáveis ao acesso e uso das
          funcionalidades do Aplicativo <span class=SpellE>Clarito</span> por você, profissional
          do ramo vidreiro e Usuário do Aplicativo <span class=SpellE>Clarito</span>.</span><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">O “Aceite” dos termos descritos a seguir é
          indispensável para a utilização do Aplicativo <span class=SpellE>Clarito</span>.
          Se você não concordar com os termos que se seguem, não poderá utilizar ou
          acessar as funcionalidade e vantagens do Aplicativo <span class=SpellE>Clarito</span>.</span><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
          text-align:justify;line-height:normal;mso-outline-level:2;background:white"><b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:black;mso-fareast-language:PT-BR">1.
          DEFINIÇÕES</span></b><b><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR"><o:p></o:p></span></b></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">1.1. Toda vez que houver menção aos termos “Aplicativo
          <span class=SpellE>Clarito</span>”, “nós” ou “nossos” estaremos nos referindo ao
          Aplicativo <span class=SpellE>Clarito</span>, bem como toda vez que houver
          menção aos termos “você”, “usuário”, “seu”, “sua”, estaremos nos referindo a
          você Usuário, que está consentindo com estes Termos de Uso para fazer uso e
          acesso ao Aplicativo.</span><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">1.2. Para efeitos do presente, as seguintes
          palavras e expressões terão os respectivos significados a eles atribuídos
          abaixo:<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR"><o:p>&nbsp;</o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><b><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">a) Aplicativo <span class=SpellE>Clarito</span>:&nbsp;</span></b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:black;mso-fareast-language:PT-BR">plataforma
          digital própria da <span class=SpellE>Clarity</span> Importação e Exportação de
          Vidros LTDA que habilita Usuários a acessarem os serviços de acompanhamento de
          pedidos, histórico de pedidos já realizados, acúmulo de pontos para resgate em
          valor proporcional em abatimento de novas compras (descontos), por meio de
          aplicativo digital.</span><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR"><o:p>&nbsp;</o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><b><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-color-alt:windowtext;mso-fareast-language:PT-BR">b) Parceiro(s) Comercial(<span
          class=SpellE>is</span>), Clube ou Clube <span class=SpellE>Clarity</span>:&nbsp;</span></b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:black;mso-fareast-language:PT-BR">distribuidores
          de vidros que vende(m) produtos diretamente ao Usuário e que, por estar(em)
          cadastrado(s) junto ao App <span class=SpellE>Clarito</span> e possuir(em) integração
          com seu sistema, se encontra(m) habilitado(s) para repassar o <span
          class=SpellE>cashback</span> das transações.<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR"><o:p>&nbsp;</o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><b><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">c) Usuário:</span></b><span style="mso-fareast-font-family:
          "Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
          color:black;mso-fareast-language:PT-BR">&nbsp;pessoa física, maior de 18 anos e
          capaz, ou pessoa jurídica regularmente constituída, com CNPJ válido e ativo,
          que aderiu a este Termo de Uso ao instalar o Aplicativo <span class=SpellE>Clarito</span>.</span><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR"><o:p>&nbsp;</o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><b><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">d) Partes:</span></b><span style="mso-fareast-font-family:
          "Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
          color:black;mso-fareast-language:PT-BR">&nbsp;significa o App <span
          class=SpellE>Clarito</span> e o Usuário que aderem a estes Termos de Uso.</span><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR"><o:p>&nbsp;</o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><b><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">e) <span class=SpellE>Cashback</span>:</span></b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:black;mso-fareast-language:PT-BR">&nbsp;programa
          de fidelidade, pelo qual o Usuário recebe de volta parte do valor pago ao
          parceiro comercial credenciado ao realizar uma aquisição de produtos <span
          class=SpellE>Clarity</span>.</span><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR"><o:p>&nbsp;</o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><b><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:red;
          mso-fareast-language:PT-BR">f) Canais de Atendimento:&nbsp;</span></b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:red;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <ul type=disc>
           <li class=MsoNormal style="color:red;mso-margin-top-alt:auto;mso-margin-bottom-alt:
               auto;text-align:justify;line-height:normal;mso-list:l0 level1 lfo1;
               tab-stops:list 36.0pt;background:white"><span style="mso-fareast-font-family:
               "Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
               mso-fareast-language:PT-BR">E-mail: appclarito@clarityglass.com.br<o:p></o:p></span></li>
           <li class=MsoNormal style="color:red;mso-margin-top-alt:auto;mso-margin-bottom-alt:
               auto;text-align:justify;line-height:normal;mso-list:l0 level1 lfo1;
               tab-stops:list 36.0pt;background:white"><span style="mso-fareast-font-family:
               "Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
               mso-fareast-language:PT-BR">Telefone: </span><span style="mso-bidi-font-family:
               Calibri;mso-bidi-theme-font:minor-latin;color:#010101;background:white">3203-4466
               </span><span style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:
               Calibri;mso-bidi-theme-font:minor-latin;mso-fareast-language:PT-BR">para
               todas as localidades <o:p></o:p></span></li>
          </ul>
          
          <p class=MsoNormal style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
          text-align:justify;line-height:normal;background:white"><span style="mso-fareast-font-family:
          "Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
          color:red;mso-fareast-language:PT-BR">,<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><b><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:red;
          mso-fareast-language:PT-BR">g) Política de Privacidade:</span></b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:red;mso-fareast-language:PT-BR">&nbsp;é a
          política de privacidade de dados do App <span class=SpellE>Clarito</span>,
          disponível no site:&nbsp;</span><span style="color:red">clarityglass.com.br</span><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:red;mso-fareast-language:PT-BR">/....<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR"><o:p>&nbsp;</o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><b><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">h) Meus <span class=SpellE>Claritos</span>:</span></b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:black;mso-fareast-language:PT-BR">&nbsp;é
          o saldo, ou seja, valor disponível no App <span class=SpellE>Clarito</span> do
          Usuário. O valor é a soma dos pontos adquiridos em forma de <span class=SpellE>cashback</span>
          - Parte do Valor da Compra de Volta. Valor que o Usuário poderá resgatar em
          forma de desconto na compra de vidros <span class=SpellE>Clarity</span>.</span><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><b><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">i) Resgate: </span></b><span style="mso-fareast-font-family:
          "Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
          color:black;mso-fareast-language:PT-BR">solicitação realizada expressamente
          pelo Usuário por meio de QR <span class=SpellE>Code</span> disponibilizado pelo
          Operador de Caixa de seu Clube <span class=SpellE>Clarity</span>, para ser
          contemplado com descontos no ato de uma nova compra de vidros <span
          class=SpellE>Clarity</span>.</span><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;</span><b><span style="mso-fareast-font-family:
          "Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
          color:black;mso-fareast-language:PT-BR">2. OBJETO</span></b><b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></b></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">2.1. O objeto destes Termos de Uso é estabelecer as
          condições aplicáveis à utilização das vantagens e benefícios dos serviços
          disponibilizados por meio do App <span class=SpellE>Clarito</span> ao Usuário.<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">2.2. Outras vantagens, benefícios e serviços
          poderão ser incluídos pelo App <span class=SpellE>Clarito</span> ao Usuário, de
          acordo com as condições estabelecidas nestes Termos de Uso, que serão disponibilizados
          conforme atualizações.</span><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
          text-align:justify;line-height:normal;mso-outline-level:2;background:white"><b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:black;mso-fareast-language:PT-BR">3. SERVIÇOS
          DISPONÍVEIS NO APLICATIVO CLARITO</span></b><b><span style="mso-fareast-font-family:
          "Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
          color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></b></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-color-alt:windowtext;mso-fareast-language:PT-BR">3.1. A <span class=SpellE>Clarity</span>
          Glass disponibilizará ao Usuário do Aplicativo <span class=SpellE>Clarito</span>,
          plataforma que contém ferramentas para acompanhamento de pedidos, ofertas de
          produtos de Parceiros Comerciais habilitados, <span class=SpellE>cashback</span>
          (descontos), incluindo, mas não se limitando a:</span><span style="mso-fareast-font-family:
          "Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
          mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><b><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">a)&nbsp;Extrato de <span class=SpellE>Claritos</span>:</span></b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:black;mso-fareast-language:PT-BR"> o
          Usuário poderá acessar extratos em tempo real dos valores disponíveis em sua
          conta, bem como controlar o resgate de pontos/valores reais na compra que
          desejar;</span><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><b><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">b)&nbsp;Meus Pedidos: </span></b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:black;mso-fareast-language:PT-BR">o
          Usuário terá acesso a todos os seus pedidos feitos através do E-commerce do
          Clube <span class=SpellE>Clarity</span> habilitado. Terá acesso ao nome do
          Clube <span class=SpellE>Clarity</span>, quantidade de peças, <span
          class=SpellE>Claritos</span> gerados em cada pedido, metragem total e peso do
          pedido. Poderá acompanhar o seu pedido nas seguintes etapas: Produzindo,
          Expedição, <span class=SpellE>Clarito</span> Disponível para Resgate e <span
          class=SpellE>Clarito</span> Resgatado</span><span style="mso-fareast-font-family:
          "Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
          color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><b><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">c)&nbsp;Acompanhamento de Pedido: <o:p></o:p></span></b></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">- Produzindo: o Usuário será avisado quando seu
          pedido entrar em produção na <span class=SpellE>Clarity</span> Glass;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">- Expedição: o Usuário será avisado quando seu
          pedido estiver pronto para retirada em seu Clube <span class=SpellE>Clarity</span>;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">- <span class=SpellE>Clarito</span> Disponível para
          Resgate: o Usuário será avisado quando seus pontos estiverem disponíveis para
          serem resgatados em forma de desconto real;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">- <span class=SpellE>Clarito</span> Resgatado: o
          Usuário será comunicado quando seus pontos já tiverem sido utilizados.<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR"><o:p>&nbsp;</o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><b><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">d) Resgatar <span class=SpellE>Claritos</span>: </span></b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:black;mso-fareast-language:PT-BR">o
          Usuário poderá solicitar o resgate dos pontos em forma de desconto real no ato
          de uma nova compra de vidros <span class=SpellE>Clarity</span> em um Clube <span
          class=SpellE>Clarity</span>.<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><b><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR"><o:p>&nbsp;</o:p></span></b></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><b><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">e) Notificações:</span></b><span style="mso-fareast-font-family:
          "Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
          color:black;mso-fareast-language:PT-BR"> o Usuário receberá todo tipo de
          comunicado nas “Notificações” do Aplicativo <span class=SpellE>Clarito</span>,
          comunicados, tais como: ofertas, promoções, alterações na Política de
          Privacidade ou deste Termo de Condições e Uso<b><o:p></o:p></b></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">3.2. O Usuário poderá utilizar os todos os recursos
          disponíveis em sua conta. O Aplicativo <span class=SpellE>Clarito</span> poderá
          permitir a oferta de novos produtos e serviços na Plataforma, sejam eles
          próprios ou de Parceiros Comerciais, cuja contratação e utilização pelo Usuário
          serão facultativas e sujeitas às condições específicas aplicáveis em cada caso,
          sem necessidade de atualização destes Termos de Uso.</span><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
          text-align:justify;line-height:normal;mso-outline-level:2;background:white"><b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:black;mso-fareast-language:PT-BR">4.
          CONDIÇÕES APLICÁVEIS AO ACESSO RESGATE DE CASHBACK&nbsp;</span></b><b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></b></p>
          
          <p class=MsoNormal style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
          text-align:justify;line-height:normal;mso-outline-level:3;background:white"><b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:black;mso-fareast-language:PT-BR">Cadastro</span></b><b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></b></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">4.1. O cadastro é efetivado pelo Usuário por meio
          do preenchimento das informações cadastrais solicitadas pelo Aplicativo <span
          class=SpellE>Clarito</span>.</span><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">4.2. O Usuário declara estar ciente de que os benefícios
          oferecidos por meio do Aplicativo <span class=SpellE>Clarity</span> têm como
          objetivo fidelizar os clientes dos Clube <span class=SpellE>Clarity</span>, e
          reconhece que não haverá a possibilidade de receber a quantia disponível para
          resgate em dinheiro, o valor está disponível apenas para ser abatido em
          desconto em novas compras de vidros <span class=SpellE>Clarity</span>.&nbsp;</span><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">4.3. A aceitação destes Termos de Uso é
          indispensável para usufruir dos benefícios oferecidos pelo Aplicativo <span
          class=SpellE>Clarito</span>. Cabe ao Usuário ler, entender e concordar com
          todas as condições estabelecidas neste documento antes de cadastrar-se como
          Usuário do Aplicativo <span class=SpellE>Clarito</span>.</span><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">4.4. Ao realizar a instalação do aplicativo <span
          class=SpellE>Clarito</span> em seu dispositivo móvel, inserir os dados
          requisitados no cadastro e aceitar eletronicamente estes Termos de Uso, o
          Usuário adere e concorda com os Termos de Uso do Aplicativo <span class=SpellE>Clarito</span>
          e com a </span><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:red;
          mso-fareast-language:PT-BR">Política de Privacidade</span><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:black;mso-fareast-language:PT-BR">. Para
          realização do cadastro junto ao Aplicativo <span class=SpellE>Clarito</span>, o
          Usuário deverá fornecer as seguintes informações que permitam sua
          identificação, especificamente:&nbsp;</span><span style="mso-fareast-font-family:
          "Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
          color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <ol start=1 type=A>
           <li class=MsoNormal style="color:red;mso-margin-top-alt:auto;mso-margin-bottom-alt:
               auto;text-align:justify;line-height:normal;mso-list:l2 level1 lfo2;
               tab-stops:list 36.0pt;background:white"><span style="mso-fareast-font-family:
               "Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
               mso-fareast-language:PT-BR">se pessoa física: nome completo; data de
               nascimento; número de inscrição no CPF; número do telefone celular e
               código de Discagem Direta a Distância (DDD); e-mail; endereço completo e, <o:p></o:p></span></li>
           <li class=MsoNormal style="color:red;mso-margin-top-alt:auto;mso-margin-bottom-alt:
               auto;text-align:justify;line-height:normal;mso-list:l2 level1 lfo2;
               tab-stops:list 36.0pt;background:white"><span style="mso-fareast-font-family:
               "Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
               mso-fareast-language:PT-BR">se pessoa jurídica: razão social; número de
               inscrição no CNPJ; nome completo, CPF, data de nascimento, número do
               telefone celular e e-mail do sócio ou representante que estiver realizando
               o cadastramento, endereço completo.<o:p></o:p></span></li>
          </ol>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">4.5. Os dados inseridos durante o cadastro deverão
          ser completos, com o preenchimento de todos os itens obrigatórios com
          informações precisas, idôneas, verdadeiras e exatas, sendo o Usuário o único
          responsável pelas informações fornecidas. Cabe ainda ao Usuário,
          obrigatoriamente, atualizá-las sempre que necessário, por meio dos Canais de
          Atendimento, sob pena de suspensão da movimentação da conta até sua
          regularização; e/ou encerramento da conta; </span><span style="mso-fareast-font-family:
          "Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
          color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">4.6. O Aplicativo <span class=SpellE>Clarito</span>
          se reserva o direito de, a qualquer momento e ao seu critério, solicitar cópias
          de documentos do Usuário, para verificar a veracidade dos dados informados, bem
          como para o cumprimento da regulamentação de cadastro de clientes. Entretanto,
          se os documentos solicitados não forem enviados, o Aplicativo <span
          class=SpellE>Clarito</span> poderá encerrar o cadastro; e zerar os <span
          class=SpellE>Claritos</span> (pontos) disponíveis.</span><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:red;
          mso-fareast-language:PT-BR">4.7. O Usuário receberá um e-mail de validação do
          seu cadastro no endereço informado. Em caso de não recebimento da referida
          mensagem eletrônica, o Usuário deverá entrar em contato conosco a fim de
          verificar a efetiva criação de seu cadastro.<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">4.8. O cadastro no Aplicativo <span class=SpellE>Clarito</span>
          é único, pessoal e intransferível. Da mesma forma, o número de telefone
          celular, e-mail e CPF ou CNPJ informados durante o cadastro somente poderão ser
          associados a um único Usuário. Em nenhuma hipótese, será aceito pelo Aplicativo
          <span class=SpellE>Clarito</span> o cadastro de novas contas de titularidade do
          mesmo Usuário.</span><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">4.9. O Aplicativo <span class=SpellE>Clarito</span>
          não se responsabiliza por falhas, erros, interrupções, maus funcionamentos,
          atrasos ou outras imperfeições que possam surgir, ainda que ocasionados pelo
          Aplicativo <span class=SpellE>Clarito</span> ou quaisquer de seus parceiros,
          não garantindo a prestação de serviço de forma ininterrupta, sem momentos de
          indisponibilidade ou lentidão. O Aplicativo <span class=SpellE>Clarito</span> considera
          que tais situações são previsíveis e possíveis de ocorrer em se tratando de
          serviços de tecnologia.</span><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">4.10. Os sistemas necessários para o funcionamento do
          Aplicativo <span class=SpellE>Clarito</span> podem eventualmente não estar
          disponíveis, na medida em que estes dependem de serviços prestados por
          terceiros, como empresas de telecomunicações e provedores de acesso à internet,
          e em casos de falhas técnicas ou problemas com a internet, interrupção ou queda
          do sistema por qualquer outra circunstância alheia ao Aplicativo <span
          class=SpellE>Clarito</span> ou a seus parceiros comerciais, o que pode
          invalidar ou cancelar o <span class=SpellE>cashback</span>/desconto em compra
          de vidros <span class=SpellE>Clarity</span> Além disso, suspenderemos o uso e
          acesso à Plataforma em caso fortuito ou força maior, por exemplo, em caso de
          ataques cibernéticos e outros que possam prejudicar as funcionalidades da
          Plataforma e colocar em risco as informações dos Usuários, ou até mesmo em
          razão de manutenções e melhorias na Plataforma. Os Usuários NÃO poderão
          responsabilizar o Aplicativo <span class=SpellE><span class=GramE>Clarito</span></span><span
          class=GramE> <span style="mso-spacerun:yes"> </span>ou</span> exigir
          ressarcimento algum, em virtude de prejuízos resultantes das dificuldades
          mencionadas, assim como por qualquer outro tipo de dano oriundo do disposto
          nesta cláusula.<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR"><o:p>&nbsp;</o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">4.11. O Aplicativo <span class=SpellE>Clarito</span>
          não se responsabiliza pelo recebimento de e-mails falsos e mensagens, enviados
          por terceiros sem qualquer relação direta com o Aplicativo <span class=SpellE>Clarito</span>,
          <span class=SpellE>Clarity</span> Glass e/ou empresas parceiras.</span><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">4.12. Ao aderir a estes Termos de Uso, o Usuário
          concorda que, para conceder as vantagens e benefícios, o Aplicativo <span
          class=SpellE>Clarito</span> exercerá a condição de mandatário, dando
          cumprimento às solicitações feitas pelo Usuário, desde que respeitadas as
          regras previstas nestes Termos de Uso.</span><span style="mso-fareast-font-family:
          "Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
          color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">4.13. O Usuário acessará as informações do
          Aplicativo <span class=SpellE>Clarito</span> pelo aplicativo eletrônico
          disponibilizado pela <span class=SpellE>Clarity</span> Glass. A disponibilidade
          de <span class=SpellE>Claritos</span> e do extrato das movimentações caracteriza-se
          como prestação de contas, para todos os fins legais.</span><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
          text-align:justify;line-height:normal;mso-outline-level:3;background:white"><b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:black;mso-fareast-language:PT-BR">Acesso,
          movimentação e manutenção do cadastro no Aplicativo <span class=SpellE>Clarito</span>&nbsp;</span></b><b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></b></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">4.14. O Usuário só será elegível à manutenção do
          cadastro no Aplicativo <span class=SpellE>Clarito</span> caso tenha 18 anos
          completos ou mais no momento do cadastro, além de capacidade legal para
          contratar, quando na condição de Pessoa Física. Se o Usuário estiver na
          condição de Pessoa Jurídica, só será elegível à manutenção do cadastro no
          Aplicativo <span class=SpellE>Clarito</span> desde que possua e informe CNPJ
          válido e ativo no momento do cadastro. Para efetuar o cadastro, o Usuário deve
          preencher as informações que permitam a sua identificação, descritas no item
          4.4 A e B.&nbsp;</span><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
          margin-left:50.0pt;text-align:justify;line-height:normal;background:white"><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">4.15. O Usuário, ao se cadastrar na Plataforma, tem
          automaticamente criada uma conta em seu nome, que poderá ser utilizada para resgate
          de <span class=SpellE>Claritos</span> e desconto em novas compras de vidros <span
          class=SpellE>Clarity</span> junto aos Parceiros Comerciais.</span><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
          text-align:justify;line-height:normal;mso-outline-level:2;background:white"><b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:black;mso-fareast-language:PT-BR">5.
          MEDIDAS DE SEGURANÇA E CREDENCIAIS DO USUÁRIO</span></b><b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></b></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">5.1. É de total responsabilidade do Usuário manter
          em sigilo suas senhas, sendo elas pessoais e intransferíveis. A senha é a única
          forma de acesso para sua conta no Aplicativo <span class=SpellE>Clarito</span>.
          Dessa maneira, o Aplicativo <span class=SpellE>Clarito</span> não será, em
          nenhum cenário, responsável por quaisquer prejuízos causados ao Usuário ou a
          terceiros pela divulgação e utilização indevida e não autorizada das senhas.</span><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:red;
          mso-fareast-language:PT-BR">5.2. O Usuário deverá comunicar imediatamente o
          Aplicativo <span class=SpellE>Clarito</span>, pelos Canais de Atendimento, se
          tiver ciência de fraude, perda, ou qualquer incidente de segurança que resulte
          no compartilhamento de suas credenciais e senha com ou ainda na hipótese de
          suspeita ou indícios de uso indevido da sua conta, para que o Aplicativo <span
          class=SpellE>Clarito</span> possa efetuar o bloqueio e emissão de uma nova
          senha para o Usuário.<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">5.3. Poderá ainda o Aplicativo <span class=SpellE>Clarito</span>
          bloquear a realização de novas Transações se:</span><span style="mso-fareast-font-family:
          "Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
          color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:red;
          mso-fareast-language:PT-BR">a) O Usuário digitar incorretamente sua senha de
          acesso acima do limite permitido;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">b) O Usuário comunicar incidente de segurança que
          resulte no compartilhamento de suas credenciais, conforme o item 5.2
          acima;&nbsp;</span><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-color-alt:windowtext;mso-fareast-language:PT-BR">5.5. O Aplicativo <span
          class=SpellE>Clarito</span> se reserva o direito de suspender, limitar,
          bloquear ou inativar os Serviços sobre os recursos constantes na Conta Ame ou
          aplicativo, por tempo indeterminado, não assistindo ao Usuário, por essa razão,
          qualquer tipo de indenização ou ressarcimento em caso de: violação aos termos
          destes Termos de Uso; suspeita de fraude; não fornecimento dos documentos
          solicitados; constatação de dados incorretos ou inverídicos fornecidos pelo
          Usuário; condutas praticadas com a finalidade de obter saldo de forma ilícita
          e/ou fraudulenta; </span><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:red;
          mso-fareast-language:PT-BR">5.6. Para utilização das ferramentas, o Usuário
          deverá possuir acesso à internet, sendo de total responsabilidade do Usuário a
          aquisição de equipamentos (computador,&nbsp;<i>smartphone</i>,&nbsp;<i>tablet</i>)
          e programas (navegador) necessários à navegação do aplicativo e o provimento
          adequado de todos os recursos da internet (como conexão), sem exceção, bem como
          manter o ambiente do computador,&nbsp;<i>smartphone</i>&nbsp;e/ou&nbsp;<i>tablet&nbsp;</i>seguros,
          com uso de ferramentas disponíveis como antivírus, atualizadas, de modo a
          contribuir na prevenção de riscos eletrônicos.<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:red;
          mso-fareast-language:PT-BR"><o:p>&nbsp;</o:p></span></p>
          
          <p class=MsoNormal style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
          text-align:justify;line-height:normal;mso-outline-level:2;background:white"><b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:black;mso-fareast-language:PT-BR">6.
          RETIRADA DE VALORES – RESGATE DE CLARITOS</span></b><b><span style="mso-fareast-font-family:
          "Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
          color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></b></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">6.1. Ao Resgatar seus <span class=SpellE>Claritos</span>
          em sua conta no Aplicativo <span class=SpellE>Clarito</span>, o Usuário opta
          por utilizar os valores respectivos em reais em uma compra de vidros <span
          class=SpellE>Clarity</span> em um parceiro Clube <span class=SpellE>Clarity</span>
          cadastrado.</span><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">6.2. Caso o valor de <span class=SpellE>Claritos</span>
          seja superior ao da compra realizada, o valor excedente não é passível de
          retorno ao Aplicativo <span class=SpellE>Clarito</span>, troco e nenhuma outra
          forma. </span><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
          text-align:justify;line-height:normal;mso-outline-level:2;background:white"><b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:black;mso-fareast-language:PT-BR">7. VALIDADE
          DOS PONTOS – CLARITOS<o:p></o:p></span></b></p>
          
          <p class=MsoNormal style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
          text-align:justify;line-height:normal;mso-outline-level:2;background:white"><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:red;mso-fareast-language:PT-BR">7.1. Os
          pontos terão validade de 1 ano após gerados e de 6 meses após resgatados.<o:p></o:p></span></p>
          
          <p class=MsoNormal style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
          text-align:justify;line-height:normal;mso-outline-level:2;background:white"><b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:black;mso-fareast-language:PT-BR">8.
          OBRIGAÇÕES DO APLICATIVO CLARITO</span></b><b><span style="mso-fareast-font-family:
          "Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
          color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></b></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">8.1. O Aplicativo <span class=SpellE>Clarito</span>
          exercerá a condição de mandatário ao dar cumprimento às Solicitações de
          Gerenciamento de Descontos e Benefícios nos exatos termos definidos pelo
          Usuário e de acordo com estes Termos de Uso, agindo por conta e ordem do
          Usuário.</span><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">8.2. O Aplicativo <span class=SpellE>Clarito</span>
          se esforçará para assegurar o cumprimento dos prazos destes Termos de Uso. Não
          obstante, o Usuário está ciente que, por determinados fatores externos que
          fujam ao controle do Aplicativo <span class=SpellE>Clarito</span>, poderão
          ocorrer atrasos, motivo pelo qual o Usuário exime o Aplicativo <span
          class=SpellE>Clarito</span> de toda e qualquer responsabilidade decorrente de
          eventuais inconvenientes ou prejuízos.</span><span style="mso-fareast-font-family:
          "Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
          color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">8.3. O Usuário reconhece que o Aplicativo <span
          class=SpellE>Clarito</span> dará destinação aos <span class=SpellE>Claritos</span>
          Resgatados conforme solicitação feita pelo Usuário, através da sua leitura do <span
          class=SpellE>QRCode</span> na Operação de Caixa do Clube <span class=SpellE>Clarity</span>
          parceiro.</span><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
          text-align:justify;line-height:normal;mso-outline-level:2;background:white"><b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:black;mso-fareast-language:PT-BR">9.
          VIGÊNCIA E ENCERRAMENTO DESTES TERMOS DE USO</span></b><b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></b></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">9.1. Este Termo de Uso tem prazo indeterminado de
          validade, com vigor a partir do Aceite do Usuário no momento de instalação do
          aplicativo eletrônico e posterior cadastro.</span><span style="mso-fareast-font-family:
          "Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
          color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">9.2. Este Termo de Uso será rescindido prontamente,
          mediante aviso ao Usuário, nas seguintes situações:&nbsp;</span><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><b><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">a)&nbsp;</span></b><span style="mso-fareast-font-family:
          "Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
          color:black;mso-fareast-language:PT-BR">Decretação de falência, regimes
          especiais de insolvência (tais como liquidação extrajudicial, intervenção,
          Regime Especial de Administração Temporária (RAET), dentre outros) a que
          estejam sujeitas as Partes ou insolvência civil de qualquer das Partes, ou
          ainda com o descumprimento de qualquer das obrigações estabelecidas neste Termo
          de Uso;&nbsp;</span><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><b><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">b)&nbsp;</span></b><span style="mso-fareast-font-family:
          "Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
          color:black;mso-fareast-language:PT-BR">Caso sejam verificadas operações fora
          do padrão de uso, e o Usuário deixe de atender pedido de envio de novos
          documentos para a comprovação da regularidade dessas operações;&nbsp;e</span><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><b><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">c)&nbsp;</span></b><span style="mso-fareast-font-family:
          "Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
          color:black;mso-fareast-language:PT-BR">Em caso de falecimento do Usuário; </span><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
          margin-left:35.45pt;text-align:justify;line-height:normal;background:white"><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p>&nbsp;</o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">9.3. Em qualquer hipótese de cancelamento da Conta
          Ame, seja por solicitação unilateral do Usuário ou por motivação do Aplicativo <span
          class=SpellE>Clarito</span>, os <span class=SpellE>Claritos</span>, valor
          disponível como Parte do Valor da Compra de Volta será excluído, não havendo
          possibilidade de utilização do referido saldo ou reativação do mesmo após a
          conclusão do encerramento da Conta.</span><span style="mso-fareast-font-family:
          "Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
          color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">9.4. O Aplicativo <span class=SpellE>Clarito</span>
          poderá alterar estes Termos de Uso a qualquer momento, mediante notificação de
          mudanças ao Usuário e publicação de uma versão atualizada destes Termos de Uso,
          explicitando a data da última modificação, por meio de comunicação enviada ao
          endereço de e-mail cadastrado do Usuário. O uso dos benefícios e vantagens da
          conta pelo Usuário, após a entrada em vigor de alguma alteração, constituirá
          sua aceitação de tais alterações ou modificações, para quaisquer fins.</span><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
          text-align:justify;line-height:normal;mso-outline-level:2;background:white"><b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:black;mso-fareast-language:PT-BR">10.
          CONDIÇÕES GERAIS</span></b><b><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR"><o:p></o:p></span></b></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">18.1. As Partes reconhecem que qualquer condição
          presente nestes Termos de Uso não implica na caracterização do Aplicativo <span
          class=SpellE>Clarito</span> como uma instituição financeira, nos termos da
          legislação vigente. O Aplicativo <span class=SpellE>Clarito</span> não presta
          nenhum serviço bancário ou de câmbio ao Usuário, e tampouco qualquer outra
          atividade privativa de Instituições Financeiras ou seguradoras reguladas ou
          fiscalizadas pelo Banco Central do Brasil, Comissão de Valores Mobiliários
          (CVM) ou pela Superintendência de Seguros Privados (Susep). As Partes
          reconhecem que o Aplicativo <span class=SpellE>Clarito</span> apenas oferece um
          Serviço de Pontuações para geração de <span class=SpellE>cashback</span> em
          forma de desconto em compras de vidros <span class=SpellE>Clarity</span> em
          Distribuidoras autorizadas, os Clubes <span class=SpellE>Clarity</span>. </span><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">18.3. Não existe entre os Usuários e o Aplicativo <span
          class=SpellE>Clarito</span> nenhuma relação societária ou comercial além da
          estabelecida por meio dos presentes Termos de Uso, e reciprocamente se manterão
          livres e indenes por qualquer reclamação oriunda das obrigações tributárias,
          trabalhistas ou previdenciárias que forem de sua própria responsabilidade. Nem o
          Aplicativo <span class=SpellE>Clarito</span> nem qualquer Usuário induzirão
          pessoa alguma em erro sobre sua independência, nem obrigarão uns aos outros
          perante terceiros. O descumprimento desta obrigação pelo Usuário autorizará o
          Aplicativo <span class=SpellE>Clarito</span> a inabilitar o cadastro do
          Usuário, sem prejuízo das medidas judiciais ou extrajudiciais que julgar
          cabíveis.<o:p></o:p></span></p>
          
          <p class=MsoNormal style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
          text-align:justify;line-height:normal;mso-outline-level:2;background:white"><b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:black;mso-fareast-language:PT-BR">11.
          DISPOSIÇÕES FINAIS</span></b><b><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR"><o:p></o:p></span></b></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">11.1. O Usuário declara-se ciente e concorda que a
          relação com o Aplicativo <span class=SpellE>Clarito</span> estará sempre
          sujeita à legislação brasileira, independentemente do local de utilização dos
          serviços.</span><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">11.2. Serão válidas todas as notificações
          realizadas aos Usuários pelo Aplicativo <span class=SpellE>Clarito</span> no
          endereço de correio eletrônico principal que conste do cadastro dos Usuários.</span><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">11.3. A nulidade ou invalidade de qualquer dos
          itens dispostos aqui nestes Termos de Uso, não implica a nulidade ou invalidade
          dos demais. As disposições consideradas nulas ou inválidas serão reescritas, de
          modo a refletir a intenção inicial de sua previsão em conformidade com a
          legislação aplicável, e inserida na nova versão destes Termos de Uso.</span><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <div class=MsoNormal align=center style="margin-bottom:0cm;text-align:center;
          line-height:normal"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;mso-fareast-language:
          PT-BR">
          
          <hr size=2 width="100%" noshade style="color:#848484" align=center>
          
          </span></div>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:#848484;
          mso-fareast-language:PT-BR">&nbsp;<o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><b><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">ATENÇÃO!&nbsp;</span></b><span style="mso-fareast-font-family:
          "Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
          color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal style="margin-bottom:0cm;text-align:justify;line-height:
          normal;background:white"><b><u><span style="mso-fareast-font-family:"Times New Roman";
          mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
          mso-fareast-language:PT-BR">Última atualização realizada em 21 de março de 2023</span></u></b><span
          style="mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
          mso-bidi-theme-font:minor-latin;color:#848484;mso-fareast-language:PT-BR"><o:p></o:p></span></p>
          
          <p class=MsoNormal><b><span style="mso-bidi-font-family:Calibri;mso-bidi-theme-font:
          minor-latin"><o:p>&nbsp;</o:p></span></b></p>
          
          </div>
          
          </body>
          
          </html>
          ` }}
          style={{ height: '75vh', overflowY: 'auto' }}
        />
      )}
    </Modal>

    //https://pt.stackoverflow.com/questions/47452/exibir-arquivo-pdf-no-corpo-da-p%C3%A1gina-html
  );
}
