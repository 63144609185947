import { api } from '../../../api/implementations/Axios';
import { BuscarPontosUsuarioController } from './BuscarPontosUsuario/BuscarPontosUsuarioController';
import { ResgatarPontosUsuarioController } from './ResgatarPontosUsuario/ResgatarPontosUsuarioController';
import { UtilizarPontosUsuarioController } from './UtilizarPontosUsuario/UtilizarPontosUsuarioController';

const buscarPontosUsuario = new BuscarPontosUsuarioController(api);
const utilizarPontosUsuario = new UtilizarPontosUsuarioController(api);
const resgatarPontosUsuario = new ResgatarPontosUsuarioController(api);

export { buscarPontosUsuario, utilizarPontosUsuario, resgatarPontosUsuario };
