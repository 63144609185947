import React from 'react';
import { FlatList, View } from 'react-native';

import { Extrato } from '../../../../entities/Extrato';
import { ITimeline } from '../../../../hooks/useTimeline';


import { Loading } from '../../../Common/Loading';
import { SemRegistro } from '../../../Common/SemRegistro';
import { DataTimeline } from '../../../Common/DataTimeline';
import { AtualizarLista } from '../../../Common/AtualizarLista';
import { CardConsumidorExtrato } from '../../CardExtrato/CardConsumidorExtrato';



interface ITimelineConsumidorExtrato {
  extrato: ITimeline<Extrato>[];
  loadingMore: boolean;
  onUpdate: () => void;
}
//

export function TimelineConsumidorExtrato({
  extrato,
  loadingMore,
  onUpdate,
}: ITimelineConsumidorExtrato) {
  
  return (
    <View>
      
      <FlatList
        data={extrato}
        keyExtractor={item => item.id.toString()}
        renderItem={({ item }) => (
          <View>
            
            <DataTimeline data={item.data} />

            {item.timeline.map(e => (
              <CardConsumidorExtrato key={e.id.toString()} data={e} />
            ))}
          </View>
        )}
        ListFooterComponent={
          loadingMore ? (
            <Loading />
          ) : extrato.length > 0 ? (
            <AtualizarLista onPress={onUpdate} />
          ) : null
        }
        ListEmptyComponent={<SemRegistro screen="Extrato de Claritos" />}
        contentContainerStyle={{
          flex: 1,
          flexDirection: 'column',
          maxHeight: '70vH',
          width: '100%',
        }}
        onEndReachedThreshold={0.5}
        initialNumToRender={20}
      />
    </View>
  );
}
