import { IApi } from '../../../../api/IApi';
import { PontosUtilizados } from '../../../../entities/PontosUtilizados';

export class ListarExtratoUsuarioAssociadoController {
  constructor(private api: IApi) {}

  async execute(ultimoId: number, token: string): Promise<PontosUtilizados[]> {
    const listaExtrato = await this.api.get<PontosUtilizados[]>(
      `usuarios/pontosUtilizadosAssociados/${ultimoId}`,
      token
    );

    return listaExtrato;
  }
}
