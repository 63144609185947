import { Validacoes } from '../Validacoes/Validacoes';
import { CadastrarPontoUtilizacaoValidacaoController } from './CadastrarPontoUtilizacaoValidacaoController';

const validacoes = new Validacoes();

const cadastrarPontoUtilizacaoValidacao = new CadastrarPontoUtilizacaoValidacaoController(
  validacoes
);

export { cadastrarPontoUtilizacaoValidacao };
