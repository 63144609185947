import React from 'react';
import { View } from 'react-native';

import { Button } from '../Button';

import { theme } from '../../../global/styles/theme';

interface IAtualizarLista {
  onPress: () => void;
}

export function AtualizarLista({ onPress }: IAtualizarLista) {
  return (
    <View style={{ flexDirection: 'row', alignSelf: 'center', marginVertical: 8 }}>
      <Button
        text="Carregar mais"
        mode="text"
        color={theme.colors.blue300}
        textStyle={{ fontFamily: theme.fonts.roboto700, fontSize: 20, opacity: 0.5 }}
        onPress={onPress}
      />
    </View>
  );
}
