import moment from 'moment';

import { Pedido } from '../entities/Pedido';
import { Extrato } from '../entities/Extrato';
import { PontosUtilizados } from '../entities/PontosUtilizados';

export interface ITimeline<T> {
  id: number;
  data: string;
  timeline: T[];
}

export const useTimeline = () => {
  function extratoPontosConsumidor(pontos: Extrato[]): ITimeline<Extrato>[] {
    const timelines: ITimeline<Extrato>[] = [];

    pontos.forEach((ponto, i) => {
      const data = moment(ponto.disponivelEm).utc().format('LL');

      if (timelines.length === 0) timelines.push({ id: i, timeline: [], data });

      if (i > 0) {
        const dataAnterior = pontos[i - 1].disponivelEm;
        const id = timelines.length - 1;
        const mesmaData =
          moment(ponto.disponivelEm).utc().format('L') === moment(dataAnterior).utc().format('L');

        if (!mesmaData) timelines.push({ id, timeline: [], data });
      }

      timelines[timelines.length - 1].timeline.push(ponto);
    });

    return timelines;
  }

  function extratoPontosAssociado(pontos: PontosUtilizados[]): ITimeline<PontosUtilizados>[] {
    const timelines: ITimeline<PontosUtilizados>[] = [];

    pontos.forEach((ponto, i) => {
      const data = moment(ponto.pontos.disponivelEm).utc().format('LL');

      if (timelines.length === 0) timelines.push({ id: i, timeline: [], data });

      if (i > 0) {
        const dataAnterior = pontos[i - 1].pontos.disponivelEm;
        const id = timelines.length - 1;
        const mesmaData =
          moment(ponto.pontos.disponivelEm).utc().format('L') ===
          moment(dataAnterior).utc().format('L');

        if (!mesmaData) timelines.push({ id, timeline: [], data });
      }

      timelines[timelines.length - 1].timeline.push(ponto);
    });

    return timelines;
  }

  function extratoPedidos(pedidos: Pedido[]): ITimeline<Pedido>[] {
    const timelines: ITimeline<Pedido>[] = [];

    pedidos.forEach((pedido, i) => {
      const data = moment(pedido.emitidoEm).format('LL');

      if (timelines.length === 0) timelines.push({ id: i, timeline: [], data });

      if (i > 0) {
        const dataAnterior = pedidos[i - 1].emitidoEm;
        const id = timelines.length - 1;
        const mesmaData = moment(pedido.emitidoEm).format('L') === moment(dataAnterior).format('L');

        if (!mesmaData) timelines.push({ id, timeline: [], data });
      }

      timelines[timelines.length - 1].timeline.push(pedido);
    });

    return timelines;
  }

  return { extratoPontosConsumidor, extratoPontosAssociado, extratoPedidos };
};
