import { isDesktop } from 'react-device-detect';
import React, { createContext, useState, useEffect } from 'react';

export type Screens =
  | 'Sobre'
  | 'Extrato de Claritos'
  | 'Meus Pedidos'
  | 'Resgatar Claritos'
  | 'Pontos de Utilização'
  | 'Notificações'
  | 'Perfil'
  | 'Home'
  | 'Notificações Detalhes'
  | 'EncerrarConta'
  | 'Contato'
  | 'Termos';


interface IScreenContext {
  screen?: Screens;
  isDesktop: boolean;
  reloadData: boolean;
  setScreen: (screen: Screens) => void;
  setReloadData: (value: boolean) => void;
}

interface IScreenProvider {
  children: React.ReactNode;
}

export const ScreenContext = createContext<IScreenContext>({} as IScreenContext);

export function ScreenProvider({ children }: IScreenProvider) {
  const [reloadData, setReload] = useState(false);
  const [screen, setActiveScreen] = useState<Screens>();

  useEffect(() => setActiveScreen(screen ?? 'Sobre'), []);

  const setScreen = (screen: Screens) => setActiveScreen(screen);

  const setReloadData = (value: boolean) => setReload(value);

  return (
    <ScreenContext.Provider value={{ screen, isDesktop, reloadData, setScreen, setReloadData }}>
      {children}
    </ScreenContext.Provider>
  );
}
