import { StyleSheet } from 'react-native';
import { getStatusBarHeight } from 'react-native-iphone-x-helper';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: getStatusBarHeight() + 8,
  },
  content: {
    width: '100%',
    flex: 1,
    marginTop: 8,
    paddingVertical: 8,
    paddingHorizontal: 16,
  },
  contentCelular: {
    width: '100%',
    flex: 1,
    marginTop: 8,
    paddingVertical: 8,
    paddingHorizontal: 16,
    marginBottom:30,
    overflow:'scroll'
  },
  gcLogo: {
    height: 35,
    marginBottom: 16,
  },
});
