import { StyleSheet } from 'react-native';
import { theme } from '../../global/styles/theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    marginTop: 0,
    paddingHorizontal: 16,
  },
  disponivelEmContainer: {
    marginBottom: 16,
  },
  disponivelEm: {
    fontFamily: theme.fonts.roboto400,
    fontSize: 24,
    color: theme.colors.text,
  },
  timelineContainer: {
    marginTop: 8,
  },
  timeline: {},
  semRegistro: {
    marginTop: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  semRegistroText: {
    fontFamily: theme.fonts.roboto700,
    fontSize: 32,
    color: theme.colors.blue300,
    opacity: 0.5,
  },
  textInput25: {
    color: theme.colors.blue300,
    fontFamily: theme.fonts.roboto400,
    fontSize: 10,
    marginEnd:20
  },
  textIcon:{
    color: theme.colors.blue300,
    fontFamily: theme.fonts.roboto400,
    fontSize: 20,
    marginEnd:5
  },
  containerFiltro: {
    flexDirection: 'row',
    justifyContent:'flex-end',
    alignItems:'center',
    textAlignVertical:'center',
    textAlign:'center',
  },
});
