import { View } from 'react-native';
import React from 'react';

import { Button } from '../../Button';
import { Modal } from '../';

interface IModalConfirmacao {
  header?: string;
  body?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export function ModalConfirmacao({ header, body, isOpen, onClose, onConfirm }: IModalConfirmacao) {
  return (
    <Modal isModalOpen={isOpen} setIsModalOpen={onClose} header={header}>
      {body && <View style={{ marginBottom: 8 }}>{body}</View>}

      <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Button text="Cancelar" mode="outlined" onPress={onClose} />

        <Button text="Confirmar" mode="contained" onPress={onConfirm} style={{ marginLeft: 8 }} />
      </View>
    </Modal>
  );
}
