import React, { useState } from 'react';
import { TextInput } from 'react-native-paper';
import { FontAwesome5 } from '@expo/vector-icons';
import { StyleProp, TextStyle, View } from 'react-native';

import { Button } from '../Button';

type $DeepPartial<T> = { [P in keyof T]?: $DeepPartial<T[P]> };

interface ITextInputSenha {
  value: string;
  theme: $DeepPartial<ReactNativePaper.Theme>;
  label?: string;
  style?: StyleProp<TextStyle>;
  eyeColor?: string;
  underlineColor?: string;
  onChangeText: (senha: string) => void;
  onKeyPress?: (e: any) => void;
}

export function TextInputSenha({
  value,
  theme,
  label,
  style,
  eyeColor,
  underlineColor,
  onChangeText,
  onKeyPress,
}: ITextInputSenha) {
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);

  return (
    <View style={{ position: 'relative' }}>
      <TextInput
        label={label || 'Senha'}
        keyboardType="default"
        textContentType="password"
        onChangeText={senha => onChangeText(senha)}
        onKeyPress={onKeyPress}
        value={value}
        secureTextEntry={isPasswordHidden}
        onPressIn={() => {}}
        onPressOut={() => {}}
        autoComplete="true"
        style={style}
        theme={theme}
        underlineColor={underlineColor}
      />

      <Button
        mode="text"
        icon={
          <FontAwesome5
            name={isPasswordHidden ? 'eye' : 'eye-slash'}
            size={20}
            color={eyeColor || 'white'}
          />
        }
        onPress={() => setIsPasswordHidden(!isPasswordHidden)}
        style={{ top: 16, right: 0, position: 'absolute', width: 48 }}
      />
    </View>
  );
}
