import React from 'react';
import { View } from 'react-native';

import { useAuth } from '../../../hooks/useAuth';

import { Button } from '../../Common/Button';

import { styles } from './styles';

import { useNavigation } from '@react-navigation/native';
import { useScreen } from '../../../hooks/useScreen';

interface IFooterPerfil {
  editando: boolean;
  editar: () => void;
  salvar: () => void;
  cancelar: () => void;
  alterarSenha: () => void;
}

export function FooterPerfil({ editando, editar, salvar, cancelar, alterarSenha }: IFooterPerfil) {
  const { logout } = useAuth();
  const { setScreen } = useScreen();
  const { navigate } = useNavigation();
  function handleEncerraContaClick() {
    setScreen('EncerrarConta');
    navigate('EncerrarConta' as any);

  }

  if (editando)
    return (
      <View style={styles.container}>
        <Button
          text="Cancelar"
          mode="outlined"
          compact={false}
          uppercase={true}
          onPress={cancelar}
        />

        <Button
          text="Salvar"
          mode="contained"
          compact={false}
          uppercase={true}
          onPress={salvar}
          style={{ marginLeft: 8 }}
        />
      </View>
    );
  else
    return (
      <View>
        <View style={styles.container}>
          <Button
            text="Alterar senha"
            mode="outlined"
            compact={false}
            uppercase={true}
            onPress={alterarSenha}
            style={{ marginLeft: 8 }}
          />

          <Button
            text="Editar"
            mode="outlined"
            compact={false}
            uppercase={true}
            onPress={editar}
            style={{ marginLeft: 8 }}
          />

          <Button
            text="Sair"
            mode="contained"
            compact={false}
            uppercase={true}
            onPress={logout}
            style={{ marginLeft: 8 }}
          />
        </View>
        <View style={styles.container}>
        <Button
            text="Encerrar conta"
            mode="outlined"
            compact={false}
            uppercase={true}
            onPress={handleEncerraContaClick}
            style={{ marginLeft: 8 }}
            
          />
        </View>

      </View>

    );
}
