import { IRecuperarSenhaUsuarioDTO } from '../../Usuario/RecuperarSenhaUsuario/RecuperarSenhaUsuarioDTO';
import { Validacoes } from '../Validacoes/Validacoes';

export class RecuperarSenhaValidadacaoController {
  constructor(private validacoes: Validacoes) {}

  public handle(props: IRecuperarSenhaUsuarioDTO): boolean {
    if (!props.cnpjCPF) throw new Error('Digite um CNPJ/CPF');

    if (!this.validacoes.validaCnpjCpf(props.cnpjCPF)) throw new Error('CNPJ/CPF inválido');

    if (!props.email) throw new Error('Digite um email');

    if (!this.validacoes.validaEmail(props.email)) throw new Error('Email inválido');

    return true;
  }
}
