import { IApi } from '../../../api/IApi';
import { Extrato } from '../../../entities/Extrato';
import { IFiltroExtratoUsuarioDTO, IListarExtratoUsuarioDTO } from './ListarExtratoUsuarioDTO';

export class ListarExtratoUsuarioController {
  constructor(private api: IApi) {}

  async execute(body: IFiltroExtratoUsuarioDTO, token: string): Promise<Extrato[]> {
    const extrato = await this.api.get<IListarExtratoUsuarioDTO[]>(
      `usuarios/extrato/`+JSON.stringify(body),
      token
    );

    return extrato;
  }
}
