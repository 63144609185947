import React from 'react';
import { View, Text } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';

import { PontoUtilizacao } from '../../../entities/PontoUtilizacao';

import { Button } from '../../Common/Button';
import { CardAssociadoResgatarPontos } from './CardAssociadoResgatarPontos';

import { styles } from './styles';

interface IAssociadoResgatarPontos {
  pontoUtilizacao: PontoUtilizacao;
  onConfirmar: () => void;
  onCancelar: () => void;
}

export function AssociadoResgatarPontos({
  pontoUtilizacao,
  onConfirmar,
  onCancelar,
}: IAssociadoResgatarPontos) {
  return (
    <View style={styles.container}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 8,
          marginBottom: 16,
        }}
      >
        <FontAwesome5 name="city" size={20} />
        <Text style={styles.tipoValorLabel}>Confirme o Clube Clarity</Text>
      </View>

      <CardAssociadoResgatarPontos caixa={pontoUtilizacao} />

      <View style={styles.btnContainer}>
        <Button text="Cancelar" mode="outlined" onPress={onCancelar} />

        <Button
          text="Prosseguir para o pagamento"
          mode="contained"
          onPress={onConfirmar}
          style={{ marginLeft: 8 }}
        />
      </View>
    </View>
  );
}
