import { IApi } from '../../../api/IApi';
import { IAlterarSenhaUsuarioDTO } from './AlterarSenhaUsuarioDTO';

export class AlterarSenhaUsuarioController {
  constructor(private api: IApi) {}

  async execute(data: IAlterarSenhaUsuarioDTO, token: string): Promise<void> {
    await this.api.post<void, IAlterarSenhaUsuarioDTO>('usuarios/alterarSenha', data, token);
  }
}
