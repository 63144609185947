import { StyleSheet } from 'react-native';
import { theme } from '../../../../global/styles/theme';

export const styles = StyleSheet.create({
  container: {
    borderWidth: 2,
    borderColor: theme.colors.blue200,
  },
  contentContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 4,
  },
  nome: {
    fontFamily: theme.fonts.roboto700,
    fontSize: 24,
    color: '#262626',
  },
  desc: {
    marginBottom: 8,
    fontFamily: theme.fonts.roboto700,
    fontSize: 20,
    color: '#262626',
  },
  text: {
    fontFamily: theme.fonts.roboto400,
    fontSize: 16,
  },
  iconContainer: {
    marginRight: 8,
    width: 12,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
