import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ScrollView, View, Text, Image } from 'react-native';

import { imagemNotificacao } from '../../../useCases/Notificacao/ImagemNotificacao';

import { useAuth } from '../../../hooks/useAuth';
import { useToast } from '../../../hooks/useToast';
import { useNotificacao } from '../../../hooks/useNotificacao';

import { Modal } from '../../Common/Modal';
import { Button } from '../../Common/Button';
import { Loading } from '../../Common/Loading';

import { styles } from './styles';

interface IModalDetalhesNotificacao {
  isOpen: boolean;
  onClose: () => void;
}

export function ModalDetalhesNotificacao({ isOpen, onClose }: IModalDetalhesNotificacao) {
  const { token } = useAuth();
  const { errorToast } = useToast();
  const { notificacao, setNotificacao } = useNotificacao();

  const [loading, setLoading] = useState(true);

  async function getImagem() {
    try {
      const _imagem = await imagemNotificacao.execute(notificacao.id, token);

      const imagem = `data:image/png;base64,${_imagem}`;

      setNotificacao({ ...notificacao, imagem });
    } catch (err) {
      errorToast(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      getImagem();
    } else setNotificacao(null);
  }, [isOpen]);

  return (
    <Modal hideHeader fullScreen isModalOpen={isOpen} setIsModalOpen={onClose}>
      <View style={styles.container}>
        {loading ? (
          <View style={styles.loadingContainer}>
            <Loading />
          </View>
        ) : (
          <View style={styles.wrapper}>
            <ScrollView style={styles.content}>
              <Button
                text="Voltar"
                mode="text"
                background="transparent"
                textStyle={styles.voltarText}
                style={styles.voltarBtn}
                onPress={onClose}
              />

              <View style={styles.header}>
                <Text style={styles.titulo}>{notificacao?.titulo}</Text>
                <Text style={styles.data}>{moment(notificacao?.cadastroEm).format('L LTS')}</Text>
              </View>

              {/* <View style={styles.imagemContainer}> */}
              <Image
                // resizeMode="cover"
                resizeMethod="auto"
                source={{ uri: notificacao?.imagem }}
                style={styles.imagem}
              />
              {/* </View> */}
            </ScrollView>
          </View>
        )}
      </View>
    </Modal>
  );
}
