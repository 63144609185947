import React from 'react';
import { Text } from 'react-native';

import { theme } from '../../../global/styles/theme';

interface IDataTimeline {
  data: string;
}

export function DataTimeline({ data }: IDataTimeline) {
  return (
    <Text
      style={{
        fontFamily: theme.fonts.roboto700,
        fontSize: 20,
        color: theme.colors.text,
        marginVertical: 8,
      }}
    >
      {data}
    </Text>
  );
}
