import React from 'react';
import { View } from 'react-native';
import { isDesktop } from 'react-device-detect';

import { useAuth } from '../../../hooks/useAuth';

import { ScreenList } from '../../../screens';
import { BottomMenuButton } from '../../Menu/BottomMenu/BottomMenuButton';

import { styles } from './styles';

export function BottomMenu() {
  const { usuario } = useAuth();

  if (!usuario) return null;

  return (
    <View style={styles.container}>
      {ScreenList.filter(e => !e.dontShowBottomMenu && e.showInMenu && usuario && e.canAccess.includes(usuario.tipo))
        .filter(e => (isDesktop ? !e.dontShowOnDesktop : e))
        .map(option => (
          <BottomMenuButton key={option.screen} screen={option.screen} icon={option?.icon} subtitle={option?.subtitle}/>
        ))}
    </View>
  );
}
