import { useState, useEffect } from 'react';

import { Endereco } from '../entities/Endereco';
import { buscaCep as buscaCepService } from '../services/Cep';

import { useToast } from './useToast';

export const useCep = () => {
  const { errorToast } = useToast();
  const [endereco, setEndereco] = useState<Endereco>();

  const buscaCep = async (cep: string): Promise<Endereco> => await buscaCepService(cep);

  const observerCep = async (cep: string): Promise<void> => {
    async function buscarCep() {
      try {
        const res = await buscaCep(cep);

        setEndereco(res);
      } catch (err) {
        setEndereco(resetEndereco(cep));
        errorToast(err);
      }
    }

    useEffect(() => {
      const temHifem = cep.includes('-');

      if (temHifem && cep.length >= 9) buscarCep();

      if (!temHifem && cep.length >= 8) {
        const cepFormatado = `${cep.slice(0, 5)}-${cep.slice(-3)}`;

        setEndereco({ ...endereco, cep: cepFormatado });
      }
    }, [cep]);
  };

  const resetEndereco = (cep: string): Endereco =>
    new Endereco({
      cep,
      endereco: '',
      bairro: '',
      cidade: {
        descricao: '',
        ibgeId: null,
        uf: '',
      },
    });

  return { buscaCep, observerCep, endereco };
};
