import React from 'react';
import { View, Image } from 'react-native';
import { isDesktop } from 'react-device-detect';
import { DrawerContentComponentProps } from '@react-navigation/drawer';

import { UsuariosTipos } from '../../../entities/Usuario';

import { DrawerHeader } from './DrawerHeader';
import { DrawerOption } from './DrawerOption';
import { DrawerOptionLogout } from './DrawerOptionLogout';

import { ScreenList } from '../../../screens';

import GC_LOGO from '../../../assets/GC.png';

import { styles } from './styles';

interface IDrawerMenu {
  userTipo?: UsuariosTipos;
  props: DrawerContentComponentProps;
}

export function DrawerMenu({ props, userTipo }: IDrawerMenu) {
  const currentPage = props.state.index;

  return (
    <View style={styles.container}>
      <DrawerHeader />

      <View style={(isDesktop ?styles.content : styles.contentCelular)}>
        {ScreenList.filter(e => e.showInMenu && e.canAccess.includes(userTipo))
          .filter(e => (isDesktop ? !e.dontShowOnDesktop : e))
          .map((e, i) => (
            <DrawerOption
              key={e.screen}
              currentPage={currentPage}
              index={i}
              screen={e.screen}
              icon={e.icon}
              {...props}
            />
          ))}

        <DrawerOptionLogout />
      </View>

      <Image source={GC_LOGO} style={styles.gcLogo} resizeMode="contain" />
    </View>
  );
}
