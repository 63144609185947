import { StyleSheet } from 'react-native';
import { theme } from '../../../global/styles/theme';

export const styles = StyleSheet.create({
  lblTitulo: {
    fontFamily: theme.fonts.roboto700,
    fontSize: 16,
  },
  valor: {
    fontFamily: theme.fonts.roboto400,
    fontSize: 16,
  },
  tipoValorLabel: {
    fontFamily: theme.fonts.roboto700,
    fontSize: 20,
    marginLeft: 8,
    marginBottom: 16,
  },
});
