import React from 'react';
import { Text, View } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';

import { IPedidoSint } from '../../../useCases/Usuario/ListarPedidosUsuario/ListarPedidosUsuarioDTO';

import { useNumberFormat } from '../../../hooks/useNumberFormat';

import { Modal } from '../../Common/Modal';

import { styles } from './styles';

interface IModalPedidosSintetizado {
  sint: IPedidoSint[];
  pedidoCliente: string;
  referencia: number;
  isOpen: boolean;
  onClose: () => void;
}

export function ModalPedidoSintetizado({
  sint,
  pedidoCliente,
  referencia,
  isOpen,
  onClose,
}: IModalPedidosSintetizado) {
  const { milhar } = useNumberFormat();

  return (
    <Modal
      header={`${pedidoCliente} (${referencia})`}
      isModalOpen={isOpen}
      setIsModalOpen={onClose}
    >
      <View>
        <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
          <FontAwesome5 name="coins" size={18} />
          <Text style={styles.tipoValorLabel}>Descrição dos Claritos gerados</Text>
        </View>

        {sint?.map(pedido => (
          <View key={pedido.id}>
            <Text style={styles.valor}>
              <Text style={styles.lblTitulo}>{pedido.sintPontos.descricao}:</Text>{' '}
              {`${milhar(pedido.pontos)} Claritos.`}
            </Text>
          </View>
        ))}
      </View>
    </Modal>
  );
}
