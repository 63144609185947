import { IApi } from '../../../../api/IApi';
import { IResgatarPontosUsuarioDTO } from './ResgatarPontosPedidoUsuarioDTO';

export class ResgatarPontosUsuarioController {
  constructor(private api: IApi) {}

  async execute(data: IResgatarPontosUsuarioDTO, token: string): Promise<void> {
    await this.api.post<void, IResgatarPontosUsuarioDTO>(
      `usuarios/pedidos/resgatarPontos`,
      data,
      token
    );
  }
}
