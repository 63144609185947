import { StyleSheet } from 'react-native';
import { theme } from '../../../global/styles/theme';

export const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    marginTop: -24,
  },
  text: {
    fontFamily: theme.fonts.roboto400,
    fontSize: 16,
  },
  btnContainer: {
    marginTop: 16,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  tipoValorLabel: {
    fontFamily: theme.fonts.roboto700,
    fontSize: 26,
    marginLeft: 8,
  },
  textInput: {
    width: '100%',
    height: 72,
    paddingHorizontal: 8,
    borderRadius: 3,
    fontSize: 48,
    fontFamily: theme.fonts.pontos,
    backgroundColor: 'transparent',
  },
  bold: {
    fontFamily: theme.fonts.roboto700,
  },
  modalDivergente: {
    color: theme.colors.red,
  },
  modalCorrigido: {
    color: theme.colors.credito,
  },
});
