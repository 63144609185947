import { StyleSheet } from 'react-native';
import { theme } from '../../global/styles/theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.blue100,
  },
  innerContainer: {
    height: '100vh',
  },
  content: {
    maxWidth: 300,
    paddingBottom: 24,
    alignSelf: 'center',
  },
  logo: {
    width: 368,
    height: 158,
    alignSelf: 'center',
  },
  separator: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.blue50,
    marginTop: 8,
    marginBottom: 16,
  },
});
