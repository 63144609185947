import React, { useState } from 'react';
import { isIOS } from 'react-device-detect';
import { useNavigation } from '@react-navigation/native';
import { DrawerToggleButton } from '@react-navigation/drawer';
import { Animated, ScrollView, View, Text, SafeAreaView, Pressable } from 'react-native';

import { useAuth } from '../../../hooks/useAuth';
import { useScreen } from '../../../hooks/useScreen';
import { useNumberFormat } from '../../../hooks/useNumberFormat';

import { Avatar } from '../Avatar';

import { styles } from './styles';
import { theme } from '../../../global/styles/theme';

interface IHeader {
  children: React.ReactNode;
  pontos?: boolean;
  collapsed?: boolean;
  hidden?: boolean;
  fator?: number;
}

const HEADER_HEIGHT_MAX = 240;
const HEADER_HEIGHT_MIN = isIOS ? 81 : 65;
const HEADER_SCROLL_DISTANCE = HEADER_HEIGHT_MAX - HEADER_HEIGHT_MIN;

export function Header({ children, collapsed = false, pontos = true, hidden = false }: IHeader) {
  const { usuario } = useAuth();
  const { setScreen } = useScreen();
  const navigation = useNavigation();
  const { milhar, currency } = useNumberFormat();

  const { blue400 } = theme.colors;
  const [scrollY] = useState<Animated.Value>(new Animated.Value(0));

  const animation = {
    headerHeight: scrollY.interpolate({
      inputRange: [0, HEADER_SCROLL_DISTANCE],
      outputRange: [HEADER_HEIGHT_MAX, HEADER_HEIGHT_MIN],
      extrapolate: 'clamp',
    }),
    pontosTranslate: scrollY.interpolate({
      inputRange: [0, HEADER_SCROLL_DISTANCE],
      outputRange: [0, -48],
      extrapolate: 'clamp',
    }),
    dinheiroTranslate: scrollY.interpolate({
      inputRange: [0, HEADER_SCROLL_DISTANCE],
      outputRange: [-8, -56],
      extrapolate: 'clamp',
    }),
    dinheiroOpacity: scrollY.interpolate({
      inputRange: [0, HEADER_SCROLL_DISTANCE - 48],
      outputRange: [1, 0],
      extrapolate: 'clamp',
    }),
    pontosSize: scrollY.interpolate({
      inputRange: [0, HEADER_SCROLL_DISTANCE],
      outputRange: [96, 32],
      extrapolate: 'clamp',
    }),
    pontosLabelOpacity: scrollY.interpolate({
      inputRange: [0, HEADER_SCROLL_DISTANCE - 48],
      outputRange: [1, 0],
      extrapolate: 'clamp',
    }),
  };

  function handleAvatarClick() {
    setScreen('Perfil');
    navigation.navigate('Perfil' as any);
  }

  return (
    <SafeAreaView style={styles.container}>
      {!hidden &&
        (collapsed ? (
          <View style={[styles.header, { height: HEADER_HEIGHT_MIN }]}>
            <View style={[styles.bar, { backgroundColor: blue400 }]}>
              <View style={{ width: '100%', height: 200 }}>
                <View style={styles.innerHeader}>
                  <DrawerToggleButton
                    pressColor="transparent"
                    accessibilityLabel="Menu"
                    tintColor={theme.colors.white}
                  />

                  {!pontos && (
                    <Text style={[styles.clube, { alignSelf: 'center' }]}>
                      Clube de Pontos Clarity
                    </Text>
                  )}

                  <Pressable onPress={handleAvatarClick}>
                    <Avatar
                      userInfo={{
                        nome: usuario?.nome ?? '',
                        avatar: usuario?.imagem ?? null,
                      }}
                      tamanho="sm"
                    />
                  </Pressable>
                </View>

                {pontos && (
                  <Text style={[styles.pontos, { marginTop: -36, fontSize: 32 }]}>
                    {milhar(usuario?.pontosTotal ?? 0)}
                  </Text>
                )}
              </View>
            </View>
          </View>
        ) : (
          <Animated.View style={[styles.header, { height: animation?.headerHeight }]}>
            <View style={[styles.bar, { backgroundColor: blue400 }]}>
              <View style={{ width: '100%', height: 200 }}>
                <View style={styles.innerHeader}>
                  <DrawerToggleButton
                    pressColor="transparent"
                    accessibilityLabel="Menu"
                    tintColor={theme.colors.white}
                  />

                  <Animated.Text
                    style={[styles.pontosLabel, { opacity: animation?.pontosLabelOpacity }]}
                  >
                    Meus Claritos
                  </Animated.Text>

                  <Pressable onPress={handleAvatarClick}>
                    <Avatar
                      userInfo={{
                        nome: usuario?.nome ?? '',
                        avatar: usuario?.imagem ?? null,
                      }}
                      tamanho="sm"
                    />
                  </Pressable>
                </View>

                <Animated.Text
                  style={[
                    styles.pontos,
                    {
                      transform: [{ translateY: animation?.pontosTranslate }],
                      fontSize: animation?.pontosSize,
                    },
                  ]}
                >
                  {milhar(usuario?.pontosTotal ?? 0)}
                </Animated.Text>

                <Animated.Text
                  style={[
                    styles.pontosDinheiroLabel,
                    {
                      transform: [{ translateY: animation?.dinheiroTranslate }],
                      opacity: animation?.dinheiroOpacity,
                    },
                  ]}
                >
                  ({currency(usuario.pontosDinheiro)})
                </Animated.Text>
              </View>
            </View>
          </Animated.View>
        ))}

      <ScrollView
        style={[styles.content, { marginTop: collapsed ? -16 : isIOS ? 16 : 0 }]}
        scrollEventThrottle={16}
        onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollY } } }], {
          useNativeDriver: false,
        })}
        disableIntervalMomentum={true}
      >
        <View style={{ marginTop: collapsed ? HEADER_HEIGHT_MIN + 40 : HEADER_HEIGHT_MAX }}>
          {children}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}
