import React from 'react';
import { AntDesign } from '@expo/vector-icons';
import { View, Text, ViewStyle } from 'react-native';
import { Portal, Modal as PaperModal, DefaultTheme } from 'react-native-paper';

import { styles } from './styles';

interface IModal {
  children?: React.ReactNode;
  header?: string;
  isModalOpen: boolean;
  transparente?: boolean;
  displayNone?: boolean;
  hideHeader?: boolean;
  fullScreen?: boolean;
  style?: ViewStyle;
  setIsModalOpen: () => void;
}

export function Modal({
  children,
  header,
  isModalOpen,
  transparente,
  displayNone = false,
  hideHeader = false,
  fullScreen = false,
  style,
  setIsModalOpen,
}: IModal) {
  return (
    <View style={styles.container}>
      <Portal
        theme={{
          ...DefaultTheme,
          colors: {
            ...DefaultTheme.colors,
            backdrop: transparente ? 'transparent' : DefaultTheme.colors.backdrop,
          },
        }}
      >
        <PaperModal
          visible={displayNone ? false : isModalOpen}
          onDismiss={setIsModalOpen}
          style={fullScreen ? { flex: 1 } : { marginHorizontal: 16 }}
        >
          <View
            style={[
              styles.content,
              style ? style : {},
              fullScreen ? { height: '100vh', borderRadius: 0 } : {},
            ]}
          >
            {!hideHeader && (
              <View style={styles.header}>
                <Text style={styles.headerText}>{header}</Text>

                <AntDesign name="close" size={20} onPress={setIsModalOpen} />
              </View>
            )}

            <View>{children}</View>
          </View>
        </PaperModal>
      </Portal>
    </View>
  );
}
